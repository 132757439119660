import { stringify } from "querystring";
import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "config/hooks";
import { actionCreators } from "store";
import { bindActionCreators } from "redux"
import ActionIcon from "../../commonComponents/actions/actionIcon/ActionIcon";
import "./groupActions.scss";
import { IAction } from "interfaces/storyline";
import { addClassToElement, getString, removeClassFromElement } from "utils";
import ActionsBase from "components/commonComponents/actions/ActionsBase";
import ActionLock from "assets/images/group_actions_locked.svg";
import Step7 from 'components/walkthrough/steps/Step7';
import Step8 from 'components/walkthrough/steps/Step8';

interface GroupActionProps {
	isGameOver: boolean
}

const GroupActions = (props: GroupActionProps) => {
	const dispatch = useAppDispatch()
	const { setIsGroupActionOpen, resetSelectedCharacterList } = bindActionCreators(actionCreators, dispatch)


	let actions = useAppSelector((state) => state.storylineData.actions);
	actions = actions ? actions : [];
	const showGroupActions = useAppSelector((state) => state.initUser.showGroupActions)
	const showWalkthrough = useAppSelector(state => state.globalData.showWalkthrough)
	const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)

	const groupActions: IAction[] = actions.filter((action: IAction) => {
		return action.act_group !== 0;
	});


	const [activeAction, setActiveAction] = useState(-1)
	const [showDescription, setShowDescription] = useState(false)
	const [showGroupActionLockTip, setShowGroupActionLockTip] = useState(false)
	const [showGroupActionUnlockedTip, setShowGroupActionUnlockedTip] = useState(false)
	const isFirstRender = useRef(true)
	const audio  = new Audio("/confetti.mp3")

	useEffect(() => {
		if (!showWalkthrough && !showGroupActions && userCurrentDay >= 2 && !props.isGameOver) {
			addClassToElement('locked-actions', 'z-index-2')
			setShowGroupActionLockTip(true)
			setTimeout(function () {
				setShowGroupActionLockTip(false)
				removeClassFromElement('locked-actions', 'z-index-2')
			}, 7000)
		}
	}, [showWalkthrough])

	useEffect(() => {
		//to handle this useEffect trigger on initial render
		if (isFirstRender.current) {
			isFirstRender.current = false
		}
		else if (showGroupActions) {
			setShowGroupActionUnlockedTip(true)
			audio.play()
			setTimeout(function () {
				setShowGroupActionUnlockedTip(false)
			}, 12000)
		}

	}, [showGroupActions]);


	const handleOnClick = (index: number) => {

		if (showGroupActionUnlockedTip) {
			setShowGroupActionUnlockedTip(false)
		}

		if (activeAction != -1) {
			resetSelectedCharacterList()
		}
		if (activeAction == index) {
			setActiveAction(-1);
			/* To enable hover when action unclicked */
			removeClassFromElement("actions", "no-hover")
			setIsGroupActionOpen(false)
			setShowDescription(false)
			resetSelectedCharacterList()
			return;
		}

		setActiveAction(index);
		/* To disable hover when action clicked */
		addClassToElement("actions", "no-hover");
		setIsGroupActionOpen(true)
		setShowDescription(true)
	};

	const handleExitGroupDescription = (selectedTeamMemberData?: any) => {
		setActiveAction(-1);
		//reset selected char list in all cases and update it in takeaction handler if needed
		resetSelectedCharacterList()
		/* To enable hover when action unclicked */
		removeClassFromElement("actions", "no-hover");
		setIsGroupActionOpen(false)
		setShowDescription(false)
	};

	const activeGroupAction = groupActions.filter((action: IAction) => {
		return action.act_id == activeAction;
	});

	const handleGroupActionUnlockTip = (show: boolean) => {
		if (!showWalkthrough && !showGroupActions && userCurrentDay >= 2 && !props.isGameOver) {

			//to prevent overlap of client character with tooltip
			if (show) addClassToElement('locked-actions', 'z-index-2')
			else removeClassFromElement('locked-actions', 'z-index-2')
			setShowGroupActionLockTip(show)
		}
	}

	//Close Action Description if is opened on Game End
	if(showDescription && props.isGameOver) {
		handleExitGroupDescription()
	}

	return (
		<div className="group-actions-container">
			<div
				id="group-actions"
				className={`group-actions ${showGroupActions && !props.isGameOver ? "" : "pointer-events-none"
					} ${props.isGameOver ? 'opacity-5' : ''}`}
			>
				<div className="title">{getString('label_interact_group_actions')}</div>
				<div id="actions" className="actions">
					{groupActions.map((action: IAction, index: number) => {
						return (
							<ActionIcon
								key={index}
								icon={getString(action["act_image"])}
								onClick={handleOnClick}
								actID={action["act_id"]}
								action_name={getString(action["act_name"])}
								activeAction={activeAction}
								icon_width="3vh"
							/>
						);
					})}
				</div>
			</div>
			{showGroupActionUnlockedTip ? <Step8 /> : null}
			{!showGroupActions && !props.isGameOver ?
				<div id="locked-actions" className="locked-actions"
					onMouseEnter={() => handleGroupActionUnlockTip(true)}
					onMouseLeave={() => handleGroupActionUnlockTip(false)}
				>
					<img src={ActionLock} alt="locked" className="lock-icon" />
					{
						showGroupActionLockTip ? <Step7 /> : null
					}
				</div>
				: null}

			{showDescription ? <div
				id="group-action-description"
				className="group-action-description">
				{activeGroupAction.length > 0 && (
					<ActionsBase
						actionId={activeAction}
						actionDescription={getString(
							activeGroupAction[0]["act_desc"]
						)}
						actionName={getString(activeGroupAction[0]["act_name"])}
						actionData={activeGroupAction[0]}
						actionCost={activeGroupAction[0]["act_days"]}
						handleExitGroupDescription={handleExitGroupDescription}
						isGroupAction={true}
					/>
				)}
			</div> : null}
		</div>
	);
};

export default GroupActions;
