import React from "react";

import footer from "assets/images/intro/introFooter.svg";


import "./intro.scss"

const IntroFooter: React.FC = () => {

    return (
        <div className="intro-footer">
            <img src={footer} style={{ width: "100vw" }} />
        </div>
    )

};

export default IntroFooter;
