import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux"
import { useAppDispatch, useAppSelector } from "config/hooks";
import { actionCreators } from "store";

import IntroPowermap from "./powermap"
import ActorTabs from "./ActorTabs";
import IntroPowermapLoader from "components/commonComponents/loaders/introPowermapLoader";
import FlashToolTip from 'components/commonComponents/flashToolTip/FlashToolTip';
import { getString } from "utils";

interface IntroProps {
    onTabChange: (newValue: number) => void
    isIntroCompleted: boolean | undefined
}

const MeetYourTeam: React.FC<IntroProps> = (props: IntroProps) => {
    // component state
    const showPowermapTip = useAppSelector(state => state.mty.showPowermapTip);
    const showIntroPowermap= useAppSelector(state => state.mty.showIntroPowermap);
    const showButtons = useAppSelector(state => state.mty.showButtons);
    const isDemoEnabled = useAppSelector(state => state.initUser.isDemoEnabled)
    const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)

    const [selectedCharacter, setSelectedCharacter] = useState(null)


    const dispatch = useAppDispatch()

    const { startDemoMode, resetDataFetched, setDemoCompleted, setShowPowermapTip } = bindActionCreators(actionCreators, dispatch)

    // Router useHistory initialisation 
    const history = useHistory()
    // const btnaudio = new Audio("/button_cta.mp3")

        
    useEffect(() => {
        if (props.isIntroCompleted) {
            // setIntroPowermap(true)
            // setShowButtons(true)
            // setTimeout(function () {
            //     setShowPowermapTip(false)
            // }, 3000)
        }
    }, [])

    // functions
    const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
        // btnaudio.play()
        setDemoCompleted()
        history.push('/dashboard')
    }
    const handleBack = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onTabChange(2)
    }
    const handleDemo = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (props.isIntroCompleted) return;
        // now redirect to demo pages
        // set showWalkthrough = true 
        // btnaudio.play()
        await startDemoMode()
        await resetDataFetched()
        history.push('/demo')
        // set isWalkthroughFinished = true 
    }
    const setVisited = (visited: boolean) => {
        if (props.isIntroCompleted) return;
        // setIntroPowermapLoader(true)
        // setTimeout(function () {
        //     // setIntroPowermapLoader(false)
        //     // setIntroPowermap(true)
        //     setShowButtons(true)
        //     // setShowPowermapTip(true)
        // }, 1000)

    }

    const hidePowermapTip = () => {
        // setShowPowermapTip(false)
        setTimeout(function () { setShowPowermapTip(false)}, 0.001)
    }

    const showCharacterTab = (chr) =>{
        setSelectedCharacter(chr)
    }

    return (
        <>
            <div className="mty-header">
                <div className="mty-actor">
                    <ActorTabs setVisited={setVisited} selectedCharacter={selectedCharacter}/>
                </div>
                {/* {showIntroPowermapLoader &&
                    <IntroPowermapLoader />
                } */}
                {showIntroPowermap &&
                    <div className="mty-body">
                        {showPowermapTip ? <div className='powermap-tip'>
                             <FlashToolTip toolTipText={getString("label_intro_pm_tooltip")}/> 
                        </div> : null}
                        <IntroPowermap hidePowermapTip={hidePowermapTip} 
                                       showPowermapTip={showPowermapTip}
                                       showCharacterTab={showCharacterTab} />
                    </div >
                }
            </div >
            <div className="tbody mty-buttons">
                <button className="back" onClick={handleBack}>{getString("label_intro_back")}</button>
                {showButtons ?
                        isDemoEnabled ? (<><button className={`next tryDemo ${props.isIntroCompleted ? 'disable-button' : null}`} onClick={handleDemo}>{getString("label_intro_trydemo")}</button>
                                            <button className="next startSim" onClick={handleNext}>{userCurrentDay > 0 ? `${getString("label_intro_resume_sim")}` : `${getString("label_intro_start_sim")}`}</button>
                                            </>) 
                        : (<><button className="next startSim" onClick={handleNext}>{userCurrentDay > 0 ? `${getString("label_intro_resume_sim")}` : `${getString("label_intro_start_sim")}`}</button></>) 
                : <span style={{marginRight: "3vmax"}}>{getString("label_mty_instruction")}</span>}
            </div>
            
        </>
    )
}

export default MeetYourTeam;
