import * as  jointjs from "jointjs"

import { Dispatch } from "redux"
import actionConsts from "../constants";

export const setDataFetched = (value: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.ISDATAFETCHED,
            value
        })
    }
}

export const setDimensions = (height: number, width: number) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.WINDOWRESIZE,
            height,
            width
        })
    }
}

export const setIntroPowermap = (showIntroPowermap: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.SHOWINTROPOWERMAP,
            payload: showIntroPowermap
        })
    }
}

export const setIntroPowermapLoader = (showIntroPowermapLoader: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.SHOWPOWERMAPLOADER,
            payload: showIntroPowermapLoader
        })
    }
}

export const setShowButtons = (showButtons: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.SHOWINTROBUTTONS,
            payload: showButtons
        })
    }
}

export const setShowPowermapTip = (powermaptip: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.POWERMAPTIP,
            payload: powermaptip
        })
    }
}

export const setActorVisited = (visited: number) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.ACTORVISITED,
            payload: visited
        })
    }
}

export const setMovedCharacters = (visited: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.MOVEDCHARACTERS,
            payload: visited
        })
    }
}

export const setDashPowermap = (showPowermap: boolean) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.SHOWDASHPOWERMAP,
            payload: showPowermap
        })
    }
}


export const resetDataFetched = () => {
    return async function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.RESET_DATA_FETCHED
        })
    }
}






