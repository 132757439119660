import { IClientCharacter } from "interfaces/storyline"
import actionConsts from "../constants"

const storylineData = (state: any, data: any) => {
	const { payload, type } = data
	switch (type) {
		case actionConsts.STORYLINE: {
			const _state = { ...state, ...payload }
			return _state
		}
		case actionConsts.SET_BG_READ: {
			const prevState = state.clientCharacters
			const charsArray = prevState.map(
				(character: IClientCharacter) => {
					if (character.stc_id === payload) {
						character.gc_bg_read = 1
					}
					return character
				}
			)
			const newState = { clientCharacters: charsArray }
			return { ...state, ...newState }
		}
		case actionConsts.SET_LEVEL2_BG_READ: {
			const prevState = state.clientCharacters
			const charsArray = prevState.map(
				(character: IClientCharacter) => {
					if (character.gc_id === payload) {
						character.gc_bg_read = 2
					}
					return character
				}
			)
			const newState = { clientCharacters: charsArray }
			return { ...state, ...newState }
		}
		case actionConsts.UPDATE_STORYLINE_DATA: {
			const _state = { ...state, ...{ ...payload } }
			return _state
		}
		default: {
			const _statedef = { ...state }
			return _statedef
		}
	}
}

const initUser = (state: any, data: any) => {
	const { payload, type } = data

	switch (type) {
		case actionConsts.INIT: {
			const _state = { ...state, ...payload }
			return _state
		}
		case actionConsts.UPDATE_USER_DATA: {
			const _state = { ...state, ...{ ...payload } }
			return _state
		}
		default: {
			const _statedef = { ...state }
			return _statedef
		}
	}
}

const history = (state: any, data: any) => {
	const { payload, type } = data

	switch (type) {
		case actionConsts.HISTORY: {
			const _state = { ...state, ...{ data: payload } }
			return _state
		}
		case actionConsts.UPDATE_HISTORY: {
			const _state = { ...state, data: [...state.data, payload] }
			return _state
		}
		default: {
			const _statedef = { ...state }
			return _statedef
		}
	}
}

const userMap = (state: any, data: any) => {
	const { payload, type } = data

	switch (type) {
		case actionConsts.USERMAP: {
			if(payload.length != 0){
				const _state = { ...state, ...payload }
				return _state
			}else{
				const _state = {}
				return _state
			}
		}
		case actionConsts.UPDATEPOWERMAPLINKS: {
			if (payload && state.userMap) {
				return { ...state, ...{ userMap: { ...payload } } }
			} else {
				return { ...state }
			}
		}
		default: {
			const _statedef = { ...state }
			return _statedef
		}
	}
}

const leaderboard = (state: any, data: any) => {
	const { payload, type } = data

	switch (type) {
		case actionConsts.LEADERBOARD: {
			const _state = { ...state, ...payload }
			return _state
		}
		default: {
			const _statedef = { ...state }
			return _statedef
		}
	}
}

const currentActionResponse = (state: any, data: any) => {
	const { type, payload } = data;
	switch (type) {
		case actionConsts.UPDATE_CURRENT_ACTION_RESPONSE:
			return { ...{ ...payload } };
		default:
			return state ? state : {};
	}
}

const precheckResponse = (state: any, data: any) => {
	const { type, payload } = data;
	switch (type) {
		case actionConsts.UPDATE_PRECHECK_RESPONSE:
			return { ...{ ...payload } }
		default:
			return state ? state : {}
	}
}

const actionOptionsResponse = (state: any, data: any) => {
	const { type, payload } = data;
	switch (type) {
		case actionConsts.ACTION_OPTION_RESPONSE:
			return { ...state, ...{ ...payload } }
		default:
			return state ? state : { data: [] }
	}
}

const eventData = (state: any, data: any) => {
	const { type, payload } = data;
	switch (type) {
		case actionConsts.ADD_EVENT_DATA: {
			const new_state = { ...state, data: [...state.data, ...payload] }
			return new_state
		}
		case actionConsts.REMOVE_EVENT_DATA: {
			let prev_state = state
			let newdata = []
			for (let i = 0; i < payload.length; i++) {
				newdata = prev_state.data.filter((event: any) => { return event.ev_id !== payload[i].ev_id })
				prev_state = newdata
			}
			const new_state = { ...state, data: newdata }
			return new_state
		}
		default:
			return state ? state : { showEvent: false, data: [] }
	}
}

const reportPowermapData = (state: any, data: any) => {
	const { type, payload } = data;

	switch (type) {
		case actionConsts.REPORTPM:{
			return { ...state, ...{...payload }}
		}
		case actionConsts.REPORTPMCACHED:
			return { ...state, ...{...payload }}
		default:
			return state ? state : {}
	}
}

const reportAnalysis = (state: any, data: any) => {
	let { payload } = data
	const { type } = data
	switch (type) {
		case actionConsts.REPORTANALYSIS:{
			payload = JSON.parse(payload)
			return { ...state, ...{ data: payload.daily } }
		}
		default:
			return state ? state : { data: [] }
	}
}

export { storylineData, initUser, history, userMap, leaderboard, currentActionResponse, precheckResponse, actionOptionsResponse, eventData, reportPowermapData, reportAnalysis }
