import TrustChangeIndicator from "components/commonComponents/trustChangeIndicator/TrustChangeIndicator"
import TrustMeter from "components/dashboard/header/trustMeter/TrustMeter"
import React, { useEffect } from "react"
import { computeOverallTrust, getString } from "utils"
import styles from "./performance.module.scss";
import { useAppSelector } from "config/hooks";

import targetAchievedIcon from 'assets/images/greenTick.svg'
import targetFailedIcon from 'assets/images/warning.svg'
import ProgressBarWithLabel from "components/dashboard/header/trustMeter/progressBarWithLabel/ProgressBarWithLabel";
import { characterImages } from "utils/characterImages";


const Performance: React.FC = () => {

    const clientChars = useAppSelector(state => state.reportPm.performance ? JSON.parse(state.reportPm.performance) : [])
    const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
    const simDays = useAppSelector(state => state.storylineData.simDays)
    const winTrust = useAppSelector(state => state.storylineData.winTrust)
    const overallTrustNow = useAppSelector(state => state.globalData.overallTrust)
    const timer = useAppSelector(state => state.globalData.timer)

    const gameStatus = useAppSelector(state => state.initUser.status)

    let showTargetAnalysis = false
    if (userCurrentDay >= simDays || timer == 0 || gameStatus == 2) {
        showTargetAnalysis = true
    }
    const { overallTrust, overallTrustComp } = computeOverallTrust(clientChars)

    return (
        <div className="performance">
            <div>{getString('label_report_change_in_trust')}</div>
            <div className="trust-meter">
            <div className="progress-bar-container">
                    <ProgressBarWithLabel
                        label_bar={`${getString('label_base_your_firm')} (${(getString('label_character_y'))})`}
                        progress_bar_value={parseFloat(overallTrust.toFixed(0))}
                        reverse={false}
                        deltaTrustValue={-1}
                    />
                    <ProgressBarWithLabel
                        label_bar={`${getString('label_base_competitor')} (${(getString('label_character_c'))})`}
                        progress_bar_value={parseFloat(overallTrustComp.toFixed(0))}
                        reverse={true}
                        deltaTrustValue={-1}
                    />
                </div>
                {/* <TrustMeter
                    label_you=""
                    label_competitor=""
                    overallTrust={overallTrust}
                    overallTrustComp={overallTrustComp}
                /> */}

            </div>
            <div style={{ marginTop: "auto" }}>{getString("label_report_target_trust")}</div>
            {showTargetAnalysis ? parseFloat(overallTrust.toFixed(0)) >= winTrust ?
                <div className='target-analysis-report'>
                    <img className='target-icon' src={targetAchievedIcon} alt='target-achieved' />
                    <div className='target-comment'>{getString("label_target_achieved")}</div>
                </div>
                :
                <div className='target-analysis-report'>
                    <img className='target-icon' src={targetFailedIcon} alt='target-failed' />
                    <div className='target-comment'>{getString("label_target_failed")}</div>
                </div>
                : null
            }

            {/* Actor Impact */}
            <div className={styles.event_res}>
                {
                    clientChars.map((char: any, index: number) => {
                        const img = getString(char['ch_image'])
                        return char['ch_type'] == "0" ? null :
                        (
                            <div
                                key={index}
                                className={styles.card}
                            >
                                <img
                                    src={
                                        characterImages(img)
                                    }
                                    alt="affected-character"
                                    className={styles.char}
                                />
                                <div className={styles.impact}>
                                    <div className={styles.first_name}>
                                        {getString(char['ch_name'])}
                                    </div>
                                    <div className={styles.impact_body}>
                                        <div className={styles.each}>
                                            <span className={styles.label} >
                                                {getString("label_character_y")} :{" "}
                                            </span>
                                            <span className={styles.ty} style={{ color: '#FC8484' }}>
                                                {" "}
                                                {char['gc_trust']}
                                                <span className={styles.percentage}>
                                                    %
                                                </span>
                                            </span>
                                            <TrustChangeIndicator
                                                impactValue={char['gc_trust'] - char['stc_ini_trust']}
                                                positiveColor="#84FCB4"
                                                negativeColor="#FF7171"
                                                neutralColor="#DFEAFB"
                                            />
                                        </div>
                                        <div className={styles.each}>
                                            <span className={styles.label}>
                                                {getString("label_character_c")} :{" "}
                                            </span>
                                            <span className={styles.ty} style={{ color: '#FCDA84' }}>
                                                {" "}
                                                {char['gc_trust_comp']}
                                                <span className={styles.percentage}>
                                                    %
                                                </span>
                                            </span>
                                            <TrustChangeIndicator
                                                impactValue={char['gc_trust_comp'] - char['stc_ini_trust_comp']}
                                                positiveColor="#84FCB4"
                                                negativeColor="#FF7171"
                                                neutralColor="#DFEAFB"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Performance