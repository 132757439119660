import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/hooks";
import "./connector.scss"

const OnetoTwoLine: React.FC = () => {

    const { height, width } = useAppSelector(state => state.dimensions)

    return (
        <svg>
            <defs>
                <linearGradient id="path1" >
                    <stop stopColor="#0D54BD" offset="1" />
                </linearGradient>
            </defs>
            <path d={`M ${width / 4.2} ${height / 20} V ${height / 40} H ${width / 20} V ${height / 20} L ${width / 20} ${height / 20}`}
                strokeWidth="0.1vh" fill="none" stroke="url(#path1)" />

            <line className="line1" x1={width / 7} x2={width / 7} y1="0" y2={height / 40} stroke="url(#line1)"></line>
        </svg >
    )
}

interface IOnetoOne {
    x1: number
    x2: number
    y1?: number
    y2: number
    class?: string
}
const OnetoOneLine: React.FC<IOnetoOne> = (props: IOnetoOne) => {
    const { x1, x2, y1, y2 } = props
    const { height, width } = useAppSelector(state => state.dimensions)

    return (
        <svg className={props.class}>
            <defs>
                <linearGradient id="line1" x1={width / x1} y1={y1 ? height / y1 : 0} x2={width / x2} y2={height / y2} gradientUnits="userSpaceOnUse">
                    <stop stopColor="rgba(5, 75, 125, 0)" offset="3%" />
                    <stop stopColor="#0D54BD" offset="1" stopOpacity="1" />
                </linearGradient>
            </defs>
            <line className="line1" x1={width / x1} x2={width / x2} y1={y1 ? height / y1 : 0} y2={height / y2} stroke="url(#line1)"></line>
        </svg>
    )
}


interface IPathConnector {
	pathD: string;
	class: string;
	cx?: number;
	cy?: number;
	r?: number;
}
const PathConnector: React.FC<IPathConnector> = (props: IPathConnector) => {
	return (
		<svg className={props.class}>
			<defs>
				<linearGradient id="path2">
					<stop stopColor="#0D54BD" offset="1" />
				</linearGradient>
			</defs>
			<path
				d={props.pathD}
				strokeWidth="0.18vh"
				fill="none"
				stroke="url(#path2)"
			/>
			<circle cx={props.cx} cy={props.cy} r={props.r} fill="#0D54BD" />
		</svg>
	);
};


export { OnetoOneLine, OnetoTwoLine, PathConnector }