import { PathConnector } from "components/commonComponents/connectors/connectors";
import { useAppSelector } from "config/hooks";
import React, { useEffect } from "react";
import { addClassToElement, getString, removeClassFromElement } from "utils";
import "./steps.scss";

interface IStep {
	clickHandler: (step: number) => void;
}

const Step5 = (props: IStep) => {
	const { height, width } = useAppSelector((state) => state.dimensions);

	useEffect(() => {
		addClassToElement("client-container", "z-index-3");
		addClassToElement("client-container", "pointer-events-all");
		removeClassFromElement('group-actions', 'z-index-3')
		addClassToElement('group-actions', 'z-index-1')
		addClassToElement('powermap-btn', 'z-index-1')
		addClassToElement("locked-actions", 'z-index-1')
		removeClassFromElement("dashboard-your-team", 'z-index-3')
		addClassToElement("dashboard-your-team", 'z-index-1')
		addClassToElement("dashboard-body", "pointer-events-all");
	}, []);

	const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
		props.clickHandler(3);
		removeClassFromElement("client-container", "z-index-3");
		addClassToElement('group-actions', 'z-index-3')
		removeClassFromElement("dashboard-your-team", 'z-index-1')
		removeClassFromElement("client-container", "pointer-events-all");
		removeClassFromElement("dashboard-body", "pointer-events-all");
		removeClassFromElement("locked-actions", 'z-index-1')
		removeClassFromElement('group-actions', 'z-index-1')
		removeClassFromElement('powermap-btn', 'z-index-1')
	};

	return (
		<>
			<PathConnector
				pathD={`M ${width / 8} ${height / 14} V ${height / 110} H ${
					width / 20
				} L ${width / 30} ${height / 110}`}
				class="s5-connector1 animate fadeIn"
				cx={width / 8}
				cy={height / 14}
				r={height / 300}
			/>
			<div className="client-container-mask animate fadeIn"></div>
			<div className="s5-stakeholder animate fadeIn">
				<div className="s5-stakeholder-content">
					<div className="title">
						{/* Click on the stakeholder’s Profile to view it */}
						{getString('label_wt_msg_trust_tk_action')}
					</div>
				</div>
				<div className="s5-navigation-buttons animate fadeIn">
					<button className="s5-prev" onClick={handlePrevious}>
						{getString('label_gen_previous')}
					</button>
				</div>
			</div>
			<div className="mask-actor animate fadeIn"></div>
			
		</>
	);
};

export default Step5;
