import { PathConnector } from "components/commonComponents/connectors/connectors";
import { useAppSelector } from "config/hooks";
import React, { useEffect } from "react";
import { addClassToElement, getString, removeClassFromElement } from "utils";
import "./steps.scss";
import gradientLine from "assets/images/walkthrough_gradient_line.svg";
interface IStep {
	clickHandler: (step: number) => void;
}

const Step3 = (props: IStep) => {
	const { height, width } = useAppSelector((state) => state.dimensions);

	useEffect(() => {
		addClassToElement("powermap-btn", "z-index-3", "powermap-gradient");
		addClassToElement("dashboard-your-team", "z-index-3");
	}, []);

	const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
		props.clickHandler(1);
		removeClassFromElement(
			"powermap-btn",
			"z-index-3",
			"powermap-gradient"
		);
		removeClassFromElement("dashboard-your-team", "z-index-3");
	};

	const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
		removeClassFromElement(
			"powermap-btn",
			"z-index-3",
			"powermap-gradient"
		)
		removeClassFromElement("dashboard-your-team", "z-index-3")
		props.clickHandler(3)
	};

	return (
		<>
			<PathConnector
				pathD={`M ${width / 100} ${height / 14} V ${height / 110} H ${
					width / 100
				} L ${width / 10} ${height / 110}`}
				class="s3-connector1 animate fadeIn"
				cx={width / 100}
				cy={height / 14}
				r={height / 300}
			/>
			<div className="s3-powermap animate fadeIn">
				<div className="s3-powermap-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_powermap_1') }}>
					</div>
				</div>
			</div>
			<PathConnector
				pathD={`M ${height / 3.8} ${width / 30} V ${width / 110} H ${
					height / 20
				} L ${height / 100} ${width / 110}`}
				class="s3-connector2 animate fadeIn"
				cx={height / 3.8}
				cy={width / 30}
				r={width / 500}
			/>
			<div className="s3-your-team animate fadeIn">
				<div className="s3-your-team-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_your_team_1') }}>

					</div>
				</div>
				<div className="s3-navigation-buttons animate fadeIn">
					<button className="s3-prev" onClick={handlePrevious}>
						{getString('label_gen_previous')}
					</button>
					<button className="s3-next" onClick={handleNext}>
						{getString('label_gen_next')}
					</button>
				</div>
			</div>

		</>
	);
};

export default Step3;
