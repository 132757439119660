import React, { useEffect } from "react";

import { Tab, Tabs } from '@mui/material';
import TabSwitcher from "./TabSwitcher"
import Introduction from "./Introduction"
import About from "./About"
import Objective from "./Objective"
import MeetYourTeam from "./MeetYourTeam"
import "./body.scss"
import { getString } from "utils";

interface IntroBodyProps {
    isIntroCompleted?: boolean
}

const IntroBody: React.FC<IntroBodyProps> = (props: IntroBodyProps) => {
    const [currentTab, setValue] = React.useState(0);

    // if seen = true then currentTab 3
    // Update mty state , showButtons, showIntroPowermap to true, showIntroPowermapLoader= false
    useEffect(() => {
        if (props.isIntroCompleted) {
            setValue(3)
        }
    }, [])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    const handleTabChange = (newValue: number) => {
        setValue(newValue)
    }
    return (
        <div className="intro-tabs">
            <Tabs
                value={currentTab}
                classes={{ indicator: "indicator" }}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={getString("label_intro_introduction")} className="tabHead" disableRipple />
                <Tab label={getString("label_intro_abt_comm")} className="tabHead" disableRipple />
                <Tab label={getString("label_base_objectives")} className="tabHead" disableRipple />
                <Tab label={getString("label_intro_myt")} className="tabHead" disableRipple />
            </Tabs>

            <TabSwitcher value={currentTab} index={0}>
                <Introduction onTabChange={handleTabChange} />
            </TabSwitcher>
            <TabSwitcher value={currentTab} index={1}>
                <About onTabChange={handleTabChange} />
            </TabSwitcher>
            <TabSwitcher value={currentTab} index={2}>
                <Objective onTabChange={handleTabChange} />
            </TabSwitcher>
            <TabSwitcher value={currentTab} index={3}>
                <MeetYourTeam onTabChange={handleTabChange} isIntroCompleted={props.isIntroCompleted} />
            </TabSwitcher>

        </div>
    )
};

export default IntroBody;
