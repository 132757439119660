import { Tabs, Tab } from "@mui/material"
import Popup from "components/commonComponents/popup/Popup"
import Objective from "components/intro/IntroBody/Objective"
import TabSwitcher from "components/intro/IntroBody/TabSwitcher"

import Performance from "./performance"
import Leaderboard from "./leaderboardReport"
import PowermapReport from "./powermapReport"
import Analysis from "./analysis"
import React, { useEffect, useState } from "react"
import "./report.scss"
import { useAppDispatch, useAppSelector } from "config/hooks"
import { bindActionCreators } from "redux"
import * as actionCreators from "store/actionCreator"
import { getString } from "utils"

interface IMonthlyReport {
    close: () => void
}

const MonthlyReport: React.FC<IMonthlyReport> = (props: IMonthlyReport) => {

    const [showComponent, setShowComponent] = useState(true)
    const [currentTab, setValue] = React.useState(0);

    const showReport = useAppSelector(state => state.globalData.showReport)
    const simDays = useAppSelector(state => state.storylineData.simDays)
    const month = useAppSelector(state => state.globalData.month)
    const day = useAppSelector(state => state.reportPm.day)

    const dispatch = useAppDispatch();
    const { getReportPowermap, getReportPowermapCached, setShowReport } = bindActionCreators(actionCreators, dispatch)
    // const  audio = new Audio("/popup.mp3")
    
    const handleClose = () => {
        setShowReport(0)
        setShowComponent(false)
        props.close()
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    useEffect(() => {
        // api
        // getReportAnalysis()
        // audio.play()
        // if (showReport == 2)
            getReportPowermap(month)
        // else
        //     getReportPowermapCached()
    }, [])

    return (
        <>
            {showComponent || showReport == 2 ?
                <Popup handleClose={handleClose}>
                    < div className="phead" >
                        <div style={{ display: "contents" }}>
                            <div className="metric-popup-icon">
                                <img src={require(`assets/images/reports.svg`).default} style={{ width: "2vw" }} />
                            </div>
                            <span>&nbsp;{getString("label_base_report")}</span>
                            <span className="day">&nbsp;{"| " + getString("label_base_day") + " " + (day > simDays ? simDays : day)}</span>
                        </div>
                    </div >
                    <div className="curved-header"></div>
                    <div className="pbody report" style={{ marginRight: "0", paddingTop: "1vh", width: "80vw", height: "75vh" }}>
                        <Tabs
                            value={currentTab}
                            classes={{ indicator: "indicator" }}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label={getString("label_report_performance")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} />
                            {/* <Tab label={getString("label_base_leaderboard")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} /> */}
                            <Tab label={getString("label_report_powermap")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} />
                            <Tab label={getString("label_report_analysis")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} />
                        </Tabs>
                        <TabSwitcher value={currentTab} index={0}>
                            <Performance />
                        </TabSwitcher>
                        {/* <TabSwitcher value={currentTab} index={1}>
                            <Leaderboard />
                        </TabSwitcher> */}
                        <TabSwitcher value={currentTab} index={1}>
                            <PowermapReport />
                        </TabSwitcher>
                        <TabSwitcher value={currentTab} index={2}>
                            <Analysis />
                        </TabSwitcher>
                    </div>
                </Popup > : null
            }
        </>
    )
}

export default MonthlyReport