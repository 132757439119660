import React, { useState } from "react";
import { Powermap } from "components/commonComponents/powermap";
import RepsMap from "components/commonComponents/repsMap/RepsMap";
import { bindActionCreators } from "redux"
import { useAppDispatch, useAppSelector } from "config/hooks";
import { actionCreators } from "store";

interface PowermapIntroProps {
    showPowermapTip?: boolean
    hidePowermapTip?: () => void
    showCharacterTab?: (chr: any) => void
}

const PowermapIntro: React.FC<PowermapIntroProps> = (props: PowermapIntroProps) => {
    const [showInfo, setShowInfo] = useState(false)
    const movedCharacters = useAppSelector(state => state.mty.movedCharacters);

    const dispatch = useAppDispatch()

    const { setShowPowermapTip } = bindActionCreators(actionCreators, dispatch)

    const renderInfo = () => {
        setShowInfo(true)
        setShowPowermapTip(false)
    }

    const hideInfo = () =>{
        setShowInfo(false)
        !movedCharacters  ? setShowPowermapTip(true) : null // only if actor images have not moved.
    }

    return (
        <div className="powermap-container">
            {!showInfo ? <Powermap isIntro={true} 
                                   isGameOver={false}
                                   renderInfo={renderInfo} 
                                   hidePowermapTip={props.hidePowermapTip} 
                                   showPowermapTip={props.showPowermapTip}
                                   showCharacterTab={props.showCharacterTab} /> :

                <RepsMap showIcon={true} handleClick={hideInfo} />}

        </div >
    )
}

export default PowermapIntro