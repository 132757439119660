import React, { useEffect, useRef } from "react";
import { bindActionCreators } from "redux"
// import { bindActionCreators } from "redux"
import * as  jointjs from "jointjs"
import $ from "jquery"

import { useAppDispatch, useAppSelector } from "config/hooks";
import { getString } from "utils";
import { actionCreators } from "store";
import { characterImages } from "utils/characterImages";

interface PowermapProps {
    isIntro: boolean
    isGameOver: boolean
    isReport?: boolean
    width?:number
    height?:number
    config?: any
    showPowermapTip?: boolean
    renderInfo: () => void
    hidePowermapTip?: () => void
    showCharacterTab?: (chr: any) => void
}



const Powermap: React.FC<PowermapProps> = (props: PowermapProps) => {
    const clientCharacters = props.isReport ? useAppSelector(state => JSON.parse(state.reportPm.performance)) : useAppSelector(state => state.storylineData.clientCharacters)
    const teamChars = useAppSelector(state => state.storylineData.teamCharacters)
    const teamCharacters = teamChars ? teamChars.length > 3 ? teamChars.slice(0, 3) : null : null
    const updatedLocations = props.isReport ? useAppSelector(state => state.reportPm.performance ? JSON.parse(state.reportPm.performance) : []) : useAppSelector(state => state.dash.updatedLocations);
    const updatedLinks = props.isReport ? useAppSelector(state => JSON.parse(state.reportPm.links)) : useAppSelector(state => state.userMap);
    // const gameStatus = useAppSelector(state => state.initUser.status)

    const dispatch = useAppDispatch();
    const { setPowermapLocations, createPowermapLink, updatePowermapLink, removePowermapLink, setMovedCharacters } = bindActionCreators(actionCreators, dispatch)
   
    const first = useRef(true);
    
    useEffect(() => {
        if (first.current) { // to prevent it from re render everytime we open the powermap.
            first.current = false;
            return;
          }
          const len = Object.keys(updatedLinks).length
          switch (props.config && props.config.instruction) {
              case "draw": {
                  createPowermapLink(updatedLinks[len-1], updatedLinks)
                  break;
              }
              case "update": {
                  updatePowermapLink(updatedLinks[len-1], updatedLinks)
                  break;
              }
              case "remove": {
                  const links = Object.keys(updatedLinks)
                  let link = {}, key = ""
                  for(let i =0; i <links.length;i++){
                        const _link = updatedLinks[links[i]]

                        if (_link["ln_src"] == props.config.from && _link["ln_tar_id"] == props.config.to || 
                            (_link["ln_src"] == props.config.to && _link["ln_tar_id"] == props.config.from)) {
                                link = _link
                                key = links[i]
                                break
                        }
                    }
                  delete updatedLinks[key];
                  removePowermapLink(link, updatedLinks)
                  break;
              }
              default: return
          }
            
    }, [props.config])

    useEffect(()=>{
        renderMap()
        return () => {
            // update updatedLinks userMap api
            if (!props.isGameOver)
                setPowermapLocations(updatedLocations)
        }
    },[])

    const customImage = jointjs.shapes.standard.BorderedImage.define('CustomImage', {
        defaults: jointjs.util.defaultsDeep({
            type: 'html.CustomImage',
        }, jointjs.shapes.standard.BorderedImage.prototype)
    }, {
        markup: [{
            tagName: 'circle',
            selector: 'background',
        }, {
            tagName: 'image',
            selector: 'image'
        },
        {
            tagName: 'rect',
            selector: 'labelBody'
        }, {
            tagName: 'text',
            selector: 'label'
        }, {
            tagName: 'text',
            selector: 'subtext'
        }]
    });

    const renderMap = () => {

        const graph = new jointjs.dia.Graph;

        const element = document.getElementById("myholder")!
        if (element) {
            $('#myholder').unbind()
        }
        const paper = new jointjs.dia.Paper({
            el: element,
            model: graph,
            height: '100%',
            width: '100%',
            gridSize: 1,
            restrictTranslate: true
        })

        renderBaseQuadrants(graph, paper)
        props.isIntro ? renderTeamCharacters(graph, paper) : renderAllCharacters(graph, paper)
        if (props.config && Object.keys(props.config).length !== 0) {
            updateLinks(props.config, graph, paper)
        }
        props.isReport ? paper.scaleContentToFit({padding: 1}): null

    }

    const renderBaseQuadrants = (graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        const { width, height } = paper.getComputedSize()

        const posY = new jointjs.shapes.standard.Link();
        posY.source(new jointjs.g.Point(width / 2, height / 2));
        posY.target(new jointjs.g.Point(width / 2, 0));
        posY.attr({
            "line": { stroke: "#CFDDF2" },
            "wrapper": { pointerEvents: "none" }
        })
        posY.addTo(graph);

        const negX = posY.clone()
        negX.source(new jointjs.g.Point(width / 2, height / 2))
        negX.target(new jointjs.g.Point(0, height / 2))
        negX.addTo(graph);

        const posX = posY.clone()
        posX.source(new jointjs.g.Point(width / 2, height / 2))
        posX.target(new jointjs.g.Point(width, height / 2))
        posX.addTo(graph);

        const negY = posY.clone()
        negY.source(new jointjs.g.Point(width / 2, height / 2))
        negY.target(new jointjs.g.Point(width / 2, height))
        negY.addTo(graph);

        renderTexts(graph, paper)
    }

    const renderTexts = (graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        // texts
        const { width, height } = paper.getComputedSize()

        const sch = new jointjs.shapes.standard.Rectangle();
        sch.position(width / 4, height / 30);
        sch.attr({
            body: {
                stroke: 'none'
            },
            label: {
                text: getString("label_intro_scholar"),
                fontFamily: "Roboto",
                fontSize: 12,
                fontWeight: 400
            }
        });
        sch.addTo(graph);

        const rel = sch.clone()
        rel.position(width / 1.3, height / 30)
        rel.attr({
            label: {
                text: getString("label_intro_relator")
            }
        })
        rel.addTo(graph)

        const exec = sch.clone()
        exec.position(width / 4, height / 1.05)
        exec.attr({
            label: {
                text: getString("label_intro_executor")
            }
        })
        exec.addTo(graph)

        const prom = sch.clone()
        prom.position(width / 1.3, height / 1.05)
        prom.attr({
            label: {
                text: getString("label_intro_promoter")
            }
        })
        prom.addTo(graph)

        const info = new jointjs.shapes.standard.Image({
            attrs: {
                image: {
                    xlinkHref: require('assets/images/intro/pm_info.svg').default,
                    cursor: "pointer"
                },
            }
        });
        info.resize(height / 18, height / 18);
        info.position(width - height / 14, height / 50);
        info.addTo(graph);

        paper.findViewByModel(info).on('cell:pointerclick', function () {
            
            props.renderInfo()
        })

        const io = new jointjs.shapes.standard.Rectangle();
        io.position(width / 1.94, height / 7);
        io.attr({
            body: {
                stroke: 'none'
            },
            label: {
                text: getString('label_pm_internal_oriented'),
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: 400,
                fill: "#0D54BD",
                transform: "rotate(-90deg)"
            }
        });
        io.addTo(graph);

        const exo = sch.clone()
        exo.position(width / 1.94, height / 1.2)
        exo.attr({
            label: {
                text: getString("label_pm_external_oriented"),
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: 400,
                fill: "#0D54BD",
                transform: "rotate(-90deg)"
            }
        })
        exo.addTo(graph)

        const to = sch.clone()
        to.position(width / 8, height / 2.07)
        to.attr({
            label: {
                text: getString("label_pm_task_oriented"),
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: 400,
                fill: "#0D54BD",
            }
        })
        to.addTo(graph)

        const pgo = sch.clone()
        pgo.position(width / 1.15, height / 2.07)
        pgo.attr({
            label: {
                text: getString("label_pm_ppl_oriented"),
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: 400,
                fill: "#0D54BD",
            }
        })
        pgo.addTo(graph)
    }


    const renderTeamCharacters = (graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        const { width, height } = paper.getComputedSize()

        // arrows for the top character
        const leftTop = new jointjs.shapes.standard.Link();
        leftTop.source(new jointjs.g.Point(width / 2.7, height / 2.2));
        leftTop.target(new jointjs.g.Point(width / 3.1, height / 2.5));
        leftTop.attr({
            "line": { stroke: "#0D54BD", strokeDasharray: 5 },
            "wrapper": { pointerEvents: "none" }
        })

        const rightTop = leftTop.clone()
        rightTop.source(new jointjs.g.Point(width / 2, height / 2.2))
        rightTop.target(new jointjs.g.Point(width / 1.85, height / 2.5))

        const leftBottom = leftTop.clone()
        leftBottom.source(new jointjs.g.Point(width / 2.6, height / 1.8))
        leftBottom.target(new jointjs.g.Point(width / 3, height / 1.6))

        const rightbottom = leftTop.clone()
        rightbottom.source(new jointjs.g.Point(width / 2.15, height / 1.8))
        rightbottom.target(new jointjs.g.Point(width / 1.93, height / 1.6))



        if (teamCharacters) 
            for (let i = teamCharacters.length - 1; i >= 0; i--) {
                const imgUrl = getString(teamCharacters[i]['ch_image'])

                const exists = updatedLocations.filter((ele: { [x: string]: any; }) => {
                    return ele["stc_id"] === teamCharacters[i]['stc_id'] ? ele : null
                })

                let position
                if (exists.length != 0) {
                    position = { x: exists[0].gc_x, y: exists[0].gc_y }
                } else {
                    position = { x: width / (3 - i / 3), y: height / 2.2 }
                    if (props.showPowermapTip) {
                        leftTop.addTo(graph);
                        rightbottom.addTo(graph);
                        rightTop.addTo(graph);
                        leftBottom.addTo(graph);
                    }
                }

                const character = new customImage({
                    position,
                    size: { width: height / 7, height: height / 7 },
                    attrs: {
                        image: {
                            xlinkHref: characterImages(imgUrl)
                        },
                        background: {
                            stroke: '#0D54BD',
                            strokeWidth: 0,
                            r: height / 13.8,
                            cx: height / 14,
                            cy: height / 14

                        },
                    }
                })
                if (i == 0 && exists.length == 0) {
                    character.embed(leftTop)
                    character.embed(rightbottom)
                    character.embed(rightTop)
                    character.embed(leftBottom)
                    character.attr({
                        background: {
                            strokeWidth: 2,
                        }
                    })
                    character.addTo(graph)
                } else {
                    character.addTo(graph)

                }
                paper.findViewByModel(character).on('cell:pointerdown', function () {
                    // tab change
                    // props.showCharacterTab ? props.showCharacterTab(teamCharacters[i]) :  null
                    // console.log(teamCharacters[i])
                    
                    character.unembed(leftTop)
                    character.unembed(rightbottom)
                    character.unembed(rightTop)
                    character.unembed(leftBottom)
                    // enter means add blue shadow
                    leftTop.remove();
                    rightbottom.remove();
                    rightTop.remove();
                    leftBottom.remove();
                    character.attr({
                        background: {
                            strokeWidth: 2,
                        }
                    })
                });
                paper.findViewByModel(character).on('cell:pointerup', function () {

                    if (props.showPowermapTip) {
                        setMovedCharacters(true)
                        props.hidePowermapTip ? props.hidePowermapTip() : null
                    }
                    // leave means remove blue shadow
                    character.attr({
                        background: {
                            strokeWidth: 0,
                        }
                    })

                    const exists = updatedLocations.filter((ele: { [x: string]: any; gc_x: number | undefined; gc_y: number | undefined; }) => {
                        if (ele["stc_id"] === teamCharacters[i]['stc_id']) {
                            ele.gc_x = character.attributes.position?.x
                            ele.gc_y = character.attributes.position?.y
                            return true
                        }
                    })
                    if (exists.length == 0)
                        updatedLocations.push({
                            gc_stc_id: teamCharacters[i]['stc_id'],
                            stc_id: teamCharacters[i]['stc_id'],
                            gc_x: character.attributes.position?.x,
                            gc_y: character.attributes.position?.y
                        })
                });
            }

    }

    const renderAllCharacters = (graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        const { width, height } = paper.getComputedSize()
        teamCharacters ? teamCharacters.length > 3 ? teamCharacters.splice(3) : null : null

        const characters = props.isReport ? clientCharacters :[...clientCharacters, ...teamCharacters]

        if (teamCharacters && clientCharacters) {
            for (let i = characters.length - 1; i >= 0; i--) {
                const exists = updatedLocations.filter((ele: { [x: string]: any; }) => {
                    return ele["gc_stc_id"] === characters[i]['stc_id'] ? ele : null
                })

                let position = {x: width / (3 - i / 6), y: height / 2.2}
                if (exists.length != 0) {
                    const scale_x = exists[0].gc_x >= width ? width / 1.4 : exists[0].gc_x
                    const scale_y = exists[0].gc_y >= height ? height / 1.4 : exists[0].gc_y
                    position = { x: scale_x, y: scale_y}
                }
                const imgUrl = getString(characters[i]['ch_image'])
                const character = new customImage({
                    id: characters[i]['stc_id'],
                    position: position,
                    size: { width: height / 7, height: height / 7 },
                    attrs: {
                        image: {
                            xlinkHref: characterImages(imgUrl)
                        },
                        background: {
                            stroke: '#0D54BD',
                            strokeWidth: 0,
                            r: height / 13.8,
                            cx: height / 14,
                            cy: height / 14

                        },
                        labelBody: {
                            fill: '#054B7D',
                            stroke: '#fff',
                            strokeWidth: .5,
                            width: "5vw",
                            height: "4vmin",
                            y: '8.5vh',// decides the box over image
                            filter: "drop-shadow( 0px 0px#0D54BD)",
                            rx: "3%",
                            // justifyItem: "center",

                        },
                        label: {
                            text: getString(characters[i]['ch_name']).split(" ")[0],
                            fontSize: "2vmin",
                            fontFamily: "Roboto",
                            fill: "#fff",
                            textAlign: "center",
                            x: props.isReport ? "1vmin": 1,
                            y: props.isReport ? "1vmin" : -8,
                        },
                        subtext: {

                        }
                    }
                })
                if (i >= 6)
                    character.attr({
                        labelBody: {
                            height: "6vmin",
                            fill: "#fff",
                            stroke: '#4F6382',
                            strokeWidth: .5
                        },
                        label:{
                            fill:"#0D54BD",
                            text: getString(characters[i]['ch_name']).split(" ")[0],
                            fontSize: "2vmin",
                            fontFamily: "Roboto",
                            textAlign: "center",
                            x: props.isReport ? "1vmin": 1,
                            y: props.isReport ? "1vmin" : -8,
                        },
                        subtext: {
                            text: getString("label_pm_team"),
                            fontSize: "1.7vmin",
                            fontFamily: "Roboto",
                            fill: "#4F6382",
                            textAlign: "center",
                            x: "1.1vmax",
                            y: "13.5vmin",
                        }
                    })
                character.addTo(graph)

                paper.findViewByModel(character).on('cell:pointerdown', function () {
                    // enter means remove blue shadow
                    character.attr({
                        background: {
                            strokeWidth: 2,
                        }
                    })
                });
                paper.findViewByModel(character).on('cell:pointerup', function () {
                    // leave means remove blue shadow
                    character.attr({
                        background: {
                            strokeWidth: 0,
                        }
                    })
                    const exists = updatedLocations.filter((ele: { [x: string]: any; gc_x: number | undefined; gc_y: number | undefined; }) => {
                        if (ele["gc_stc_id"] === characters[i]['stc_id']) {
                            ele.gc_x = character.attributes.position?.x
                            ele.gc_y = character.attributes.position?.y
                            return true
                        }
                    })
                    if (exists.length == 0)
                        updatedLocations.push({
                            gc_stc_id: characters[i]['stc_id'],
                            gc_x: character.attributes.position?.x,
                            gc_y: character.attributes.position?.y
                        })
                });
            }
            renderLinks(characters, graph, paper)
        }
    }

    const updateLinks = (config: any, graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        const { from, to, instruction, direction, influence, isBidirection } = config
        if (checkExistingLinks(config, graph, paper))
            return // update/ remove has been done on existing link
        if (instruction === "draw") {

            const link1 = new jointjs.shapes.standard.Link({
                id: from + "#" + to,
                source: {
                    id: String(from)
                },
                target: {
                    id: String(to)
                }
            });

            let color
            if (direction == "positive") {
                color = "#84FCB4"
            } else {
                color = "#FC8484"
            }

            if (isBidirection) {
                link1.attr({
                    "line": {
                        stroke: color,
                        strokeWidth: 1 + 0.03 * influence,
                        sourceMarker: {
                            'type': 'path',
                            'stroke': color,
                            'fill': color,
                            'd': 'M 10 -5 0 0 10 5 Z'
                        },
                        targetMarker: {
                            'type': 'path',
                            'stroke': color,
                            'fill': color,
                            'd': 'M 10 -5 0 0 10 5 Z'
                        }
                    },
                    "wrapper": { pointerEvents: "none" }
                });
            } else {
                link1.attr({
                    "line": {
                        stroke: color,
                        strokeWidth: 1 + 0.03 * influence
                    },
                    "wrapper": { pointerEvents: "none" }
                });
            }
            const next = Object.keys(updatedLinks).length
            updatedLinks[next] = {
                ln_src: parseInt(from),
                ln_tar_id: parseInt(to),
                ln_dir: isBidirection ? 1 : 0,
                ln_col: direction == "positive" ? 1 : 0,
                ln_width: influence,
            }
            link1.addTo(graph);
        }
    }

    const checkExistingLinks = (config: any, graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        // find and update or remove exitsing link as per the instruction
        const { from, to, instruction, direction, influence, isBidirection } = config
        const fromTo = from + '#' + to
        const toFrom = to + '#' + from
        const link1 = graph.getCell(fromTo) ? graph.getCell(fromTo) : graph.getCell(toFrom) ? graph.getCell(toFrom) : null
        if (link1 && instruction !== "remove") {
            // update link1
            let color
            if (direction == "positive") {
                color = "#84FCB4"
            } else {
                color = "#FC8484"
            }

            link1.id = from + "#" + to
            if (isBidirection)
                link1.attr({
                    source: {
                        id: String(from)
                    },
                    target: {
                        id: String(to)
                    },
                    line: {
                        stroke: color,
                        strokeWidth: 1 + 0.03 * influence,
                        sourceMarker: {
                            'type': 'path',
                            'stroke': color,
                            'fill': color,
                            'd': 'M 10 -5 0 0 10 5 Z'
                        },
                        targetMarker: {
                            'type': 'path',
                            'stroke': color,
                            'fill': color,
                            'd': 'M 10 -5 0 0 10 5 Z'
                        }
                    },
                })
            else
                link1.attr({
                    "line": {
                        stroke: color,
                        strokeWidth: 1 + 0.03 * influence
                    },
                    "wrapper": { pointerEvents: "none" }
                });
            // update
            Object.keys(updatedLinks).filter((_link: any) => {

                _link = updatedLinks[_link]
                if (_link["ln_src"] == from && _link["ln_tar_id"] == to) {
                    _link["ln_dir"] = isBidirection ? 1 : 0
                    _link["ln_col"] = direction == "positive" ? 1 : 0
                    _link["ln_width"] = influence
                } else if (_link["ln_src"] == to && _link["ln_tar_id"] == from) {
                    _link["ln_src"] = parseInt(from)
                    _link["ln_tar_id"] = parseInt(to)
                    _link["ln_dir"] = isBidirection ? 1 : 0
                    _link["ln_col"] = direction == "positive" ? 1 : 0
                    _link["ln_width"] = influence
                }
            })
            return true
        } else {
            if (!link1)
                return false
        }
    }

    const renderLinks = (characters: any[], graph: jointjs.dia.Graph, paper: jointjs.dia.Paper) => {
        if (updatedLinks && Object.keys(updatedLinks).length == 0)
            return
        Object.keys(updatedLinks).filter((_link: any) => {
            let src, targ
            _link = updatedLinks[_link]
            if (_link["ln_src"] && _link["ln_tar_id"]) {
                src = _link["ln_src"]
                targ = _link["ln_tar_id"]
            }
            const link1 = new jointjs.shapes.standard.Link({
                id: src + "#" + targ,
                source: {
                    id: String(src)
                },
                target: {
                    id: String(targ)
                }
            });
            let color
            if (_link["ln_col"]) {
                color = "#84FCB4"
            } else {
                color = "#FC8484"
            }
            if (_link["ln_dir"]) {
                link1.attr({
                    "line": {
                        stroke: color,
                        strokeWidth: 1 + 0.03 * _link["ln_width"],
                        sourceMarker: {
                            'type': 'path',
                            'stroke': color,
                            'fill': color,
                            'd': 'M 10 -5 0 0 10 5 Z'
                        },
                        targetMarker: {
                            'type': 'path',
                            'stroke': color,
                            'fill': color,
                            'd': 'M 10 -5 0 0 10 5 Z'
                        }
                    },
                    "wrapper": { pointerEvents: "none" }
                });
            } else {
                link1.attr({
                    "line": {
                        stroke: color,
                        strokeWidth: 1 + 0.03 * _link["ln_width"]
                    },
                    "wrapper": { pointerEvents: "none" }
                });
            }
            link1.addTo(graph);
        })
    }

    return (
        <div style={{ height: `${props.height? props.height:'100'}%`, width: `${props.width? props.width: '100'}%` , pointerEvents: `${ props.isGameOver ? "none": "unset"}` }}>
            <div id="myholder"></div>
            {renderMap()}
        </div>
    )
}

export { Powermap }