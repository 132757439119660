import React, { useEffect, useState } from "react";
import close from "assets/images/close.svg"
import { getString } from "utils";
import * as  jointjs from "jointjs"
import { useAppSelector } from "config/hooks";
import { characterImages } from "utils/characterImages";



interface IPowermapDesc {
    close: () => void
}

const PowermapDesc: React.FC<IPowermapDesc> = (props: IPowermapDesc) => {
    const [showComponent, setShowComponent] = useState(true)
    const { clientCharacters } = useAppSelector(state => state.storylineData)

    const clientImage1 = getString(clientCharacters[0]['ch_image'])
    const clientImage2 = getString(clientCharacters[1]['ch_image'])

    useEffect(() => renderImage())

    const renderImage = () => {
        const graph = new jointjs.dia.Graph;

        const element = document.getElementById("image")!

        const paper = new jointjs.dia.Paper({
            el: element,
            model: graph,
            height: '30vh',
            width: '90%',
            gridSize: 1,
            restrictTranslate: true
        })

        renderInfluence(paper, graph, "negative", 0, 0)
        renderInfluence(paper, graph, "positive", 2, 0)
        renderInfluence(paper, graph, "bidirectional", 0, 2)
        renderLinks(paper, graph, "low")
        renderLinks(paper, graph, "medium")
        renderLinks(paper, graph, "high")
    }
    const renderInfluence = (paper: jointjs.dia.Paper, graph: jointjs.dia.Graph, influence: string, x: number, y: number) => {
        const { width, height } = paper.getComputedSize()

        const fromActorImage = new jointjs.shapes.standard.BorderedImage({
            id: clientCharacters[0]['stc_id'] + influence,
            size: { width: height / 4, height: height / 2 },
            attrs: {
                image: {
                    xlinkHref: characterImages(clientImage1)
                },
                border: {
                    stroke: 'none',
                },
                root: { pointerEvents: "none" }
            }
        })
        fromActorImage.position(!x ? x : width / x, !y ? 0 : height / y)
        fromActorImage.addTo(graph);

        const toActorImage = new jointjs.shapes.standard.BorderedImage({
            id: clientCharacters[1]['stc_id'] + influence,
            size: { width: height / 4, height: height / 2 },
            attrs: {
                image: {
                    xlinkHref: characterImages(clientImage2)
                },
                border: {
                    stroke: 'none',
                },
                root: { pointerEvents: "none" }
            }
        })
        toActorImage.position(!x ? x + width / 2.8 : width / x + width / 2.8, !y ? 0 : height / y)
        // console.log("to", toActorImage)
        // console.log("from ", fromActorImage)
        toActorImage.addTo(graph)

        const color = influence === "positive" ? "#84FCB4" : "#FC8484"

        const ngLink = new jointjs.shapes.standard.Link({
            source: {
                id: String(clientCharacters[0]['stc_id'] + influence)
            },
            target: {
                id: String(clientCharacters[1]['stc_id'] + influence)
            },
            attrs: {
                "line": {
                    stroke: color,
                    fill: color,
                    strokeWidth: 3
                },
                "wrapper": { pointerEvents: "none" }
            }
        });

        const ngInfluence = new jointjs.shapes.standard.Rectangle();
        ngInfluence.position(!x ? x + width / 4.5 : width / x + width / 4.5, !y ? height / 3 : height / y + height / 3);
        ngInfluence.attr({
            body: {
                stroke: 'none'
            },
            label: {
                text: influence == "negative" ? getString("label_negative_influence") : getString("label_positive_influence"),
                fontFamily: "Roboto",
                fontSize: 16,
                fontWeight: 400
            }
        });
        if (influence == "bidirectional") {
            ngLink.attr({
                "line": {
                    stroke: color,
                    fill: color,
                    strokeWidth: 3,
                    sourceMarker: {
                        'type': 'path',
                        'stroke': color,
                        'fill': color,
                        'd': 'M 10 -5 0 0 10 5 Z'
                    },
                    targetMarker: {
                        'type': 'path',
                        'stroke': color,
                        'fill': color,
                        'd': 'M 10 -5 0 0 10 5 Z'
                    }
                }
            })

            ngInfluence.attr({
                label: {
                    text: getString("label_bidirectional_influence"),
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: 400
                }
            })
        }

        ngLink.addTo(graph);
        ngInfluence.addTo(graph);
    }

    const renderLinks = (paper: jointjs.dia.Paper, graph: jointjs.dia.Graph, influence: string) => {
        const { width, height } = paper.getComputedSize()
        let text = "", strokeWidth = 1, space = 1
        switch (influence) {
            case "low": {
                text = getString("label_low_influence")
                strokeWidth = 1
                space = 1
                break;
            }
            case "medium": {
                text = getString("label_medium_influence")
                strokeWidth = 2
                space = 1.5
                break;
            }
            case "high": {
                text = getString("label_high_influence")
                strokeWidth = 3
                space = 2
                break;
            }
        }

        const influenceLink = new jointjs.shapes.standard.Link({
            source: new jointjs.g.Point(width / 2, height / (2 - 0.4 * space)),
            target: new jointjs.g.Point(width / 1.5, height / (2 - 0.4 * space)),
            attrs: {
                "line": {
                    stroke: "#FC8484",
                    fill: "#FC8484",
                    strokeWidth
                },
                "wrapper": { pointerEvents: "none" }
            }
        });
        influenceLink.addTo(graph)

        const influenceText = new jointjs.shapes.standard.Rectangle();
        influenceText.position(width / 1.3, height / (2 - 0.4 * space));
        influenceText.attr({
            body: {
                stroke: 'none'
            },
            label: {
                text,
                fontFamily: "Roboto",
                fontSize: 16,
                fontWeight: 400
            }
        });
        influenceText.addTo(graph)
    }

    return (
        <>
            {showComponent ?
                <div className="desc-container">
                    <img src={close} onClick={props.close} style={{ cursor: "pointer", float: "right", padding:"1vh 1vh 0 0" }} />
                    <div className="desc-header">
                        <p style={{ paddingTop: "2vh" }}>{getString("label_base_powermap")}</p>
                    </div>
                    <div className="curved-header"></div>
                    <div className="desc-body">
                        <p dangerouslySetInnerHTML={{ __html: getString("label_pm_desc") }}></p><br /><br />
                        <p><span className="steps">{getString("label_pm_step_1")}</span>: <span dangerouslySetInnerHTML={{ __html: getString("label_pm_step1_heading") }}></span></p>
                        <p dangerouslySetInnerHTML={{ __html: getString("label_pm_step_1_desc") }}></p><br />
                        <p><span className="steps">{getString("label_pm_step_2")}</span>: <span dangerouslySetInnerHTML={{ __html: getString("label_pm_step2_heading") }}></span></p><br />
                        <p dangerouslySetInnerHTML={{ __html: getString("label_pm_step_2_desc") }}></p><br />
                        <div id="image">
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}

export default PowermapDesc