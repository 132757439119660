import Popup from "components/commonComponents/popup/Popup";
import React from "react";

import PopupFooter from "assets/images/popup_footer.svg";
import "./baseActionResponse.scss";
import rightArrow from "assets/images/rightArrow_gradient.svg";
import TrustChangeIndicator from "components/commonComponents/trustChangeIndicator/TrustChangeIndicator";
import { Button } from "@mui/material";
import { useAppSelector } from 'config/hooks';
import { getAffectedCharList, getString } from 'utils';
import { IClientCharacter } from 'interfaces/storyline';
import FlashToolTip from 'components/commonComponents/flashToolTip/FlashToolTip';
import { characterImages } from "utils/characterImages";
interface BaseActionResponseProps {
	// directlyAffectedClientList: number[];
	// indirectlyAffectedClientList: number[];
	// teamMember: number;
	handleExit: () => void;
}

const BaseActionResponse = (props: BaseActionResponseProps) => {

	const selectedTeamCharData = useAppSelector((state) => state.globalData.selectedTeamMemberData)
	const actionResponseData = useAppSelector((state) => state.currentActionResponse)
	// const actionStaId = actionResponseData.action.history.gh_sta_id
	const currentActionData = useAppSelector((state) => state.globalData.currentActionData)
	const userCurrentDay = useAppSelector((state) => state.initUser.userCurrentDay)
	const allCharacterList = useAppSelector(state => state.lists.characterList)
	const selectedCharacterList = useAppSelector(state => state.globalData.selectedCharacterList)
	const { directlyImpactedData, indirectlyImpactedData } = getAffectedCharList(actionResponseData.action.history, allCharacterList)

	const selectedTeamCharImg = getString(selectedTeamCharData['ch_image'])
	const selectedTeamCharName = getString(selectedTeamCharData['ch_name']).split(" ")[0]
	const currentActionImg = getString(currentActionData['act_image'])

	return (
		<>
			<div className='fake-div'>
				<Popup handleClose={props.handleExit}>
					<div className="phead">
						<div className="action-name">
							<img
								src={
									characterImages(currentActionImg)
								}
								alt="action-name"
								className="action-icon"
							/>
							<span className="action-title">{getString(currentActionData["act_name"])}</span>
							<span className="current-day">{getString('label_gen_day')}&nbsp;{userCurrentDay}</span>
						</div>{" "}
					</div>
					<div className="curved-header"></div>
					<div className="pbody arbody">
						<div className="action-characters">
							<div style={{display: "flex", flexDirection: "column"}}>
								<img
									src={
										characterImages(selectedTeamCharImg)
									}
									className="from-character"
								/>
								<div style={{font: "Roboto", fontSize: '1vw', textAlign: "center", paddingTop: '0.3vw', paddingRight: "0.8vw"}}>{selectedTeamCharName}</div>
							</div>
							<img
								src={rightArrow}
								alt="rightArrow"
								className="arrow-right"
							/>
							{selectedCharacterList.map((char: IClientCharacter) => {
								const img = getString(char['ch_image'])
								const charName = getString(char['ch_name']).split(" ")[0]
								return (
									<div key={char['stc_id']} style={{display: "flex", flexDirection: "column"}}>
										<img
											src={
												characterImages(img)
											}
											alt="affected-character"
											className="to-character"
										/>
										<div style={{font: "Roboto", fontSize: '1vw', textAlign: "center", paddingTop: '0.3vw', paddingRight: "0.8vw"}}>{charName}</div>	
									</div>
								);
							})}
						</div>
						<div className="action-res">
							<span className="res-title">
								{getString('label_action_impact')}
							</span>
							<div>
							{directlyImpactedData.map((char: any) => {
								const img = getString(char['charImage'])
								return (
									<div className="res-card" key={char['charName']}>
										<div style={{display: 'flex', flexDirection: 'column'}}>
											<img
												src={
													characterImages(img)
												}
												alt="affected-character"
												className="res-character"
											/>
											<div className="actor-first-name">
												{getString(char['charName']).split(" ")[0]}
											</div>
										</div>
										<div className="res-impact">
											<div className="impact-body">
												<div className="impact-each">
													<span className="impact-label">
														{getString('label_character_y')}&nbsp;:&nbsp;
													</span>
													<span className="ty">
														{" "}
														{char['updatedTrust']}
														<span className="percentage">
															%
														</span>
													</span>
													<TrustChangeIndicator
														impactValue={char['deltaTrust']}
														positiveColor="rgba(108, 219, 152, 0.5)"
														negativeColor="rgba(252, 132, 132, 0.5)"
														neutralColor="#DFEAFB"
													/>
												</div>
												<div className="impact-each">
													<span className="impact-label">
													{getString('label_character_c')}&nbsp;:&nbsp;
													</span>
													<span className="ty">
														{" "}
														{char['updatedTrustComp']}
														<span className="percentage">
															%
														</span>
													</span>
													<TrustChangeIndicator
														impactValue={char['deltaTrustComp']}
														positiveColor="rgba(108, 219, 152, 0.5)"
														negativeColor="rgba(252, 132, 132, 0.5)"
														neutralColor="#DFEAFB"
													/>
												</div>
											</div>
										</div>

										<div className="res-desc">
											<div className='triangle'></div>
											{getString(char['responseDesc'], "", { "actorA": getString(selectedTeamCharData && selectedTeamCharData['ch_name'])?.split(" ")[0], "actorB": getString(selectedCharacterList[0] && selectedCharacterList[0]['ch_name'])?.split(" ")[0], "actorC": getString(char['networkCharName']) })}
										</div>
									</div>
								);
							})}
							{indirectlyImpactedData.length > 0 ?
								<>
									<span className="indirect-res-title">
										{getString('label_also_impacted')}
									</span>
									<div className='indirectly-impacted'>
										{indirectlyImpactedData.map((char: any) => {
											const img = getString(char['charImage'])
											return (
												<div className="indirect-res-card" key={char['charName']}>
													<img
														src={
															characterImages(img)
														}
														alt="affected-character"
														className="res-character"
													/>
													<div className="res-impact">
														<div className="actor-first-name">
															{getString(char['charName']).split(" ")[0]}
														</div>
														<div className="impact-body">
															<div className="impact-each">
																<span className="impact-label">
																	{getString('label_character_y')}&nbsp;:&nbsp;
																</span>
																<span className="ty">
																	{" "}
																	{char['updatedTrust']}
																	<span className="percentage">
																		%
																	</span>
																</span>
																<TrustChangeIndicator
																	impactValue={char['deltaTrust']}
																	positiveColor="#84FCB4"
																	negativeColor="#FF7171"
																	neutralColor="#DFEAFB"
																/>
															</div>
															<div className="impact-each">
																<span className="impact-label">
																	{getString('label_character_c')}&nbsp;:&nbsp;
																</span>
																<span className="ty">
																	{" "}
																	{char['updatedTrustComp']}
																	<span className="percentage">
																		%
																	</span>
																</span>
																<TrustChangeIndicator
																	impactValue={char['deltaTrustComp']}
																	positiveColor="#84FCB4"
																	negativeColor="#FF7171"
																	neutralColor="#DFEAFB"
																/>
															</div>
														</div>
													</div>
												</div>)
										})}
									</div>
									<div className='indirect-tooltip'>
										<FlashToolTip toolTipText={getString('label_tip_draw_connection')} />
									</div>
								</> : null}
								<Button
									variant="contained"
									disableRipple={true}
									className="close-popup-btn"
									onClick={props.handleExit}
								>
									{getString('label_gen_ok')}
								</Button>
							</div>
							
						</div>
					</div>
				</Popup>
			</div>
		</>
	);
};

export default BaseActionResponse;
