import React from 'react'
import { getString } from 'utils'

interface TranscriptProps {
  isVideoCompleted: boolean
  handleClick: () => void
}

const Transcript = (props: TranscriptProps) : JSX.Element => {

  return (
    <>
      <div className='transcript-container'>
        <div className='transcript-body'>
          <div className='t-title'>{getString('label_video_transcript')}</div>
          <div className='t-desc' dangerouslySetInnerHTML={{ __html: getString('video_transcript') }}/>
        </div>
      </div>
      <div className='proceed-btn-container'>
        <button className={`proceed-btn ${props.isVideoCompleted ? '' : 'disable-btn'}`} onClick={props.handleClick}>
          {getString('label_gen_proceed')}
        </button>
      </div>
    </>
  )
}

export default Transcript
