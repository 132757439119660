import RepsMap from "components/commonComponents/repsMap/RepsMap";
import React from "react";
import { getString } from "utils";


const RepsFramework: React.FC = (props) => {

    return (
        <div className="cbody">
            <div className="tbody">
                <p className=" bbody" dangerouslySetInnerHTML={{ __html: getString("label_intro_rel_style_desc") }}></p>
            </div>
            <div className="tbody trepsbody">
                <RepsMap />
            </div>
        </div >
    )
}

export default RepsFramework;
