import React, { useState } from "react"
import "./default.scss"

import SimLogo from "../../commonComponents/simLogo/SimLogo"
import TrustMeter from "./trustMeter/TrustMeter"
import { Divider } from "@mui/material"
import TimerComponent from "../../commonComponents/timerComponent/Timer"
import CalendarComponent from "../../commonComponents/calendarComponent/CalendarComponent"
import MetricIcon from "./metricIcon/MetricIcon"
import IntroIcon from "../../commonComponents/introIcon/IntroIcon"
import LogOut from "components/commonComponents/logOut/LogOut"
import { useAppSelector } from "config/hooks"
import { getString } from "utils"

interface HeaderDefaultProps {
	isGameOver: boolean
}

const HeaderDefault: React.FC<HeaderDefaultProps> = (props: HeaderDefaultProps) => {

	const isTimerSet = useAppSelector(state => state.initUser.isTimerSet)
	const leaderboardAvail = useAppSelector(state => state.storylineData.leaderboardAvail)
	const isVAC = useAppSelector(state => state.storylineData.isVAC)
	const monthlyReportAvail = useAppSelector(state => state.storylineData.monthlyReportAvail)
	const timer = useAppSelector(state => state.globalData.timer)
	const showActionResponse = useAppSelector((state) => state.globalData.showActionResponse)
	const showProgressBarChange = useAppSelector((state) => state.globalData.showProgressBarChange)
	const showReport = useAppSelector((state) => state.globalData.showReport)
	const isDemoUser = useAppSelector(state => state.initUser.isDemoUser)
	const showExitDemoConfirmationPopup = useAppSelector(state => state.globalData.showExitDemoConfirmationPopup)
	const month = useAppSelector(state => state.globalData.month)

	// need a better way
	const zindex = showActionResponse || showProgressBarChange ? 6 : 1

	return (
		<div className="header-container">
			<div className="sim-logo-container">
				<SimLogo />
				<span className="bold white" style={{ marginLeft: "-2px" }}>
					TRUST
				</span>
				<span className="yellow">Sim</span>
			</div>
			<Divider orientation="vertical" flexItem className="divider" />
			{showActionResponse || showProgressBarChange ? <div className='overlay-meter-cutout'></div> : null}
			<div className="trust-meter" style={{ zIndex: zindex }}>
				<TrustMeter
					label_you={`${getString("label_base_your_firm")} (${getString("label_character_y")})`}
					label_competitor={`${getString("label_base_competitor")} (${getString("label_character_c")})`}
				/>
			</div>
			{isDemoUser && (props.isGameOver || showExitDemoConfirmationPopup) ? <div className='timer-cutout'></div> : null}
			{isTimerSet ?
				<div className="timer-container">
					<div className="timer" style={{
						backgroundImage: `${showActionResponse || showProgressBarChange && !props.isGameOver ? "#054B7D" : `${props.isGameOver || timer == 0 ? 'linear-gradient(to right, #E99090, #E99090)' : 'linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(to right, rgb(5, 75, 125), rgb(5, 75, 125))'}`}`,
						zIndex: 11
					}}>
						<TimerComponent isGameOver={props.isGameOver} />
					</div>
					<div className="calendar" style={{ zIndex: isDemoUser && (props.isGameOver || showExitDemoConfirmationPopup) ? 9 : 1 }}>
						<CalendarComponent />
					</div>
				</div>
				: <div className="calendar-container" style={{ zIndex: isDemoUser && props.isGameOver ? 9 : 1 }}>
					<CalendarComponent />
				</div>
			}
			<div className="metric-container">
				<MetricIcon label={getString("label_base_history").toUpperCase()} icon="history" />
				{!isVAC && leaderboardAvail ? <MetricIcon label={getString("label_base_leaderboard").toUpperCase()} icon="leaderboard" /> : null}
				{!isVAC && monthlyReportAvail ? <MetricIcon label={getString("label_base_report").toUpperCase()} icon={month ? "reports" : "report_lock" } iconClicked={showReport} isDisabled={month}/> : null}
			</div>
			<Divider orientation="vertical" flexItem className="divider" />
			<div className="intro-icons-container">
				<IntroIcon label={getString("label_base_concepts").toUpperCase()} icon="concepts" />
				<IntroIcon label={getString("label_base_objectives").toUpperCase()} icon="objectives" />
			</div>
			<Divider orientation="vertical" flexItem className="divider" />
			<div className="logout-container">
				<LogOut />
			</div>
		</div >
		
	);
};

export default HeaderDefault;
