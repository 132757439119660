import React, { useState } from "react";

import logOut from "assets/images/logout.svg";
import "./logOut.scss";
import Popup from "../popup/Popup";
import { getString } from "utils";
import { bindActionCreators } from "redux"
import * as actionCreators from "store/actionCreator"
import { useAppDispatch, useAppSelector } from "config/hooks";

const LogOutPopup: React.FC = () => {

    const logoutType = useAppSelector(state => state.initUser.logoutType)
    const dispatch = useAppDispatch();
    const { setShowlogoutPopup } = bindActionCreators(actionCreators, dispatch)

    const handleLogoutBtn = () => {
        window.location.assign(`${process.env.REACT_APP_BACKEND_URL}/user/logout`)
    }

    const handleClose = () => {
        setShowlogoutPopup(false)
    }

    return (
        <>
            <Popup handleClose={handleClose} zIndex={10} height={"45vmin"}>
                <div className="phead" style={{ pointerEvents: 'all' }}>
                    <div style={{ display: "inherit", alignItems: 'center' }}>
                        <img src={require(`assets/images/logout.svg`).default} style={{ width: "3vw", paddingRight: '0.5vw' }} />
                        <span>&nbsp;{getString("label_logout")}</span>
                    </div>
                </div>
                <div className="curved-header"></div>
                <div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "25vh", fontSize: "2vh", lineHeight: "2.4vh", justifyContent: "center", alignItems: "center", minHeight: "31vh", pointerEvents: 'all', borderRadius: '0', borderBottomLeftRadius: '0.7vw', borderBottomRightRadius: '0.7vw'  }}>
                    <p dangerouslySetInnerHTML={{ __html: logoutType == "RETURN" ? getString("label_ask_exit") : getString("label_logout_message") }} style={{ color: "#083880", fontSize: "2.6vh" }}></p>
                    <div className="lbuttons">
                        <button className="logout-btn" onClick={handleLogoutBtn}>{logoutType == "RETURN" ? getString("label_gen_return") : getString("label_logout_btn")}</button>
                        <button className="cancel" onClick={handleClose}>{getString("label_logout_cancel_btn")}</button>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default LogOutPopup;
