import Popup from 'components/commonComponents/popup/Popup'
import { useAppSelector } from 'config/hooks'
import React from 'react'
import { getString } from 'utils'
import DashboardDefault from '../components/dashboard/default/Default'

/**
 * This class will decide which dashboard component will be rendered
*/
const Dashboard: React.FC = () => {
	const showLoginPopup = useAppSelector(state => state.showLoginPopup)

	return (
		<>

			<DashboardDefault />

			{showLoginPopup ? <>
				<Popup zIndex={20}>
					<div className="phead">
						<p style={{ display: "inherit" }}>
							<img src={require(`assets/images/Meeting.svg`).default} style={{ width: "3vw" }} />
							<span>&nbsp;{getString("label_logout")}</span>
						</p>
					</div>
					<div className="curved-header"></div>
					<div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "31vh", fontSize: "2vh", lineHeight: "2.4vh", justifyContent: "center", alignItems: "center", borderRadius: '0', borderBottomLeftRadius: '0.7vw', borderBottomRightRadius: '0.7vw' }}>
						<p dangerouslySetInnerHTML={{ __html: getString("label_login_again") }} style={{ color: "#083880", fontSize: "2.6vh" }}></p>
						<div className="lbuttons">
							<button className="logout-btn" onClick={() => window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`)}>{getString("label_login_again_heading")}</button>
						</div>
					</div>
				</Popup>
			</> : null}
		</>
	);
}

export default Dashboard;
