import React from "react";

import ProgressBarWithLabel from "./progressBarWithLabel/ProgressBarWithLabel";
import "./trustMeter.scss";
import informationIcon from "assets/images/information.svg";
import { useAppSelector } from 'config/hooks';
import { getString } from "utils";

interface ProgressMeterProps {
	label_you: string;
	label_competitor: string;
	overallTrust?: number;
    overallTrustComp?: number; 
}

const ProgressMeter: React.FC<ProgressMeterProps> = (
	props: ProgressMeterProps
) => {

	let overallTrust = props.overallTrust ? props.overallTrust :  useAppSelector((state) => state.globalData.overallTrust)
	let overallTrustComp = props.overallTrustComp? props.overallTrustComp :  useAppSelector((state) => state.globalData.overallTrustComp)

	let deltaOverallTrust = useAppSelector((state) => state.globalData.deltaOverallTrust)
	let deltaOverallTrustComp = useAppSelector((state) => state.globalData.deltaOverallTrustComp)


	deltaOverallTrust = parseFloat(deltaOverallTrust.toFixed(2))
	deltaOverallTrustComp = parseFloat(deltaOverallTrustComp.toFixed(2))
	overallTrust = parseFloat(overallTrust.toFixed(0))
	overallTrustComp = parseFloat(overallTrustComp.toFixed(0))

	return (
		<div className="progress-container" style={{ zIndex: 6 }}>
			<div className="title-meter">
				{getString("label_base_trust_meter_label")}
				<img src={informationIcon} className='info-icon'/>
			</div>
			<div className="progress-bar-container">
				<ProgressBarWithLabel
					label_bar={props.label_you}
					progress_bar_value={overallTrust}
					reverse={false}
					deltaTrustValue={deltaOverallTrust}
				/>
				<ProgressBarWithLabel
					label_bar={props.label_competitor}
					progress_bar_value={overallTrustComp}
					reverse={true}
					deltaTrustValue={deltaOverallTrustComp}
				/>
			</div>
		</div>
	);
};

export default ProgressMeter;
