import Overlay from "components/commonComponents/overlay/Overlay";
import React from "react";
import neutralEventFooter from "assets/images/neutral_response_footer.svg";
import negativeEventFooter from "assets/images/negative_response_footer.svg";
import positiveEventFooter from "assets/images/positive_response_footer.svg";
import neutralEventDesign from "assets/images/event_illustration_neutral.svg";
import negativeEventDesign from "assets/images/event_illustration_negative.svg";
import positiveEventDesign from "assets/images/event_illustration_positive.svg";


import closeIcon from "assets/images/event_close.svg"
import Spinner from 'components/commonComponents/loaders/Spinner'
import styles from "./events.module.scss";
import './eventLoader.scss'
import { getString } from "utils";

interface EventBaseProps {
	children?: React.ReactNode;
	eventTitle: string
	eventDesc: string
	impact: number;
	showLoader: boolean;
	disableExit?: boolean;
	handleExit?: () => void;
}

const EventBase: React.FC<EventBaseProps> = (props: EventBaseProps) => {

	let borderColor = '#0D54BD', eventDesign = neutralEventDesign, eventFooter = neutralEventFooter;
	const { impact } = props
	if (props.showLoader) {
	}
	else if (impact > 0) {
		borderColor = '#84FCB4'
		eventDesign = positiveEventDesign
		eventFooter = positiveEventFooter
	}
	else if (impact < 0) {
		borderColor = '#FC8484'
		eventDesign = negativeEventDesign
		eventFooter = negativeEventFooter
	}

	return (
		<>
			<>
				<Overlay zIndex={5} />
				<div
					className={styles.event_container}
					style={{
						borderTop: `4px solid ${borderColor}`,
						borderRadius: "0.5vh 0.5vh 0 0",
					}}
				>
					{props.showLoader ?
						<div className={styles.event_loader}>
							{/*fake-div to prevent css change in other files with same classes. */}
							<div className='fake-div'><Spinner hideOverlay={true} /></div>

							<div className={styles.loading_text}>
								{getString('label_event_loading_text')}</div>
						</div> :
						<>
							<div className={styles.body}>
								<div className={styles.title}>{props.eventTitle}</div>
								<div className={styles.desc}>
									{props.eventDesc}
								</div>
								{props.children}
							</div>

							<img
								src={eventDesign}
								alt="design"
								className={styles.response_design}
								style={{ visibility: props.showLoader ? 'hidden' : 'visible' }}
							/>
							{!props.disableExit ? <img
								src={closeIcon}
								alt="close"
								className={styles.close}
								onClick={props.handleExit}
							/> : null}
							<img src={eventFooter} alt="footer" className={styles.footer} />
						</>}
				</div>
			</>
		</>
	);
};

export default EventBase;
