import React from "react";

import IntroLogOut from "components/commonComponents/logOut/IntroLogOut";
import introLogo from "assets/images/intro/introLogo.svg"

import "./intro.scss"

const IntroHeader: React.FC = () => {

    return (
        <div className="intro-header">
            <img src={introLogo} />
            <IntroLogOut />
        </div>
    )



};

export default IntroHeader;
