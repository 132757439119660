import React from "react";

import "./simLogo.scss";
import simLogo from "assets/images/logo.svg";

const SimLogo: React.FC = () => {
	return <img className="sim-logo" src={simLogo} alt="Sim Logo" />;
};

export default SimLogo;
