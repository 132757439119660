import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'config/hooks'
import { actionCreators } from "store";
import { bindActionCreators } from "redux"
import teamIllustration from 'assets/images/illustration_end_sim1.svg'
import emailReportIcon from 'assets/images/report-email.svg'
import { Button, InputBase } from '@mui/material';
import { addClassToElement, getString, removeClassFromElement } from 'utils';
import './endScreen.scss'
import TargetAnalysisScreen from './TargetAnalysisScreen'

import timerOver from 'assets/images/timerEnded.svg'
import negativeFooter from 'assets/images/negative_response_footer.svg'
import Overlay from 'components/commonComponents/overlay/Overlay'
import { initializeFeedback, showFeedbackFormWithoutPopup } from 'utils/feedback';
import greenTickIcon from 'assets/images/greenTick.svg'
import failedIcon from 'assets/images/warning.svg'

interface SimEndScreenProps {
  gameStatus: number
}

const SimEndScreen = (props: SimEndScreenProps) => {

  // if gameStatus == 2, means Game is already completed, hence show end screen directly.
  const [showTargetAnalysisScreen, setShowTargetAnalysisScreen] = useState(false)
  const [showTimeUpPopup, setShowTimeUpPopup] = useState(false)
  const [showComponent, setShowComponent] = useState(false)
  const [isFeedbackCompleted, setIsFeedbackCompleted] = useState(false)
  const isNewReportEnabled = useAppSelector(state => state.storylineData.isNewReportEnabled)
  const monthlyReportAvail = useAppSelector(state => state.storylineData.monthlyReportAvail)
  const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
  const simDays = useAppSelector(state => state.storylineData.simDays)
  const endReportAvail = useAppSelector(state => state.storylineData.endReportAvail)
  const isWebReportEnabled = useAppSelector(state => state.storylineData.isWebReportEnabled)
  const isFeedbackEnabled = useAppSelector(state => state.storylineData.isFeedbackEnabled)
  const user_lti_id = useAppSelector(state => state.initUser.usr_lti_id)
  const uliId = useAppSelector(state => state.initUser.uliId)
  const grpId = useAppSelector(state => state.initUser.groupId)
  
  const dispatch = useAppDispatch()
  const { downloadUserReport, emailUserReport, setShowReport } = bindActionCreators(actionCreators, dispatch)
  // const  audio = new Audio("/popup.mp3")

  useEffect(() => {

    if (props.gameStatus != 2) {
      if (userCurrentDay < simDays) {
        setShowTimeUpPopup(true)
        // audio.play()
      }
      else {
        setShowTargetAnalysisScreen(true)
      }
    }
    setShowComponent(true)
  }, [])

  let deploymentType = 'commonDB'
  if (user_lti_id) {
    deploymentType = 'lti'
  }

  const setFeedbackCompleted = () => {
    setIsFeedbackCompleted(true)
  }
  const isFeedbackInitialized = false

  const giveFeedback = () => {
    if (!isFeedbackInitialized) {
      initializeFeedback(deploymentType, uliId, grpId, () => { console.log('done') })
    }
    showFeedbackFormWithoutPopup(setFeedbackCompleted);
  }

  const downloadReport = async () => {
    const downloadBtnElement = document.getElementById('download-report-btn');
    if (downloadBtnElement) {
      downloadBtnElement.innerHTML = getString('label_end_downloading_btn')
    }

    const res: any = await downloadUserReport(isNewReportEnabled)

    if (downloadBtnElement) {
      downloadBtnElement.innerHTML = getString('label_end_download_btn')
    }

    const linkElement = document.createElement('a')
    if (res) {
      linkElement.href = res.filePath
      linkElement.download = res.fileName
      document.body.appendChild(linkElement)
      linkElement.click()
      document.body.removeChild(linkElement)
    }
  }

  const showWebReport = async () => {
    const viewReportBtnElement = document.getElementById('download-report-btn');
    if (viewReportBtnElement) {
      viewReportBtnElement.innerHTML = getString('label_report_opening')
    }

    //To do: different API for old user web report.
    //using new report for both cases in web report. 
    window.open(`${process.env.REACT_APP_BACKEND_URL}/user/newUserReportHTML`,'_blank')

    if (viewReportBtnElement) {
      viewReportBtnElement.innerHTML = getString('label_end_view_report')
    }

  }

  const validateEmailAddress = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    if (event.target.value.match(emailRegex)) {
      removeClassFromElement('send-email-icon', 'pointer-events-none', 'opacity-4')
    }
    else {
      addClassToElement('send-email-icon', 'pointer-events-none', 'opacity-4')
      const invalidEmailElement: any = document.getElementById('invalid-email')
      invalidEmailElement.style.display = 'block';
      setTimeout(function() {
        invalidEmailElement.style.display = 'none';
      }, 1000)
    }
  }

  const onClickEmailReport = async () => {

    const inputEmailElement: any = document.getElementById('input-email')
    let emailAddress = ''
    if (inputEmailElement) {
      emailAddress = inputEmailElement.value
    }

    inputEmailElement.value = getString('label_sending_report')
    const res: any = await emailUserReport(emailAddress)
    console.log(res)
    if(res.success) {
      const successEmailElement: any = document.getElementById('email-report-success')
      successEmailElement.style.display = 'flex'
      setTimeout(function() {
        successEmailElement.style.display = 'none'
      }, 3000)
    }
    else {
      const failedEmailElement: any = document.getElementById('email-report-failed')
      failedEmailElement.style.display = 'flex'
      setTimeout(function() {
        failedEmailElement.style.display = 'none'
      }, 3000)
    }
    inputEmailElement.value = ""
  }

  const handleExitTargetAnalysis = () => {
    if (monthlyReportAvail) {
      setShowReport(2)
    }
    setShowTargetAnalysisScreen(false)
  }


  const handleExitTimeUpPopup = () => {
    setShowTimeUpPopup(false)
    setShowTargetAnalysisScreen(true)
  }


  return (
    <>
      {showComponent ? showTimeUpPopup ?
        <>
          <Overlay zIndex={9} />
          <div className='timer-over-container'>
          <div className='time-over-popup' style={{ top: '10vh' }}>
            <img className='time-over-illustration' src={timerOver} alt='time-over' />
            <div className='time-over-desc' style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ width: '12vw', paddingBottom: '1.5vh' }} dangerouslySetInnerHTML={{__html: getString('label_time_over')}}></span>
              <button className='close-popup-btn' onClick={handleExitTimeUpPopup}>{getString('label_gen_ok')}</button>
            </div>
            <img className='t-footer' src={negativeFooter} alt='footer' />
          </div>
          </div>
        </> :
        showTargetAnalysisScreen ? <TargetAnalysisScreen handleExit={handleExitTargetAnalysis} /> :
        <>
          <div className='sim-end'>
            <div className='end-body'>
              <div className='end-message'>{getString('label_end_sim_thankyou')}</div>
              <img className='end-celebrate' src={teamIllustration} alt='celebrate' />
                <div className='end-feedback-title'>{getString('label_feedback_desc')}</div>
              <Button
                variant="contained"
                disableRipple={true}
                className="feedback-btn"
                onClick={() => giveFeedback()}
              >
                {getString('label_end_give_feedback')}
              </Button>
                {endReportAvail ?
                  <>
                    <div className='download-report-title'>{getString('label_end_analyse_performance')}</div>
                    <Button
                      variant="contained"
                      // disabled={isFeedbackEnabled ? !isFeedbackCompleted : false}
                      disableRipple={true}
                      id="download-report-btn"
                      className="download-report-btn"
                      onClick={() => isWebReportEnabled ? showWebReport() : downloadReport()}
                    >
                      {isWebReportEnabled ? getString('label_end_view_report') : getString('label_end_download_btn')}
                    </Button>
                    <div className='email-report'>
                      {/* Removed disable from input for now if feedback not completed
                        To enable add this code:  disabled={isFeedbackEnabled ? !isFeedbackCompleted : false}*/}
                      <InputBase placeholder={getString('label_placeholder_email_report')} id='input-email' className='input-email' type='text' name='emailAddress' onChange={validateEmailAddress} />
                      <div id='send-email-icon' className='send-email-icon pointer-events-none opacity-4' onClick={() => onClickEmailReport()}><img src={emailReportIcon} alt='email-report-icon' className='email-report-icon' /></div>
                    </div>
                    <div id='email-report-success' style={{ display: 'none'}}><img src={greenTickIcon} style={{width: '1.1vw', marginRight: '0.2vw'}}/> {getString('label_email_report_success')}</div>
                    <div id='email-report-failed' style={{ display: 'none'}}><img src={failedIcon} style={{width: '1.1vw', marginRight: '0.2vw'}}/> {getString('label_email_report_failed')}</div>
                    <div id='invalid-email' style={{ display: 'none'}}>{getString('label_email_invalid_id')}</div>
                  </>
                  : null}
            </div>
          </div>
          </> : null
      }
    </>
  )
}

export default SimEndScreen
