import React from "react";
import objectivesIllustration from "assets/images/intro/illustration_objectives.svg"
import qoute from "assets/images/intro/qoute.svg"
import { getString } from "utils";

interface IntroProps {
    onTabChange: (newValue: number) => void
    isPopup?: boolean
}

const Objective: React.FC<IntroProps> = (props: IntroProps) => {
    // const btnaudio = new Audio("/button_cta.mp3")
    
    const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
        // btnaudio.play()
        props.onTabChange(3);
    }
    const handleBack = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onTabChange(1);
    }
    return (
        <div>
            {!props.isPopup ? <div className="theader">
                <img src={objectivesIllustration} className="timage" />
                <img src={qoute} className="tqoute" />
                <div className="ceo-qoute">
                    <p dangerouslySetInnerHTML={{ __html: getString("label_intro_steve_obj") }} />
                </div>
            </div> : null}
            <div className="tbody">
                <p className="bhead bbody" style={{ marginRight: "5vw" }}
                    dangerouslySetInnerHTML={{ __html: getString("label_intro_obj_list") }} />
                {!props.isPopup ? <div className="tbuttons">
                    <button className="back" onClick={handleBack}>{getString("label_intro_back")}</button>
                    <button className="next" onClick={handleNext}>{getString("label_intro_next")}</button>
                </div> : null}
            </div>
        </div>
    )
}

export default Objective;
