import React from "react";

import "./overlay.scss";

interface overlayProps {
	zIndex?: number
	onClickHandler?: () => void
}


const Overlay = (props: overlayProps) => {

	const clickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (props.onClickHandler) {
			props.onClickHandler()
		}
	}

	return <div className="overlay" style={{ zIndex: props.zIndex }} onClick={clickHandler}></div>;
};

export default Overlay;
