import IRoute from '../interfaces/routes'
import Dashboard from '../pages/dashboard';
import Intro from '../pages/intro';
import Demo from '../pages/demo';
import LandingPage from 'components/intro/landingPage';
import VideoPage from '../pages/video';

const routes: IRoute[] = [
    {
        path: '/',
        name: 'Landing Page',
        component: LandingPage,
        exact: true
    }, {
        path: '/video',
        name: 'Video Page',
        component: VideoPage,
        exact: true
    }, {
        path: '/intro',
        name: 'Intro Page',
        component: Intro,
        exact: true
    }, {
        path: '/dashboard',
        name: 'Dashboard Page',
        component: Dashboard,
        exact: true
    }, {
        path: '/demo',
        name: 'Demo Page',
        component: Demo,
        exact: true
    }
]

export default routes;