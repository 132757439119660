import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from "react-redux";

import IntroHeader from "./IntroHeader";
import IntroBody from "./IntroBody/IntroBody";
import background from "assets/images/intro/BG_intro.svg"
import introFooter from "assets/images/intro/introFooter.svg"


import "./intro.scss";
import IntroFooter from './IntroFooter';

/**
 * This is the default Intro Component
 */
interface IntroComponentProps {
    showIntroBody: boolean
    isIntroCompleted?: boolean
    children?: React.ReactNode
}


const IntroComponentDefault: React.FC<IntroComponentProps> = (props: IntroComponentProps) => {
    const [storylineData, setData] = useState({})

    return (
        <div className="intro-container"
            style={{ "background": `url(${background}) no-repeat center center fixed`, backgroundSize: "cover" }}>
            < IntroHeader />
            {props.children}
            {props.showIntroBody ? <IntroBody isIntroCompleted={props.isIntroCompleted} /> : null}
            <IntroFooter />
        </div >
    )
}

export default IntroComponentDefault;
