import React from "react";
import Overlay from "../overlay/Overlay";

import "./popup.scss";
import close from "assets/images/close.svg"

interface IPopup {
	children: React.ReactNode
	handleClose?: () => void
	disableOverlayClick?: boolean
	zIndex?: number
	height?: string
}

const Popup: React.FC<IPopup> = (props: IPopup) => {
	return (
		<>
			<Overlay zIndex={props.zIndex ? props.zIndex : 3} onClickHandler={props.disableOverlayClick ? () => { return } : props.handleClose} />
			<div className="pcontainer" style={{ zIndex: props.zIndex ? props.zIndex : 6, height: props.height }}>
				{props.handleClose ?
					<img className='popup-close' src={close} onClick={props.handleClose} style={{ cursor: "pointer", float: "right", width: '2.5vw', marginRight: '0.5vw', marginTop: '1vh', borderRadius: '50%' }} />
					: null}
				{props.children}
			</div>
		</>
	);
};
/**
 * Usage
 * <Popup>
		<div className="phead">
			<p>head</p>
		</div>
		<div className="pbody">
			body
		</div>
	</Popup>
 */
export default Popup;
