import Popup from 'components/commonComponents/popup/Popup';
import { useAppSelector } from 'config/hooks';
import React from 'react';
import { getString } from 'utils';
import IntroComponentDefault from '../components/intro/introComponentDefault'

/**
 * This class will decide which casestudy component will be rendered
 */
const Intro: React.FC = () => {
    const showLoginPopup = useAppSelector(state => state.showLoginPopup)
    const isDemoEnabled = useAppSelector(state => state.initUser.isDemoEnabled)
    const isDemoCompleted = useAppSelector(state => state.initUser.isDemoCompleted)
    const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
    const isDemoUser = useAppSelector(state => state.initUser.isDemoUser)

    const renderIntroComponent = () => {
        // userCurrentday == 0
        // isdemoenaled && is demo completed
        // <IntroComponentDefault showIntroBody={true} seen={true} />
        // else <IntroComponentDefault showIntroBody={true} seen={false}/>
        // else 
        // <IntroComponentDefault showIntroBody={true} seen={true} />

        if (userCurrentDay == 0) {
            if (isDemoEnabled == 1 && isDemoCompleted == 2) {
                return <IntroComponentDefault showIntroBody={true} isIntroCompleted={true} />;
            }
            else {
                return <IntroComponentDefault showIntroBody={true} isIntroCompleted={false} />;
            }
        }
        else {
            return <IntroComponentDefault showIntroBody={true} isIntroCompleted={true} />;
        }

    }

    return (
        <>
            {renderIntroComponent()}
            {showLoginPopup ? <>
                <Popup>
                    <div className="phead">
                        <div style={{ display: "inherit", alignItems: 'center' }}>
                            <img src={require(`assets/images/logout.svg`).default} style={{ width: "3vw" }} />
                            <span>&nbsp;{getString("label_logout")}</span>
                        </div>
                    </div>
                    <div className="curved-header"></div>
                    <div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "31vh", fontSize: "2vh", lineHeight: "2.4vh", justifyContent: "center", alignItems: "center", borderRadius: '0', borderBottomLeftRadius: '0.7vw', borderBottomRightRadius: '0.7vw' }}>
                        <p dangerouslySetInnerHTML={{ __html: getString("label_login_again") }} style={{ color: "#083880", fontSize: "2.6vh" }}></p>
                        <div className="lbuttons">
                            <button className="logout-btn" onClick={() => window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`)}>{getString("label_login_again_heading")}</button>
                        </div>
                    </div>
                </Popup>
            </> : null}
        </>
    )
}

export default Intro;
