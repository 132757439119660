import { PathConnector } from "components/commonComponents/connectors/connectors";
import { useAppSelector } from "config/hooks";
import React, { useEffect } from "react";
import { addClassToElement, getString, removeClassFromElement } from "utils";
import "./steps.scss";

interface IStep {
	clickHandler: (step: number) => void;
}

const Step4 = (props: IStep) => {
	const { height, width } = useAppSelector((state) => state.dimensions);

	useEffect(() => {
		addClassToElement(
			"group-actions",
			"z-index-3",
			"group-action-gradient"
		);
		addClassToElement("locked-actions", "z-index-3");
	}, []);

	const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
		props.clickHandler(2);
		removeClassFromElement(
			"group-actions",
			"z-index-3",
			"group-action-gradient"
		);
		removeClassFromElement("locked-actions", "z-index-3");
	};

	const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
		removeClassFromElement(
			"group-actions",
			"z-index-3",
			"group-action-gradient"
		)
		props.clickHandler(4);
		removeClassFromElement("locked-actions", "z-index-3");
	};

	return (
		<>
			<PathConnector
				pathD={`M ${width / 7} ${height / 14} V ${height / 110} H ${
					width / 20
				} L ${width / 30} ${height / 110}`}
				class="s4-connector1 animate fadeIn"
				cx={width / 7}
				cy={height / 14}
				r={height / 300}
			/>
			<div className="s4-group-action animate fadeIn">
				<div className="s4-group-action-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_group_actions') }}></div>
					<div className="description" dangerouslySetInnerHTML={{ __html: getString('label_wt_group_actions_1') }}>
					</div>
				</div>
				<div className="s4-navigation-buttons animate fadeIn">
					<button className="s4-prev" onClick={handlePrevious}>
						{getString('label_gen_previous')}
					</button>
					<button className="s4-next" onClick={handleNext}>
						{getString('label_gen_next')}
					</button>
				</div>
			</div>

		</>
	);
};

export default Step4;
