import React from "react";

import logo from "assets/images/knolskape-logo.svg";
import "./knolskapeLogo.scss";

const KnolskapeLogo = () => {
	return (
		<div className="knolskape-logo">
			<img src={logo} alt="knolskape-logo" style={{ width: "10vw" }} />
		</div>
	);
};

export default KnolskapeLogo;
