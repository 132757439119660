
import actionConsts from "../constants"
import { convertToMap } from "utils"


const isDataFetched = (state: any, data: any) => {
    const { type } = data
    switch (type) {
        case actionConsts.ISDATAFETCHED: {
            return state + 1
        }
        case actionConsts.RESET_DATA_FETCHED: {
            return 0
        }
        default:
            return state ? state : 0
    }
}

const showLoginPopup = (state: any, data: any) => {
    const { type } = data
    switch (type) {
        case actionConsts.SHOWPOPUP: {
            return true
        }
        default:
            return state ? state : false
    }
}

const setDimensions = (state: any, data: any) => {
    const { height, width, type } = data
    switch (type) {
        case actionConsts.WINDOWRESIZE:
            return { height, width }
        default:
            return state ? state : { height: document.documentElement.clientHeight, width: document.documentElement.clientWidth }
    }
}

const setMTYFlags = (state: any, data: any) => {
    const { payload, type } = data
    switch (type) {
        case actionConsts.SHOWINTROPOWERMAP:
            return { ...state, showIntroPowermap: payload }
        case actionConsts.SHOWPOWERMAPLOADER:
            return { ...state, showIntroPowermapLoader: payload }
        case actionConsts.SHOWINTROBUTTONS:
            return { ...state, showButtons: payload }
        case actionConsts.ACTORVISITED:
            return { ...state, visitedAll: payload }
        case actionConsts.POWERMAPTIP:
            return { ...state, showPowermapTip: payload }
        case actionConsts.MOVEDCHARACTERS:
            return { ...state, movedCharacters: payload }
        default:
            return state ? state : { showIntroPowermap: true, showButtons: true, showIntroPowermapLoader: false, visitedAll: 1, showPowermapTip: true, movedCharacters: false }
    }
}

const setDashFlags = (state: any, data: any) => {
    const { payload, type } = data
    switch (type) {
        case actionConsts.SHOWDASHPOWERMAP:
            return { ...state, showPowermap: payload }
        case actionConsts.SETPOWERMAPLOCATIONS:
            return { ...state, updatedLocations: payload ? payload : [] }
        default:
            return state ? state : { showPowermap: false, updatedLocations: [] }
    }
}

const actionResponseLoader = (state: any, data: any) => {
    const { type } = data;
    switch (type) {
        case actionConsts.SHOWACTIONRESPONSELOADER:
            return true;
        case actionConsts.HIDEACTIONRESPONSELOADER:
            return false;
        default:
            return state ? state : false;
    }
};

const setLists = (state: any, data: any) => {
    const { type, payload } = data
    switch (type) {
        case actionConsts.LISTS: {
            const data = [...payload.clientCharacters, ...payload.teamCharacters]

            return {
                ...state,
                characterList: convertToMap(data, "stc_id"),
                actionList: convertToMap(payload.actions, "sta_id")
            }
        }
        case actionConsts.UPDATE_CHARACTER_LISTS: {
            const charData = [...payload]

            return {
                ...state,
                characterList: {...state.characterList, ...convertToMap(charData, "stc_id") }
            }
        }
        default:
            return state ? state : {
                characterList: {}, actionList: {}
            }
    }
}

const gameEnd = (state: any, data: any) => {
    const { type, payload } = data
}

export {
    isDataFetched,
    setDimensions,
    setMTYFlags,
    setDashFlags,
    actionResponseLoader,
    setLists,
    showLoginPopup
};