import React, { useEffect, useState } from "react";

import "./userHistory.scss";
import Popup from "components/commonComponents/popup/Popup"
import MenuListComposition from "components/commonComponents/dropdown"
import { SelectChangeEvent } from "@mui/material/Select/Select"
import { getNetworkCharName, getString } from "utils";
import { useAppSelector } from "config/hooks";
import rightArrow from "assets/images/right_arrow.svg";
import eventIcon from "assets/images/history_event_icon.svg";
import backgroundInfo from "assets/images/Background_Info.png";
import { TrustChangeIndicator } from "components/commonComponents/trustChangeIndicator/TrustChangeIndicator";
import { characterImages } from "utils/characterImages";

interface IHistory {
    close: () => void
}

const UserHistory: React.FC<IHistory> = (props: IHistory) => {

    const [showComponent, setShowComponent] = useState(true)
    const [day, setDay] = useState("-1")
    const [clientId, setClientId] = useState("-1")
    const [teamId, setTeamId] = useState("-1")
    const history = useAppSelector(state => state.history)
    const [data, setData] = useState(history.data)
    const { characterList, actionList } = useAppSelector(state => state.lists)
    // const  audio = new Audio("/popup.mp3")

    // useEffect(()=>{
    //     audio.play()
    // },[])
    
    useEffect(() => {
        handleFrom()
    }, [day, clientId, teamId])

    const handleClose = () => {
        setShowComponent(false)
        props.close()
    }

    const handleReset = () => {
        setData(history.data)
        setClientId("-1")
        setTeamId("-1")
        setDay("-1")
    }

    const handleFrom = () => {
        // one single handler n apply all 3 values
        const newData = history.data.filter((ele: any) => {
            let count = 0
            // check day
            if (day != "-1") {
                if (ele["gh_day"] == day) count++
            } else count++

            // check teamId
            if (teamId != "-1") {
                if (ele["gh_ste_id"] == null) { // not an event
                    if (ele["gh_src_stc_id"] == null) {
                        // check You
                        teamId == "#" ? count++ : null
                    } else ele["gh_src_stc_id"] == teamId ? count++ : null

                }
            } else count++

            // check clientId
            if (clientId != "-1") {
                ele["user_game_history_details"].map((item: any) => {
                    if (item["ghd_stc_id"] == clientId) {
                        count++
                    }
                })
            } else count++
            if (count == 3) return true
            else return false
        })
        setData(newData)
    }

    const handleDay = (event: SelectChangeEvent) => {
        const id = event.target.value
        setDay(id as string)
    }

    const handleClients = (event: SelectChangeEvent) => {
        const id = event.target.value
        setClientId(id as string)
    }

    const handleTeam = (event: SelectChangeEvent) => {
        const id = event.target.value
        setTeamId(id as string)
    }

    const getHeading = (item: any) => {
        return item["gh_sta_id"] ? getString(actionList[Number(item["gh_sta_id"])]["act_name"]) : getString(item["gh_interaction_heading"])
    }

    return (
        <>
            {showComponent ?
                <Popup handleClose={handleClose}>
                    <div className="phead">
                        <div style={{ display: "contents" }}>
                            <div className="metric-popup-icon">
                                <img src={require(`assets/images/history.svg`).default} style={{ width: "2vw" }} />
                            </div>
                            <span>&nbsp;{getString("label_base_history")}</span>
                        </div>
                    </div>
                    <div className="curved-header"></div>
                    <div className="pbody hbody" style={{ width: "75vw", backgroundImage: "none", minHeight: "46vh" }}>
                        <div className="hbhead">
                            <div className="filter">
                                <span className="">{getString("label_gamehistory_filter_by")}: </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span className="label">{getString("label_gamehistory_day").toUpperCase()}</span>
                                <MenuListComposition showAll={true} values={history.data} handleChange={handleDay} currentValue={day} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <span className="label">{getString("label_gamehistory_comm").toUpperCase()}</span>
                                <MenuListComposition showAll={true} clientCharactersOnly={true} handleChange={handleClients} currentValue={clientId} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <span className="label">{getString("label_gamehistory_team").toUpperCase()}</span>
                                <MenuListComposition showAll={true} teamCharactersOnly={true} handleChange={handleTeam} currentValue={teamId} />
                            </div>
                            <div className="reset" onClick={handleReset}>
                                <span className="">{getString("label_gamehistory_reset").toUpperCase()}</span>
                            </div>
                        </div>
                        <div className="hbbody">
                            {data.map((item: any, index: number) => {
                                let img = '', act_img = '', name = ''
                                const img_you = getString("img_you")
                                if (item["gh_src_stc_id"]) {
                                    const ch = characterList[Number(item["gh_src_stc_id"])]
                                    img = getString(ch["ch_image"])
                                    name = getString(ch["ch_name"]).split(" ")[0]
                                }
                                if (!item["gh_src_stc_id"] && item["gh_ste_id"] == null) {
                                    const actCh = actionList[Number(item["gh_sta_id"])]
                                    act_img = getString(actCh["act_image"])
                                }
                                return (
                                    <div key={item['gh_id']}>
                                        <div className="filter" style={{ height: "auto", width: "100%", display: "flex" }}>
                                            <span>{getString("label_gen_day").toUpperCase()} {item["gh_day"]} | <strong>{getHeading(item)}</strong></span>
                                            <span style={{ marginLeft: "auto" }}><strong>{getString("label_character_y")}</strong></span>
                                            <span style={{ marginLeft: "10%", marginRight: "10%" }}><strong>{getString("label_character_c")}</strong></span>
                                        </div>
                                        <div className="tableRow">
                                            {!item["gh_src_stc_id"] ?
                                                // chheck for event then different image  
                                                item["gh_ste_id"] != null ? <img src={eventIcon} /> :
                                                    <img src={characterImages(img_you)} />
                                                : <div>
                                                    <img src={characterImages(img)} />
                                                    <div style={{ textAlign: "center", fontSize: "1vw"}}>{name}</div>
                                                </div>}
                                            <div className="arrow">
                                            {!item["gh_src_stc_id"] && item["gh_ste_id"] == null ? <>
                                                {item["gh_interaction_desc"] == "BG INFO" ?
                                                    <img
                                                        src={characterImages(act_img)}
                                                        style={{ marginLeft: "2vw", width: "2vw", marginBottom: "-1vh" }}

                                                    /> : null }
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    style={{ marginBottom: "1vh", marginRight: "1vw", marginLeft: "1vw" }}

                                                /> </> : <img
                                                src={rightArrow}
                                                alt="rightArrow"
                                                style={{ marginBottom: "2vh", marginRight: "1vw", marginLeft: "1vw" }}

                                            />}
                                            </div>
                                            <div className="impacted">
                                                {item.user_game_history_details.map((ghd: any, index: number) => {
                                                    const imgUrl = getString(characterList[Number(ghd["ghd_stc_id"])]["ch_image"])
                                                    let name_b = getString(characterList[Number(ghd["ghd_stc_id"])]["ch_name"])
                                                    name_b = name_b ? name_b.split(" ")[0] : ''

                                                    //get label name if any network revealed for the character. 
                                                    const networkCharName = getNetworkCharName(ghd, characterList)

                                                    return (
                                                        <div key={ghd['ghd_id']} style={{ display: "-webkit-inline-box", paddingBottom: "1.6vh" }}>
                                                             <div>
                                                                 < img src={characterImages(imgUrl)} />
                                                                <div style={{ textAlign: "center", fontSize: '1vw'}}>{name_b}</div>
                                                             </div>
                                                            {ghd["ghd_detail"] != "bg_info" ?
                                                                <>
                                                                    <div className="res-arrow"></div>
                                                                    <div className="response">
                                                                        {ghd['ghd_influence'] == 1 ? "Indirectly impacted" :
                                                                            <>
                                                                                <div>{getString(ghd["ghd_detail"], "", { "actorA": name, "actorB": name_b, "actorC": getString(networkCharName) })}</div>
                                                                                {!ghd["ghd_detail"] ? <div>{getString(item["gh_interaction_desc"], "", { "actorA": name, "actorB": name_b, "actorC": getString(networkCharName) })}</div> : null} 
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </> : null}
                                                            <div style={{ marginLeft: "auto" }}>
                                                                <TrustChangeIndicator
                                                                    impactValue={!ghd["ghd_delta"] ? 0 : ghd["ghd_delta"]}
                                                                    style={{ padding: "1vh" }} />
                                                            </div>
                                                            <div style={{ marginLeft: "3vw" }}>
                                                                <TrustChangeIndicator
                                                                    impactValue={!ghd["ghd_comp_delta"] ? 0 : ghd["ghd_comp_delta"]}
                                                                    style={{ padding: "1vh" }} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </Popup>
                : null}
        </>
    )
};

export default UserHistory;
