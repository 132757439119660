import React from 'react'
import { getString } from 'utils'
import './steps.scss';

const Step7 = () => {
  return (
    <>
      <div className='s7-group-action animate fadeIn'>
        <div className='s7-content'>
          <div className="title">
            {getString("label_group_action_locked")}
          </div>
        </div>
      </div>
    </>
  )
}

export default Step7
