import React from "react"
import { useAppDispatch, useAppSelector } from "config/hooks"
import { bindActionCreators } from "redux"
import * as actionCreators from "store/actionCreator"

import logOut from "assets/images/logout.svg"
import "./logOut.scss"

const LogOut: React.FC = () => {

	const userEmail = useAppSelector(state => state.initUser.userEmail)
	const dispatch = useAppDispatch();
	const { setShowlogoutPopup } = bindActionCreators(actionCreators, dispatch)

	const handleLogout = () => {
		setShowlogoutPopup(true)
	}


	return (
		<>
			<div className="logout">
				<span className="logout-label">{userEmail}</span>
				<img
					src={logOut}
					alt="logout"
					style={{ width: "2vw" }}
					onClick={handleLogout}
				/>
			</div>
		</>
	);
};

export default LogOut;
