import React, { useEffect, useState } from "react"
import { getString } from "utils"
import { Powermap } from "components/commonComponents/powermap";
import RepsMap from "components/commonComponents/repsMap/RepsMap";
import { useAppSelector } from "config/hooks";


const PowermapReport: React.FC = () => {

    const [showInfo, setShowInfo] = useState(false)

    let reportAnalyse = useAppSelector(state => JSON.parse(state.reportPm.analyse))
    reportAnalyse = JSON.parse(reportAnalyse)

    const renderInfo = () => {
        setShowInfo(true)
    }

    return (
        <div className="pm">
            {/* <p style={{ marginBottom: "1vh" }}>{getString('label_report_disclaimer')}</p> */}
            <div className="container">
                <div className="powermap">
                    {!showInfo ? <Powermap isIntro={false} renderInfo={renderInfo} isReport={true} isGameOver={true}/> :
                        <RepsMap showIcon={true} handleClick={() => setShowInfo(false)} />}
                </div>
                <div className="pm-analysis">
                    <div className="style">
                        <p>{reportAnalyse.style ? reportAnalyse.style : 0.0}%</p>
                        <span>{getString('label_report_accuracy_style')}</span>
                    </div>
                    <p className="connections">{getString('label_report_connections')}:</p>
                    <div className="conn-grid">
                        <div className="">
                            <p style={{ fontSize: "2vw", color: "#0D54BD" }}>{reportAnalyse.storylineTotalEdges ? reportAnalyse.storylineTotalEdges: 0}</p>
                            <p>{getString('label_report_act_connections')}</p>
                        </div>
                        <div className="">
                            <p style={{ fontSize: "2vw", color: "#0D54BD" }}>{reportAnalyse.user ? reportAnalyse.user:0}</p>
                            <p>{getString('label_report_drawn_connections')}</p>
                        </div>
                        <div className="">
                            <p style={{ fontSize: "2vw", color: "#0D54BD" }}>{reportAnalyse.correct ? reportAnalyse.correct:0}</p>
                            <p>{getString('label_report_correct_connections')}</p>
                        </div>
                        <div className="">
                            <p style={{ fontSize: "2vw", color: "#0D54BD" }}>{reportAnalyse.influence ? reportAnalyse.influence:0}</p>
                            <p>{getString('label_report_nf_connections')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PowermapReport