import React, { useEffect, useState } from "react";
import Character from "./Character";
import { useAppDispatch, useAppSelector } from "config/hooks";
import { bindActionCreators } from "redux";
import { actionCreators } from "store";
import "./orgChart.scss";
import { IClientCharacter } from "interfaces/storyline";
import {
	OnetoOneLine,
	OnetoTwoLine,
} from "components/commonComponents/connectors/connectors";
import CharacterPopup from "./characterPopup/CharacterPopup";
import Overlay from "components/commonComponents/overlay/Overlay";
import BaseActionResponse from "../actionResponse/BaseActionResponse";
import EventStatement from "../events/EventStatement";
import ActionOptions from '../actionResponse/ActionOptions';
import EventResult from '../events/EventResult';

interface OrgChartProps {
	isGameOver: boolean
}

const OrgChart: React.FC<OrgChartProps> = (props: OrgChartProps) => {
	let clientCharacters = useAppSelector((state) => state.storylineData.clientCharacters);
	clientCharacters = clientCharacters ? clientCharacters : [];


	const currentActionResponse = useAppSelector(state => state.currentActionResponse)
	const showEvent = useAppSelector(state => state.globalData.showEvent)
	const showReport = useAppSelector(state => state.globalData.showReport)
	const showWalkthrough = useAppSelector(state => state.globalData.showWalkthrough)
	const showActionResponse = useAppSelector(state => state.globalData.showActionResponse)
	const showActionOption = useAppSelector(state => state.globalData.showActionOption)
	const isGroupActionOpen = useAppSelector((state) => state.globalData.isGroupActionOpen)
	const selectedCharacterList = useAppSelector((state) => state.globalData.selectedCharacterList)
	const dispatch = useAppDispatch()
	const { updateSelectedCharacterList, setDimensions, resetSelectedCharacterList, setShowActionResponse, setShowEvent, addEventData, setShowReport, resetSelectedTeamMember } = bindActionCreators(actionCreators, dispatch)

	const [characterClicked, setCharacterClicked] = useState(-1);
	const [actorInfo, showActorInfo] = useState(false);

	const eventData = useAppSelector(state => state.eventData.data)

	useEffect(() => {
		const updateWindowDimensions = () => {
			setDimensions(document.documentElement.clientHeight, document.documentElement.clientWidth)
		};

		window.addEventListener("resize", updateWindowDimensions);
		return () =>
			window.removeEventListener("resize", updateWindowDimensions);
	}, [])


	useEffect(() => {
		if (props.isGameOver && characterClicked != -1) {
			setCharacterClicked(-1)
			showActorInfo(false)
		}
	}, [props.isGameOver])

	/*
		Character click will have different behaviours in cases when Group Action is open
		or closed. 
	*/
	const handleCharacterClick = (index: number, character: IClientCharacter) => {
		const isCharacterSelected = selectedCharacterList.find((char: any) => { return char['stc_id'] === character['stc_id'] })
		if (isCharacterSelected) {
			updateSelectedCharacterList(character, false)
		}
		else {
			updateSelectedCharacterList(character, true)
		}

		if (!isGroupActionOpen) {
			//set Walkthrough done if not already done
			setCharacterClicked(index)
			showActorInfo(true)
		}
	};

	const activeCharacterDescription = clientCharacters.filter(
		(character: IClientCharacter) => {
			return character['stc_id'] == characterClicked;
		}
	);
	
	/*
		Handle exit character popup. 
		Events data can come with action response in case when background info was taken
		so we need to check during closing of character popup if there is event data present.
		Also, monthly report check needs to be done since, bg info action will cost 1 day which can change
		month number. 
	*/
	const handleExit = (selectedTeamMember?: any) => {

		const isTeamMemberEmpty = (selectedTeamMember && Object.keys(selectedTeamMember).length === 0) || !selectedTeamMember

		if (showWalkthrough == true && isTeamMemberEmpty) {
			return;
		}

		if (eventData.length > 0 && isTeamMemberEmpty) {
			setShowEvent(true)
		}
		else if (showEvent) {
			setShowEvent(false)
		}

		// no action and no event to occur then show report on character popup exit. 
		if (showReport == 1 && eventData.length == 0 && isTeamMemberEmpty) {
			setShowReport(2)
		}

		//reset selected char list in all cases and update it in takeaction handler if needed
		resetSelectedCharacterList()
		setCharacterClicked(-1)
		showActorInfo(false)
	};

	const handleExitActionResponse = () => {
		// redux global state reset to defaults
		resetSelectedTeamMember()
		resetSelectedCharacterList()
		setShowActionResponse(false)

		/* 
			check if events data present then show event and similarly for monthly report
			if showReport == 1(means month number has changed) then show monthly report popup
		*/
		if (currentActionResponse.event.length > 0) {
			addEventData(currentActionResponse.event)
			setShowEvent(true)
		}
		else if (eventData.length > 0) {
			setShowEvent(true)
		} else if (showReport == 1) {
			setShowReport(2)
		}
	}

	const isSelected = (chr: any) => {
		for (let i = 0; i < selectedCharacterList.length; i++) {
			if (selectedCharacterList[i]['stc_id'] === chr['stc_id'])
				return true
		}
		return false
	}

	return (
		<>
			<div id="client-container" className="client-container">
				{clientCharacters.map(
					(chr: IClientCharacter, index: number) => {
						if (index == 1) {
							return (
								<div className="client-row-1" key={index}>
									<Character
										index={index - 1}
										character={clientCharacters[index - 1]}
										handleClick={handleCharacterClick}
										isCharacterSelected={isSelected(clientCharacters[index - 1])}
									/>
									<Character
										index={index}
										character={chr}
										handleClick={handleCharacterClick}
										isCharacterSelected={isSelected(chr)}
									/>
								</div>
							);
						} else if (index == 2)
							return (
								<div key={index}>
									<div className="client-connectors">
										<OnetoTwoLine />
										<OnetoOneLine x1={8} x2={8} y2={25} />
									</div>
									<div className="client-row-3">
										<Character
											index={index}
											character={chr}
											handleClick={handleCharacterClick}
											isCharacterSelected={isSelected(chr)}
										/>
										<Character
											index={index + 1}
											character={
												clientCharacters[index + 1]
											}
											handleClick={handleCharacterClick}
											isCharacterSelected={isSelected(clientCharacters[index + 1])}
										/>
										<Character
											index={index + 2}
											character={
												clientCharacters[index + 2]
											}
											handleClick={handleCharacterClick}
											isCharacterSelected={isSelected(clientCharacters[index + 2])}
										/>
									</div>
								</div>
							);
						else if (index == 5)
							return (
								<div key={index}>
									<div>
										<OnetoOneLine
											x1={4.3}
											x2={4.3}
											y2={20}
										/>
									</div>
									<div className="client-row-5">
										<Character
											index={index}
											character={chr}
											handleClick={handleCharacterClick}
											isCharacterSelected={isSelected(chr)}
										/>
									</div>
								</div>
							);
					}
				)}
			</div>
			{actorInfo ? (
				<div
					id="client-description-container"
					className="client-description-container"
				>
					<Overlay zIndex={6} onClickHandler={handleExit} />
					{activeCharacterDescription.length > 0 && (
						<CharacterPopup
							character={activeCharacterDescription[0]}
							handleExit={handleExit}
							isGameOver={props.isGameOver}
							
						/>
					)}
				</div>
			) : null}
			{showActionResponse? <BaseActionResponse handleExit={handleExitActionResponse} /> : null}
			{/* Checks to perform priority scheduling in order of:
				1. Action Response 
				2. Action Option Popup 
				3. Character Popup opened 
				4. Event Popup 
				5. Monthly Report Popup
			*/}
			{!showActionResponse && !showActionOption && characterClicked === -1 && showEvent && eventData.length > 0 && eventData[0].ev_type === 2 ? <EventStatement showEvent={true} eventData={eventData[0]} /> : null}
			{!showActionResponse && !showActionOption && characterClicked === -1 && showEvent && eventData.length > 0 && eventData[0].ev_type === 1 ? <EventResult eventData={eventData[0]} eventType={1} /> : null}
			{showActionOption ? <ActionOptions showActorInfo={showActorInfo} setCharacterClicked={setCharacterClicked} /> : null}

			{/* Monthly report handle */}
		</>
	);
};

export default OrgChart;
