import { Button, buttonClasses, Radio, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/hooks";
import { bindActionCreators } from "redux";
import { actionCreators } from "store";
import { IStringObject } from 'interfaces/storyline';
import React, { useEffect, useState } from "react";
import { getEventResultData, getString } from 'utils';
import { getEventParams } from '../clientCharacters/characterPopup/actions/ActionParams';
import EventBase from "./EventBase";
import EventResult from './EventResult';
import styles from "./events.module.scss";

interface EventStatementProps {
	showEvent: boolean;
	eventData: any;
}

const EventStatement = (props: EventStatementProps) => {
	const [selectedMessage, setSelectedMessage] = useState(-1);
	const [showEventOption, setShowEventOption] = useState(true);
	const [eventData, setEventData] = useState(props.eventData)
	const [showLoader, setShowLoader] = useState(true)

	const dispatch = useAppDispatch()
	const { setShowProgressBarChange } = bindActionCreators(actionCreators, dispatch)

	// const btnaudio = new Audio("/button_cta.mp3")

	const handleChange = (messageOption: number) => {
		setSelectedMessage(messageOption)
	}

	useEffect(() => {
		setTimeout(function () {
			setShowLoader(false)
		}, 300)
		// setShowProgressBarChange(true)
	}, [])

	const CustomButton = styled(Button)({
		[`&.${buttonClasses.disabled}`]: {
			opacity: "0.2",
			background: "#FBEC66;",
			color: "#0D54BD",
		},
	});

	const executeEventHandler = async () => {
		// btnaudio.play()

		const eventParams = getEventParams(props.eventData, selectedMessage)
		const eventResultData = await getEventResultData(eventParams)

		setEventData(eventResultData)
		setShowEventOption(false)

	}

	return (
		<>
			{showEventOption ? (
				<EventBase
					eventTitle={getString(props.eventData.ev_name)}
					eventDesc={getString(props.eventData.ev_desc)}
					disableExit={true}
					impact={0}
					showLoader={showLoader}
				>
					<div className={styles.msg_options}>
						{props.eventData.ev_options.map((option: IStringObject, index: number) => {
							return (
								<div
									key={index}
									className={styles.individual_msg}
								>
									<Radio
										className={styles.radio}
										disableRipple
										checked={selectedMessage === index}
										onChange={() => handleChange(index)}
										value={index}
										name="radio-buttons"
										inputProps={{ "aria-label": "A" }}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 15,
											},
										}}
									/>
									<span className={styles.msg_text} onClick={() => handleChange(index)}>{getString(option['seo_desc'])}</span>
								</div>
							);
						})}
					</div>
					<CustomButton
						variant="contained"
						disableRipple={true}
						className={styles.respond_event_button}
						disabled={selectedMessage === -1}
						onClick={() => executeEventHandler()}
					>
						{getString('label_event_respond')}
					</CustomButton>
				</EventBase>
			) :
				<EventResult eventData={eventData} eventType={2} />
			}
		</>
	);
};

export default EventStatement;
