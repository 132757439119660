import React, { useEffect, useState } from 'react'
import EventBase from './EventBase'
import { useAppDispatch, useAppSelector } from "config/hooks";
import { bindActionCreators } from "redux";
import { actionCreators } from "store";
import BaseHandler from 'utils/actionResponseHandler';
import { computeOverallTrust, getString } from 'utils';
import styles from "./events.module.scss";
import { TrustChangeIndicator } from 'components/commonComponents/trustChangeIndicator/TrustChangeIndicator';
import { characterImages } from 'utils/characterImages';

interface EventResultProps {
  eventData: any
  eventType: number
}

const EventResult = (props: EventResultProps) => {

  const clientChars = useAppSelector(state => state.storylineData.clientCharacters)
  const showReport = useAppSelector(state => state.globalData.showReport)
  const allEventsData = useAppSelector(state => state.eventData.data)
  const showEvent = useAppSelector(state => state.globalData.showEvent)
  const charList = useAppSelector(state => state.lists.characterList)
  const dispatch = useAppDispatch()
  const { setShowEvent, setShowProgressBarChange, removeEventData, updateHistoryData, setShowReport } = bindActionCreators(actionCreators, dispatch)

  const [showLoader, setShowLoader] = useState(true)
  const [trustImpact, setTrustImpact] = useState(0)

  const historyData = props.eventType === 1 ? props.eventData.history : props.eventData
  const userHistory = historyData.user_game_history_details
  useEffect(() => {
    if (showEvent) {
      const handlerObj = new BaseHandler()
      handlerObj.updateCharacterData(userHistory, clientChars)
      const { deltaTrust, deltaTrustComp } = computeOverallTrust()

      if (deltaTrust - deltaTrustComp > 0) {
        setTrustImpact(1)
      }
      else if (deltaTrust - deltaTrustComp < 0) {
        setTrustImpact(-1)
      }
      setShowLoader(false)
      setShowProgressBarChange(true)
    }

    return function cleanup() {
      //update history data
      updateHistoryData(historyData)

      if (allEventsData.length > 1) {
        setShowEvent(true)
      }
    }

  }, [showEvent])

  const handleExit = () => {
    setShowProgressBarChange(false)
    setShowEvent(false)
    removeEventData([allEventsData[0]])

    if (allEventsData.length > 1) {
      // setTimeout(setShowEvent(true), 5000)
    } else if (showReport == 1) {
      setShowReport(2)
    }
  }


  return (
    <>
      <EventBase
        eventTitle={getString(historyData['gh_interaction_heading'])}
        eventDesc={getString(historyData['gh_interaction_desc'])}
        handleExit={handleExit}
        impact={trustImpact}
        showLoader={showLoader}
      >
        <div className={styles.event_res}>
          {
            userHistory.map((char: any, index: number) => {
              const img = getString(charList[char['ghd_stc_id']]['ch_image'])
              return (
                <div
                  key={index}
                  className={styles.card}
                >
                  <img
                    src={
                      characterImages(img)
                    }
                    alt="affected-character"
                    className={styles.char}
                  />
                  <div className={styles.impact}>
                    <div className={styles.first_name}>
                      {getString(charList[char['ghd_stc_id']]['ch_name'])}
                    </div>
                    <div className={styles.impact_body}>
                      <div className={styles.each}>
                        <span className={styles.label} >
                          {getString("label_character_y")} :{" "}
                        </span>
                        <span className={styles.ty} style={{ color: '#FC8484' }}>
                          {" "}
                          {charList[char['ghd_stc_id']]['gc_trust']}
                          <span className={styles.percentage}>
                            %
                          </span>
                        </span>
                        <TrustChangeIndicator
                          impactValue={char['ghd_delta']}
                          positiveColor="rgba(108, 219, 152, 0.5)"
                          negativeColor="rgba(252, 132, 132, 0.5)"
                          neutralColor="#DFEAFB"
                        />
                      </div>
                      <div className={styles.each}>
                        <span className={styles.label}>
                          {getString("label_character_c")} :{" "}
                        </span>
                        <span className={styles.ty} style={{ color: '#FCDA84' }}>
                          {" "}
                          {charList[char['ghd_stc_id']]['gc_trust_comp']}
                          <span className={styles.percentage}>
                            %
                          </span>
                        </span>
                        <TrustChangeIndicator
                          impactValue={char['ghd_comp_delta']}
                          positiveColor="rgba(108, 219, 152, 0.5)"
                          negativeColor="rgba(252, 132, 132, 0.5)"
                          neutralColor="#DFEAFB"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

      </EventBase>
    </>
  )
}

export default EventResult
