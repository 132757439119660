import React, { useEffect } from 'react'
import { getString } from 'utils'
import popper from 'assets/images/party_popper.svg'
import './steps.scss';
import { PathConnector } from 'components/commonComponents/connectors/connectors';
import { useAppSelector } from 'config/hooks';

interface Step8Props {
  tooltipText?: string
}

const Step8 = (props: Step8Props) => {

  const { height, width } = useAppSelector((state) => state.dimensions)
  const  audio = new Audio("/confetti.mp3")

  useEffect(()=>{
    audio.play()
  },[])

  return (
    <>
      <PathConnector
        pathD={`M ${width / 9} ${height / 14} V ${height / 110} H ${width / 20
          } L ${width / 30} ${height / 110}`}
        class="s8-connector1 animate fadeIn"
        cx={width / 9}
        cy={height / 14}
        r={height / 300}
      />
      <div className='s8-group-action-unlocked animate fadeIn'>
        <div className='s8-content'>
          <div className="title">
            <img src={popper} alt='popper' /> <br />
            {props.tooltipText ? props.tooltipText : getString('label_group_action_unlocked')}
          </div>
        </div>
      </div>
    </>
  )
}

export default Step8
