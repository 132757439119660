import React, { useEffect, useState } from "react"
import * as Chartist from "chartist"
import { useAppSelector } from "config/hooks"
import { getString } from "utils"

const Analysis: React.FC = () => {

    const reportAnalysis = useAppSelector(state => state.reportAnalysis.data)
    const simDays = useAppSelector(state => state.storylineData.simDays)


    const getdata = (updateState) => {
        const labels: any = [], series: any = []
        for (let i = 0; i < simDays; i++) {
            labels.push(i)
        }
        reportAnalysis.map((ele: any) => {
            series[ele["ghDay"]] = ele["ghdDelta"]
        })
        if (updateState) {
            setData({
                labels,
                series: [series]
            })
        } else {
            return {
                labels,
                series: [series]
            }
        }
    }

    const [data, setData] = useState(getdata(false))

    useEffect(() => {
        getdata(true)
    }, [])

    const lineChartOptions = {
        // Don't draw the line chart points
        showPoint: true,

        // // Disable line smoothing
        lineSmooth: true,

        // X-Axis specific configuration
        axisX: {
            // We can disable the grid for this axis
            showGrid: true,

            // and also don't show the label
            showLabel: true,
            position: 'end',


            labelInterpolationFnc: function (value: any) {
                if (value % 10 === 0) {
                    return value;
                }
            }
        },

        // Y-Axis specific configuration
        axisY: {
            // Lets offset the chart a bit from the labels
            // offset: 60,
            low: 0,
            high: 100,
            onlyInteger: true,
            showLabel: true,
            position: 'start',
            labelInterpolationFnc: function (value: any) {
                return value + '%';
            }
        },
        showArea: true,
        classNames: { point: 'ct-point', area: 'ct-area', line: 'ct-line', },
        chartPadding: {
            top: 15,
            right: 15,
            bottom: 5,
            left: 10
        },
    };

    const type = "Line"

    return (
        <div className="analysis" style={{ width: "70vw", height: "50vmin", marginTop: "2vh" }}>
            <span className="ylabel">{getString("label_mr_trust_percent")} </span>
            <RenderChart data={data} lineChartOptions={lineChartOptions} type={type} />
            <span className="xlabel">{getString("label_gen_days")}</span>
        </div>
    )
}

const RenderChart: React.FC<{ data, lineChartOptions, type }> = (props) => {
    useEffect(() => {
        new Chartist.LineChart('.ct-octave', props.data, props.lineChartOptions);
    })
    return (
        <div className='ct-octave' />
    )

}

export default Analysis