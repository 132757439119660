import React from "react";
import ceo from "assets/images/intro/ceo.svg"
import qoute from "assets/images/intro/qoute.svg"
import { getString } from "utils";


const BuildingTrust: React.FC = (props) => {

    return (
        <div className="bt-container">
            <div className="theader">
                <p className="thtext" dangerouslySetInnerHTML={{ __html: getString("label_intro_buildtrust_desc") }}></p>
            </div>
            <div className="tbody">
                <p className="bbody" dangerouslySetInnerHTML={{ __html: getString("label_intro_buyerrole_desc") }}></p>
                <ul>
                    <li>
                        <span className="li-head" dangerouslySetInnerHTML={{ __html: getString("label_intro_evaluator") + ": " }} />
                        <span className="bdesc" dangerouslySetInnerHTML={{ __html: getString("label_intro_evaluator_desc") }} />
                    </li>
                    <li>
                        <span className="li-head" dangerouslySetInnerHTML={{ __html: getString("label_intro_recommender") + ": " }} />
                        <span className="bdesc" dangerouslySetInnerHTML={{ __html: getString("label_intro_recommender_desc") }} />
                    </li>
                    <li>
                        <span className="li-head" dangerouslySetInnerHTML={{ __html: getString("label_intro_approver") + ": " }} />
                        <span className="bdesc" dangerouslySetInnerHTML={{ __html: getString("label_intro_approver_desc") }} />
                    </li>
                    <li>
                        <span className="li-head" dangerouslySetInnerHTML={{ __html: getString("label_intro_influencer") + ": " }} />
                        <span className="bdesc" dangerouslySetInnerHTML={{ __html: getString("label_intro_influencer_desc") }} />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default BuildingTrust;
