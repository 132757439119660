import React from "react";
import aboutIllustration from "assets/images/intro/illustration_about.svg"
import qoute from "assets/images/intro/qoute.svg"
import { getString } from "utils";

interface IntroProps {
    onTabChange: (newValue: number) => void
    isPopup?: boolean
}

const About: React.FC<IntroProps> = (props: IntroProps) => {

    // const btnaudio = new Audio("/button_cta.mp3")

    const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
        // btnaudio.play()
        props.onTabChange(2);
    }

    const handleBack = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onTabChange(0);
    }
    return (
        <div>
            {!props.isPopup ? <div className="theader">
                <img src={aboutIllustration} className="timage" />
                <img src={qoute} className="tqoute" />
                <div className="ceo-qoute">
                    <p dangerouslySetInnerHTML={{ __html: getString("label_intro_steve_read") }} />
                </div>
            </div> : null}
            <div className="tbody">
                <p className="bhead bbody" dangerouslySetInnerHTML={{ __html: getString("casestudy_default") }} />
                {!props.isPopup ? <div className="tbuttons">
                    <button className="back" onClick={handleBack}>{getString("label_intro_back")}</button>
                    <button className="next" onClick={handleNext}>{getString("label_intro_next")}</button>
                </div> : null}
            </div>
        </div>
    )
}

export default About;
