import actionConsts from "../store/constants"
import { store } from 'store';
import { computeOverallTrust, getMonth, limitTrustToValidRange } from 'utils';
import { IClientCharacter } from 'interfaces/storyline';

export default class BaseHandler {

  private currentActionResponse
  private month
  private monthInterval
  private simDays

  constructor() {
    this.currentActionResponse = store.getState().currentActionResponse
    this.month = store.getState().globalData.month
    this.monthInterval = store.getState().storylineData.monthInterval
    this.simDays = store.getState().storylineData.simDays
  }

  init(reqData: any): void {

    const { actionExecutionStatus } = this.currentActionResponse.action

    switch (reqData.actId) {
      //special case for Background info action
      case 1: {
        if (actionExecutionStatus) {
          store.dispatch({
            type: actionConsts.SET_BG_READ,
            payload: reqData.stcId,
          })
          const { updatedMonth, updatedDay } = this.updateStats(reqData.actId, reqData.days, reqData.actDays, this.simDays)

          //update history data
          const history = this.currentActionResponse.action.history; // object
          store.dispatch({
            type: actionConsts.UPDATE_HISTORY,
            payload: history
          })
          this.checkShowReport(updatedMonth, updatedDay)
        }
        break;
      }
      default: {
        if (actionExecutionStatus) {
          const { updatedMonth, updatedDay } = this.updateStats(reqData.actId, reqData.days, reqData.actDays, this.simDays)
          this.checkShowReport(updatedMonth, updatedDay)
        }

        //update history data
        const history = this.currentActionResponse.action.history; // object
        store.dispatch({
          type: actionConsts.UPDATE_HISTORY,
          payload: history
        })

        const clientChars = store.getState().storylineData.clientCharacters
        const historyData = this.currentActionResponse.action.history.user_game_history_details
        this.updateCharacterData(historyData, clientChars)
        computeOverallTrust(clientChars);

        store.dispatch({
          type: actionConsts.UPDATE_GLOBAL_DATA,
          payload: { showActionResponseLoader: false }
        })
        store.dispatch({
          type: actionConsts.UPDATE_GLOBAL_DATA,
          payload: { showActionResponse: true }
        })

        /* stop walkthrough if is ongoing */
        const showWalkthrough = store.getState().globalData.showWalkthrough
        if (showWalkthrough) {
          store.dispatch({
            type: actionConsts.UPDATE_GLOBAL_DATA,
            payload: { showWalkthrough: false }
          })
        }
      }
    }
  }

  // if month is changed then update showReport = 1 in global state
  checkShowReport(updatedMonth: number, updatedDay: number): void {

    if (this.month !== updatedMonth) {
      // set show Report other than at sim end. At sim end, showreport handled by SimEndScreen.tsx
      if (updatedDay != this.simDays) {
        store.dispatch({
          type: actionConsts.UPDATE_GLOBAL_DATA,
          payload: { showReport: 1 }
        })
      }

      store.dispatch({
        type: actionConsts.UPDATE_GLOBAL_DATA,
        payload: { month: updatedMonth }
      })
    }
  }

  updateStats(actId: number, currentDay: number, actionCost: number, simDays: number) {
    let updatedDay = currentDay + actionCost
    updatedDay = updatedDay > simDays ? simDays : updatedDay
    store.dispatch({
      type: actionConsts.UPDATE_USER_DATA,
      payload: { userCurrentDay: updatedDay }
    })

    const updatedMonth = getMonth(updatedDay.toString(), this.monthInterval)
    store.dispatch({
      type: actionConsts.UPDATE_GLOBAL_DATA,
      payload: { month: updatedMonth }
    })
    return { updatedMonth, updatedDay }
  }



  updateCharacterData(historyData: any, clientCharacters: any): void {
    const gameHistoryData = historyData
    const clientCharactersData = clientCharacters


    const updatedCharactersData = clientCharactersData.map((charData: IClientCharacter) => {
      let updatedChar = charData

      for (let i = 0; i < gameHistoryData.length; i++) {
        if (gameHistoryData[i]['ghd_stc_id'] === charData['gc_stc_id']) {
          let updatedTrust = charData['gc_trust'] + gameHistoryData[i]['ghd_delta']
          let updatedTrustComp = charData['gc_trust_comp'] + gameHistoryData[i]['ghd_comp_delta']
          updatedTrust = limitTrustToValidRange(updatedTrust)
          updatedTrustComp = limitTrustToValidRange(updatedTrustComp)
          updatedChar = {
            ...charData, 'gc_trust': updatedTrust, 'gc_trust_comp': updatedTrustComp
          } //to prevent auto update of charData in global state
          break
        }
      }
      return updatedChar
    })


    /*
      since we have maintained characters as map in global state and clientcharacters 
      is also present in storylineData global state, we need to update the new data in both places
    */
    store.dispatch({
      type: actionConsts.UPDATE_STORYLINE_DATA,
      payload: { clientCharacters: updatedCharactersData }
    })

    store.dispatch({
      type: actionConsts.UPDATE_CHARACTER_LISTS,
      payload: updatedCharactersData
    })
  }

  //  init = (req: any) => {

  //   if (actionExecutionStatus) {
  //     switch (req.actId) {
  //       case 1: {
  //         // showBgInfo()
  //         updateStats(1)
  //         break;
  //       }
  //       default: {
  //         const { currentMonth } = updateStats(req.actDays)
  //         renderActionResponse()
  //         event.length ? renderEvents() : null
  //         if (currentMonth != month)
  //           renderMonthlyReport()
  //       }
  //     }
  //   }
  // }



  // updateStats = (actId: number) => {
  //   //day
  //   //month 
  //   // const updatedDay =
  //   // 					performActionParams.days + performActionParams.actDays
  //   if (actId !== 1) {
  //     //trust computeOveralltrust(history/)
  //     renderActionResponse()

  //   }

  // }

  // const renderActionResponse = () => {
  //   // showActionResponse = true
  // }



}


export class ActionOptionsHandler {
  private optionsArray

  constructor(data: any) {
    this.optionsArray = data
  }

}

