import Popup from 'components/commonComponents/popup/Popup'
import { useAppDispatch, useAppSelector } from 'config/hooks'
import React, { useState } from 'react'
import { bindActionCreators } from "redux"
import { actionCreators } from 'store'
import { addClassToElement, getString } from 'utils'
import rightArrow from "assets/images/rightArrow_gradient.svg";
import './actionOptions.scss'
import { IClientCharacter } from 'interfaces/storyline'
import { Button, buttonClasses, Radio, styled } from '@mui/material'
import { groupActionParams, individualActionParams } from '../clientCharacters/characterPopup/actions/ActionParams'
import { characterImages } from 'utils/characterImages'

interface IActionOptions {
  showActorInfo?: (arg0: boolean) => void
  setCharacterClicked?: (arg0: number) => void
}



const ActionOptions: React.FC<IActionOptions> = (props: IActionOptions) => {

  const currentActionData = useAppSelector(state => state.globalData.currentActionData)
  const selectedTeamCharData = useAppSelector((state) => state.globalData.selectedTeamMemberData)
  const selectedCharacterList = useAppSelector((state) => state.globalData.selectedCharacterList)
  const optionsData = useAppSelector(state => state.actionOptionsResponse.data)
  const userCurrentDay = useAppSelector((state) => state.initUser.userCurrentDay);
  const storylineId = useAppSelector((state) => state.storylineData.storylineId);
  const showWalkthrough = useAppSelector(state => state.globalData.showWalkthrough)
	const showGroupActions = useAppSelector(state => state.initUser.showGroupActions)

  // const timer = useAppSelector(state => state.globalData.timer)
  const dispatch = useAppDispatch();
  const { resetActionOptionsResponse, setShowActionOption, performAction, setActionResponseLoader, resetSelectedCharacterList, updateSelectedCharacterList, setShowGroupActions } = bindActionCreators(actionCreators, dispatch)


  // useEffect(() => {

  //   if (timer == 0) {
  //     handleClose()
  //   }
  // }, [timer])

  const [selectedOption, setSelectedOption] = useState(-1)

  const currentActionImg = getString(currentActionData['act_image'])
  const selectedTeamCharImg = getString(selectedTeamCharData['ch_image'])
  // const btnaudio = new Audio("/button_cta.mp3")

  const handleClose = (isActionTaken?: boolean) => {
    // resetSelectedCharacterList()
    resetActionOptionsResponse()
    if (showWalkthrough) {
      addClassToElement('dashboard-header', 'z-index-3')
    }
    setShowActionOption(false)

    //character popup will open if the Action Option is closed/cancelled without taking action in case of individual actions
    if (!isActionTaken && !currentActionData.isGroupAction) {
      props.showActorInfo ? props.showActorInfo(true) : null
      props.setCharacterClicked ? props.setCharacterClicked(selectedCharacterList[0]["stc_id"]) : null
    }
    else if(!isActionTaken) {
      resetSelectedCharacterList()
    }

  }

  const handleChange = (index: number) => {
    setSelectedOption(index);
  };

  const CustomButton = styled(Button)({
    [`&.${buttonClasses.disabled}`]: {
      opacity: "0.2",
      background: "#FBEC66;",
      color: "#0D54BD",
    },
  });

  const takeActionHandler = async () => {
    // btnaudio.play()

    if (currentActionData.isGroupAction) {
      const characterData = selectedCharacterList
      const optionText = optionsData[selectedOption]['aco_desc']
      const selectedOptionData = optionsData[selectedOption]
      const actionParams = groupActionParams(
        currentActionData,
        characterData,
        userCurrentDay,
        storylineId,
        optionText,
        selectedTeamCharData,
        undefined,
        undefined,
        selectedOptionData,
      )

      handleClose(true)
      setActionResponseLoader(true)
      // to update selected char list after reseted by handleClose
      // characterData.forEach((char: IClientCharacter) => {
      //   updateSelectedCharacterList(char, true)
      // })

      if (selectedOption !== -1) {
        const response = await performAction(actionParams)
      }
    }
    else {

      const characterData = selectedCharacterList[0]
      const optionText = optionsData[selectedOption]['aco_desc']
      const selectedOptionData = optionsData[selectedOption]
      const actionParams = individualActionParams(
        currentActionData,
        characterData,
        userCurrentDay,
        storylineId,
        optionText,
        selectedTeamCharData,
        selectedOptionData,
      )

      handleClose(true)
      // to update selected char list after reseted by handleExitCharacterPopup
      // updateSelectedCharacterList(characterData, true)

      setActionResponseLoader(true)
      if (selectedOption !== -1) {
        const response = await performAction(actionParams)
        if (!showWalkthrough && !showGroupActions){
          setShowGroupActions()
        }
      }
    }
  }

  return (
    <>
      <Popup handleClose={() => handleClose(false)} zIndex={7}>
        <div className="ohead phead">
          <div className="oaction-name">
            <img
              src={
                characterImages(currentActionImg)
              }
              alt="action-name"
              className="oaction-icon"
            />
            <span className="oaction-title">{getString(currentActionData["act_name"])}</span>
          </div>
        </div>
        <div className="obody pbody" style={{ minWidth: '52vw', minHeight: '36.5vh', maxHeight: '70vh' }}>
          <div className="oaction-characters">
            <img
              src={
                characterImages(selectedTeamCharImg)
              }
              className="ofrom-character"
            />
            <img
              src={rightArrow}
              alt="rightArrow"
              className="oarrow-right"
            />
            {
              selectedCharacterList.map((char: IClientCharacter) => {
                const img = getString(char['ch_image'])
                return (
                  <img
                    key={char['stc_id']}
                    src={
                      characterImages(img)
                    }
                    alt="affected-character"
                    className="oto-character"
                  />
                );
              })
            }
          </div>
          <div className='option-container'>
            <div className='select-option-title'>
              {getString('label_action_select_option')}
            </div>
            <div className='olist'>
              {optionsData.map((option: any, index: number) => {
                return (
                  <div
                    key={option['aco_id']}
                    className='option'
                  >
                    <Radio
                      className='oradio'
                      disableRipple
                      checked={selectedOption === index}
                      onChange={() => handleChange(index)}
                      value={index}
                      name="oradio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                        },
                      }}
                    />
                    <span className='option-text' onClick={() => handleChange(index)}>{getString(option['aco_desc'])}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <CustomButton
            variant="contained"
            disableRipple={true}
            className="otake-action-button"
            disabled={selectedOption == -1}
            onClick={() => takeActionHandler()}
          >
            {getString('label_take_action')}
          </CustomButton>
          <Button
            variant="text"
            disableRipple={true}
            className="ocancel-action-button"
            onClick={() => handleClose(false)}
          >
            {getString('label_gen_cancel')}
          </Button>

        </div>

      </Popup>
    </>
  )
}

export default ActionOptions
