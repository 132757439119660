
const initializeSentry = (sentryScopeObj) => {
	try {
		window.Sentry.init({
			environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
			dsn: process.env.REACT_APP_SENTRY_DSN,
			release: process.env.REACT_APP_SENTRY_RELEASE,
		});
		window.Sentry.configureScope((scope) => {
			scope.setUser(sentryScopeObj);
		});
	} catch (e) {
		console.log(e);
	}
};

export default initializeSentry;
