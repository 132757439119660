import IntroHeader from "components/intro/IntroHeader"
import React, { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { useAppSelector, useAppDispatch } from "config/hooks"
import { actionCreators } from 'store'
import { useHistory } from 'react-router-dom'

import background from "assets/images/intro/BG_intro.svg"
import IntroFooter from "components/intro/IntroFooter"
import VideoPlayerComponent from 'components/VideoPlayer/VideoPlayerComponent'
import Transcript from 'components/VideoPlayer/Transcript'
import '../components/VideoPlayer/videoPlayer.scss'
import { getString } from "utils"

const Video: React.FC = () => {

    const [isVideoCompleted, setIsVideoCompleted] = useState(false)
    const isVideoCompletedinDB = useAppSelector(state => state.initUser.isVideoCompleted)
    const isVideoEnabled = useAppSelector(state => state.initUser.isVideoEnabled)
    const isDemoEnabled = useAppSelector(state => state.initUser.isDemoEnabled)
    const isDemoCompleted = useAppSelector(state => state.initUser.isDemoCompleted)

    // Router useHistory initialisation 
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { setVideoCompleted } = bindActionCreators(actionCreators, dispatch)

    useEffect(() => {
        //uncomment me to enable video feature.
        if (isVideoCompletedinDB == 1 || isVideoEnabled == 0) history.push('/intro')
        // history.push('/intro')
    }, [])

    const onVideoComplete = () => {
        setIsVideoCompleted(true)
    }

    const handleProceed = () => {
        setVideoCompleted()

        if (isDemoEnabled == 1 && isDemoCompleted != 2)
            history.push('/demo')
        else
            history.push('/intro')
    }

    return (
        <div className="intro-container"
            style={{ "background": `url(${background}) no-repeat center center fixed`, backgroundSize: "cover" }
            }>
            <IntroHeader />
            <div className='video-player-container'>
                <VideoPlayerComponent
                    kalturaVideoId={getString("label_video_id")}
                    propRef='intro-video'
                    onVideoComplete={onVideoComplete}
                />
                <Transcript isVideoCompleted={isVideoCompleted} handleClick={handleProceed} />
            </div>
            <IntroFooter />
        </div >
    )
}

export default Video