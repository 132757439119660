import { Slider, Switch, switchClasses, sliderClasses, Checkbox, checkboxClasses, tableRowClasses, TableRow, TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustSwitch = styled(Switch)({
    [`& .${switchClasses.thumb}`]: {
        backgroundColor: "#84FCB4"
    },
    [`& .${switchClasses.track}`]: {
        backgroundColor: "#E2EEFF",
        opacity: "inherit"
    },
    [`& .${switchClasses.checked} .${switchClasses.thumb}`]: {
        backgroundColor: "#FC8484"
    },
    [`& .${switchClasses.checked}+ .${switchClasses.track}`]: {
        backgroundColor: "#E2EEFF !important",
        opacity: "inherit"
    },
    [`& .${switchClasses.checked}:hover`]: {
        backgroundColor: "transparent"
    }
})

export const CustSlider = styled(Slider)({
    [`& .${sliderClasses.thumb}`]: {
        borderRadius: "0",
        width: "0.6vw",
        color: "#84FCB4"
    },
    [`& .${sliderClasses.rail}`]: {
        color: "#E2EEFF",
    },
    [`& .${sliderClasses.mark}`]: {
        color: "#E2EEFF",
    },
    [`& .${sliderClasses.track}`]: {
        color: "#84FCB4",
        height: "0.1vh"
    },
})

export const CustSliderNeg = styled(Slider)({
    [`& .${sliderClasses.thumb}`]: {
        borderRadius: "0",
        width: "0.6vw",
        color: "#FC8484"
    },
    [`& .${sliderClasses.rail}`]: {
        color: "#E2EEFF",
    },
    [`& .${sliderClasses.mark}`]: {
        color: "#E2EEFF",
    },
    [`& .${sliderClasses.track}`]: {
        color: "#FC8484",
        height: "0.1vh"
    },
})

export const CustTableRow = styled(TableRow)({
    [`&.${tableRowClasses.root}`]: {
        boxShadow: "0px 2px 4px #e7effe",
        borderRadius: "1.2vw",
        color: "#0D54BD"
    }
})

// export const YouTableCell = styled(TableCell)({

// })
