import React, { useState } from "react";
import { bindActionCreators } from "redux"

import { useAppDispatch, useAppSelector } from "config/hooks";


import YourTeam from "components/commonComponents/yourTeam/YourTeam";
import illustrationTeam from 'assets/images/intro/illustration_meet_your_team.svg'
import { getString } from "utils"
import { IStringObject } from "interfaces/storyline";
import { actionCreators } from "store";
import './body.scss';

interface TabPanelProps {
    setVisited?: (visited: boolean) => void;
    selectedCharacter?: any
}

const ActorTabs: React.FC<TabPanelProps> = (props: TabPanelProps) => {


    const dispatch = useAppDispatch();

    const { setActorVisited } = bindActionCreators(actionCreators, dispatch)

    const { teamCharacters } = useAppSelector(state => state.storylineData)
    const [character, setCharacter] = useState(props.selectedCharacter ? props.selectedCharacter : teamCharacters[0])
    const { visitedAll } = useAppSelector(state => state.mty)

    const onActorClickHandler = (memberIndex: number, member: IStringObject) => {
        const update = visitedAll + 1
        setCharacter(teamCharacters[memberIndex])
        setActorVisited(update)
        if (update == 3) {
            setTimeout(function () {
                // console.log("10 secs complete")
                props.setVisited ? props.setVisited(true) : null
            }, 5000);
        }
    }

    return (
        <div style={{ height: "100%" }}>
            <div className='team-header'>
                <div className="actor-tabs">
                    <YourTeam
                        showName={true}
                        showFirstName={true}
                        isYouShown={false}
                        label_you={getString("label_base_you")}
                        class_member_title="intro-team-member-title"
                        class_member_image="intro-team-image"
                        class_team_container="intro-yt"
                        isSelected={character["ch_name"]}
                        selectedClass="intro-selected"
                        onClickHandler={onActorClickHandler} />
                </div>
                <p className="divider"></p>
                <p className="actor-name">{getString(character["ch_name"]).toUpperCase()}</p>
                <img src={illustrationTeam} alt='illustration-team'  className='illustration-team'/>
            </div>
            <div className="actor-desc">
                <p dangerouslySetInnerHTML={{ __html: getString(character["ch_gen_info"]) }}></p>
            </div>
        </div>
    )
}

export default ActorTabs;
