import React, { useEffect } from 'react'
import { useAppSelector } from 'config/hooks'

import { getString } from 'utils'

import './endScreen.scss'

interface ProceedDemoProps {
  handleProceedDemo: (selectedOption: number) => void
}

const ProceedDemoScreen = (props: ProceedDemoProps) => {

  const isDemoCompleted = useAppSelector(state => state.initUser.isDemoCompleted)
  // const btnaudio = new Audio("/button_cta.mp3")

  const handleProceedDemofn = (param:number) => {
    // btnaudio.play()
    props.handleProceedDemo(param)
  }

  return (
    <>
      <div className='proceed-demo-container'>
        <div className='demo-card'>
          <div className='proceed-demo-body'>
            <div className='demo-message'>
              {isDemoCompleted == 2 ? getString('label_demo_thank_you_message') : getString('label_demo_message')}
            </div>
            {isDemoCompleted != 2 ? <div className='demo-msg-note'>
              {getString("label_demo_exit_note")}
            </div> : null}
            <div className="demo-navigation-buttons">
              <button className="play-demo" onClick={() => handleProceedDemofn(isDemoCompleted == 2 ? 1 : 0)}>
                {isDemoCompleted == 0 ? getString('label_play_demo') :
                  isDemoCompleted == 1 ? getString('label_continue_demo') : getString('label_demo_proceed_to_simulation')
                }
              </button>
              {isDemoCompleted != 2 ? <button className="skip-demo" onClick={() => handleProceedDemofn(1)}>
                {getString('label_demo_skip_to_simulation')}
              </button> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProceedDemoScreen
