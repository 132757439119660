import React, { useEffect, useState } from "react";

import Popup from "components/commonComponents/popup/Popup"

import { Tabs, Tab } from "@mui/material";
import TabSwitcher from "components/intro/IntroBody/TabSwitcher";
import RepsFramework from "./RepsFramework";
import BuildingTrust from "./BuildingTrust";
import ConceptIntro from "./ConceptIntro";
import "./concept.scss"
import { getString } from "utils";


interface IConcepts {
    close: () => void
}

const Concepts: React.FC<IConcepts> = (props: IConcepts) => {

    const [showComponent, setShowComponent] = useState(true)
    const [currentTab, setValue] = React.useState(0);
    // const  audio = new Audio("/popup.mp3")

    // useEffect(()=>{
	// 	audio.play()
	// },[])
    
    const handleClose = () => {
        setShowComponent(false)
        props.close()
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }


    return (
        <>
            {showComponent ?
                <Popup handleClose={handleClose}>
                    <div className="phead">
                        <div style={{ display: "contents" }}>
                            <div className="metric-popup-icon">
                                <img src={require(`assets/images/concepts.svg`).default} style={{ width: "2vw" }} />
                            </div>
                            <span>&nbsp;{getString("label_base_concepts")}</span>
                        </div>
                    </div>
                    <div className="curved-header"></div>
                    <div className="pbody" style={{ marginRight: "0", paddingTop: "3vh" }}>
                        <Tabs
                            value={currentTab}
                            classes={{ indicator: "indicator" }}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label={getString("label_base_about_ts")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} />
                            <Tab label={getString("label_gen_rs")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} />
                            <Tab label={getString("label_gen_ts")} className="tabHead" disableRipple classes={{ textColorPrimary: "textColorPrimary" }} />
                        </Tabs>

                        <TabSwitcher value={currentTab} index={0}>
                            <ConceptIntro />
                        </TabSwitcher>
                        <TabSwitcher value={currentTab} index={1}>
                            <RepsFramework />
                        </TabSwitcher>
                        <TabSwitcher value={currentTab} index={2}>
                            <BuildingTrust />
                        </TabSwitcher>
                    </div>
                </Popup>
                : null}
        </>
    )
};

export default Concepts;
