import React, { useEffect } from 'react'
import { useAppSelector } from 'config/hooks';

import confetti1 from 'assets/images/confetti1.svg';
import confetti2 from 'assets/images/confetti2.svg';
import closeIcon from "assets/images/event_close.svg";
import TargetAchieved from 'assets/images/illustration_end_sim1.svg'
import TargetFailed from 'assets/images/illustration_target_failed.svg'
import PositiveFooter from 'assets/images/positive_response_footer.svg'
import NegativeFooter from 'assets/images/negative_response_footer.svg'
import Overlay from 'components/commonComponents/overlay/Overlay';
import { getString } from 'utils';


interface TargetAnalysisScreenProps {
  handleExit: () => void
}

const TargetAnalysisScreen = (props: TargetAnalysisScreenProps) => {

  const winTrust = useAppSelector(state => state.storylineData.winTrust)
  const overallTrust = useAppSelector((state) => state.globalData.overallTrust)

  const isTargetAchieved = overallTrust >= winTrust
  // const audio = isTargetAchieved ? new Audio("/confetti.mp3") : new Audio("/popup.mp3")

  useEffect(()=>{
    
    const timerFunc = setTimeout(() => {
        // audio.play()
      },2000)
    return () => clearTimeout(timerFunc)
    
  },[])

  return (
    <>
      <Overlay zIndex={2} />
      <div className='target-analysis-container'>
        {isTargetAchieved ? <>
          <img className='confetti1' src={confetti1} alt='confetti' />
          <img className='confetti2' src={confetti2} alt='confetti' />
        </> : null}
        <img
          src={closeIcon}
          alt="close"
          className='target-popup-close'
          onClick={props.handleExit}
        />
        <div className='target-greeting'>
          {isTargetAchieved ? getString('label_gen_congrats') : getString('label_target_failed')}
        </div>
        <div className='target-comment'>
          {isTargetAchieved ? getString('label_target_achieved_desc') : getString('label_target_failed_desc')}
        </div>
        <div className='ceo-name'>
          {getString('label_ceo_name_company')}
        </div>
        <img className='target-illustration' src={isTargetAchieved ? TargetAchieved : TargetFailed} alt='target-illustration' />
        <button className='close-popup-btn' onClick={props.handleExit}>{isTargetAchieved ? getString('label_gen_yay') : getString('label_gen_ok')}</button>
        <img className='target-footer' src={isTargetAchieved ? PositiveFooter : NegativeFooter} alt='footer-img' />
      </div>
    </>
  )
}

export default TargetAnalysisScreen
