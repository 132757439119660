import React, { useState, useEffect } from "react";

import { addClassToElement, removeClassFromElement } from "utils";
import Overlay from "components/commonComponents/overlay/Overlay";
import DashboardDefault from "components/dashboard/default/Default";
import TabSwitcher from "components/intro/IntroBody/TabSwitcher";
import "./default.scss";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";

const WalkthroughDefault = () => {
	const [currentStep, setCurrentStep] = useState(-1);
	const clickHandler = function (step: number) {
		setCurrentStep(step);
	};

	useEffect(() => {
		addClassToElement("dashboard-container", "pointer-events-none");
		setTimeout(function () {
			setCurrentStep(0);
		}, 2000);

		return function () {
			removeClassFromElement('dashboard-header', 'z-index-3')
			removeClassFromElement("dashboard-container", "pointer-events-none")
			removeClassFromElement("dashboard-your-team", "z-index-3")
			removeClassFromElement("dashboard-your-team", "z-index-1")
			removeClassFromElement('group-actions', 'z-index-1')
			removeClassFromElement('client-container', 'z-index-3', 'pointer-events-all')
			removeClassFromElement('powermap-btn', 'z-index-1')
			removeClassFromElement("locked-actions", 'z-index-1')
		}
	}, []);

	return (
		<div>
			{currentStep == -1 ? "" : <Overlay zIndex={2} />}
			<TabSwitcher index={0} value={currentStep}>
				<Step1 clickHandler={clickHandler} />
			</TabSwitcher>
			<TabSwitcher index={1} value={currentStep}>
				<Step2 clickHandler={clickHandler} />
			</TabSwitcher>
			<TabSwitcher index={2} value={currentStep}>
				<Step3 clickHandler={clickHandler} />
			</TabSwitcher>
			<TabSwitcher index={3} value={currentStep}>
				<Step4 clickHandler={clickHandler} />
			</TabSwitcher>
			<TabSwitcher index={4} value={currentStep}>
				<Step5 clickHandler={clickHandler} />
			</TabSwitcher>
		</div>
	);
};

export default WalkthroughDefault;
