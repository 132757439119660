import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'config/hooks'
import { actionCreators } from "store"
import { bindActionCreators } from "redux"

import DemoEndScreen from './DemoEndScreen'
import SimEndScreen from './SimEndScreen'


const SimEnd = () => {

  const isDemoStoryline = useAppSelector(state => state.storylineData.isDemoStoryline)
  const userLicenseId = useAppSelector(state => state.initUser.uliId)
  const isVAC = useAppSelector(state => state.storylineData.isVAC)
  const gameStatus = useAppSelector(state => state.initUser.status) // 2 if game is already completed, 1 if game is started
  const month = useAppSelector(state => state.globalData.month)
  const dispatch = useAppDispatch()
  const { setGameStatusToComplete, setReportData, exitDemoMode, resetDataFetched } = bindActionCreators(actionCreators, dispatch)
  
  const [gameEndType, setGameEndType] = useState(-1)

  useEffect(() => {
    if (isDemoStoryline == 1 && userLicenseId == 0) {
      setGameEndType(0)
    }
    else if (isDemoStoryline == 1 && userLicenseId !== 0) {
      setGameEndType(1)
    }
    else if (isVAC == 0) {
      setGameEndType(1)
    }
    else if (isVAC == 1) {
      // setGameEndType(2) handled from backend. endReportAvail flag
    }

    if (isDemoStoryline != 1 && userLicenseId != 0) {
      setGameStatusToComplete()
    }

    setReportData(month)

  }, [])


  const handleExitDemo = async () => {
    setReportData(month)
    await exitDemoMode()
    resetDataFetched()
  }

  return (
    <>
      {gameEndType == 0 ? <DemoEndScreen isDemoOngoing={false} gameStatus={gameStatus} handleExitDemo={handleExitDemo} /> : null}
      {gameEndType == 1 ? <SimEndScreen gameStatus={gameStatus} /> : null}
    </>
  )
}

export default SimEnd
