import React from "react";
import { getString } from "utils";


const ConceptIntro: React.FC = () => {

    return (
        <div className="cbody">
            <div className="tbody">
                <span className="bhead">{getString("label_nr_aboutsim_heading")}</span>
                <p className=" bbody" dangerouslySetInnerHTML={{ __html: getString("label_intro_about_ts_desc") }}></p>
            </div>
            <div className="tbody">
                <span className="bhead">{getString("label_intro_how_ts")}</span>
                <p className="bhead bbody"
                    dangerouslySetInnerHTML={{ __html: getString("label_intro_how_ts_desc") }}></p>

            </div>
        </div >
    )
}

export default ConceptIntro;
