import { useAppSelector } from 'config/hooks'
import { IClientCharacter } from 'interfaces/storyline'
import React, { useEffect, useState } from 'react'
import { getNetworkCharName, getString } from 'utils'
import eventIcon from "assets/images/history_event_icon.svg"
import TrustChangeIndicator from 'components/commonComponents/trustChangeIndicator/TrustChangeIndicator'
import { characterImages } from 'utils/characterImages'

interface ClientHistoryProps {
  characterData: IClientCharacter
}

const ClientHistory = (props: ClientHistoryProps) => {

  const history = useAppSelector(state => state.history)
  const { characterList, actionList } = useAppSelector(state => state.lists)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {

    const historyData = JSON.parse(JSON.stringify(history.data))  // Make deep copy and not shallow copy to prevent mutation of global state
    /*
      newData contains complete response of actions/events 
      in which the current Client is included.
    */
    const newData = historyData.filter((indHistory: any) => {
      let count = 0
      const individualHistory = indHistory
      individualHistory['user_game_history_details'].map((userHistory: any) => {
        if (userHistory['ghd_stc_id'] === props.characterData['stc_id']) {
          count++
        }
      })
      if (count == 1) return true
      return false
    })


    /*
      finalData contains responses of only the client whose History is viewed, 
      removing the responses of other characters. 
    */
    const finalData = newData.map((indData: any) => {
      const indUserData = indData['user_game_history_details'].filter((ele: any) => {
        if (ele['ghd_stc_id'] === props.characterData['stc_id']) {
          return true
        }
        return false
      })

      indData['user_game_history_details'] = indUserData
      return indData
    })

    setData(finalData)

  }, [history.data])

  return (
    <>
      <div className='individual-history'>
      <div className='ind-history-container'>
        {data.map((indData: any) => {

          let img = '', name = ''
          if (indData["gh_src_stc_id"]) {
            const ch = characterList[Number(indData["gh_src_stc_id"])]
            img = getString(ch["ch_image"])
            name = getString(ch["ch_name"]).split(" ")[0]
          }


          const userGameHistoryObj = indData['user_game_history_details'][0]
          let name_b = getString(characterList[Number(userGameHistoryObj["ghd_stc_id"])]["ch_name"])
          name_b = name_b ? name_b.split(" ")[0] : ''

          //get label name if any network revealed for the character. 
          const networkCharName = getNetworkCharName(userGameHistoryObj, characterList)
          const img_you = getString("img_you")
          return (
            < >
              <div className='client-history-card' key={indData['ghd_id']}>
                <div className='hcard-desc'>
                  <div className='hcard-title'>
                    <div className='title-day'>{getString("label_day")} {indData['gh_day']}</div>
                    <div className='title-action'>{indData['gh_sta_id'] ? getString(actionList[indData['gh_sta_id']]['act_name']) : getString(indData['gh_interaction_heading'])}</div>
                  </div>
                  <div className='hcard-body'>
                    {!indData["gh_src_stc_id"] ?
                      // check for event then different image  
                      indData["gh_ste_id"] != null ? <img className='hcard-img' src={eventIcon} /> :
                        <img className='hcard-img' src={characterImages(img_you)} />
                      : <div className='img-with-name'><img className='hcard-img' src={characterImages(img)} /> <span className='hcard-img-name'>{name}</span> </div>}

                    <div className='response-with-name'>
                      <div className='hcard-response'>
                        {userGameHistoryObj["ghd_influence"] == 1 ? "Indirectly impacted" :
                          userGameHistoryObj["ghd_detail"] != "bg_info" ?
                          getString(userGameHistoryObj['ghd_detail'], "", { "actorA": name, "actorB": name_b, "actorC": getString(networkCharName) }) : ''
                        }
                      </div>
                      {userGameHistoryObj["ghd_detail"] != "bg_info" ? <span className='hcard-response-name'>-&nbsp;{name_b}</span> : null}
                    </div>
                  </div>
                </div>

                <div className='history-impact'>
                  <div className='impact-you'>
                    <div className='impact-label'>{getString("label_character_y")}</div>
                    <div className='indicator-container'>
                      <TrustChangeIndicator
                        impactValue={!userGameHistoryObj["ghd_delta"] ? 0 : userGameHistoryObj["ghd_delta"]}
                        positiveColor='#84FCB4'
                        negativeColor='#FF7171'
                      />
                    </div>

                  </div>
                  <div className='impact-you'>
                    <div className='impact-label'>{getString("label_character_c")}</div>
                    <div className='indicator-container'>
                      <TrustChangeIndicator
                        impactValue={!userGameHistoryObj["ghd_comp_delta"] ? 0 : userGameHistoryObj["ghd_comp_delta"]}
                        positiveColor='#84FCB4'
                        negativeColor='#FF7171'
                      />
                    </div>
                  </div>

                </div>

              </div>
            </>
          )
        })}
      </div>
      </div>
    </>
  )
}

export default ClientHistory
