import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'config/hooks'
import { actionCreators } from "store";
import { bindActionCreators } from "redux"

import { addClassToElement, getString } from 'utils'
import timeOver from 'assets/images/timeEnd.svg'
import neutralFooter from 'assets/images/neutral_response_footer.svg'
import Overlay from 'components/commonComponents/overlay/Overlay'

interface DemoEndScreenProps {
  isDemoOngoing?: boolean
  gameStatus: number
  handleExitDemo: () => void
}

const DemoEndScreen = (props: DemoEndScreenProps) => {

  const [showDemoOverPopup, setShowDemoOverPopup] = useState(false)
  const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
  const simDays = useAppSelector(state => state.storylineData.simDays)
  const timer = useAppSelector(state => state.globalData.timer)
  const showExitDemoConfirmationPopup = useAppSelector(state => state.globalData.showExitDemoConfirmationPopup)
  
  const dispatch = useAppDispatch()
  const { setShowExitDemoConfirmationPopup }= bindActionCreators(actionCreators, dispatch)
  // const  audio = new Audio("/popup.mp3")
  
  useEffect(() => {
    if (!props.isDemoOngoing) {
      setShowDemoOverPopup(true)
      // audio.play()
    }
    if (props.gameStatus != 2) {

      if (userCurrentDay < simDays && timer == 0) {

        // setTimeout(function () {
        //   setShowTimeUpPopup(false)
        // }, 3000)
      }
    }

  }, [props.isDemoOngoing])

  const handleExit = () => {
    console.log(props.isDemoOngoing)
    if(props.isDemoOngoing) {
      setShowExitDemoConfirmationPopup(true)
      setShowDemoOverPopup(true)
      // audio.play()
    }
    else {
      props.handleExitDemo()
      setShowExitDemoConfirmationPopup(false)
    }
  }
  
  const setExitConfirmation = () => {
    setShowDemoOverPopup(false)
    setShowExitDemoConfirmationPopup(false)
  }

  return (
    <>
      {showDemoOverPopup ?
        <>
          <Overlay zIndex={8} />
          <div className='demo-over-container'>
            <div className='time-over-popup'>
              <img className='time-over-illustration' src={timeOver} alt='time-over' />
              <div className='time-over-desc'> {showExitDemoConfirmationPopup ? getString('label_ask_exit') : getString('label_demo_ended')} <br/> <span style={{width: '80%', fontSize: '1vw', fontFamily: 'Lato', lineHeight: '1vw', fontWeight: 600}}>{getString('label_warn_demo_return')}</span></div>
            </div>

            {showExitDemoConfirmationPopup ? (
              <div className='exit-confirmation-buttons'>
                <button className="btn-cancel" onClick={setExitConfirmation}>
                  {getString('label_gen_cancel')}
                </button>
                <button className="btn-exit" onClick={props.handleExitDemo}>
                  {getString('label_gen_yes')}
                </button>
              </div>) : null}
            <img className='t-footer' src={neutralFooter} alt='footer' />
          </div>
        </> : null
      } 
      <>
        <div className='demo-end-screen' style={{ zIndex: !props.isDemoOngoing ? 9 : 1 }}>
          <div className='demo-end-content'>
            {props.isDemoOngoing ? <><span>{getString('label_playing_demo')}</span> <br /> {getString('label_warn_demo_exit')}</>
              : <><span className='demo-end-text'>{getString('label_demo_ended')}</span> <br />{getString('label_end_thank_you_playing')}</>}
          </div>
          <button className="exit-demo-btn" onClick={handleExit}>
            {getString('label_end_exit_demo')}
          </button>
        </div>
      </>
    </>
  )
}

export default DemoEndScreen
