import React, { useState } from "react";

import logOut from "assets/images/intro/logout.svg";
import "./logOut.scss";
import { getString } from "utils";
import Popup from "../popup/Popup";
import { useAppSelector } from "config/hooks";

const IntroLogOut: React.FC = () => {

    const [showPopup, setShowPopup] = useState(true)
	const userEmail = useAppSelector(state => state.initUser.userEmail)
    const logoutType = useAppSelector(state => state.initUser.logoutType)

    const handleLogout = () => {
        setShowPopup(false)
    }

    const handleLogoutBtn = () => {
        window.location.assign(`${process.env.REACT_APP_BACKEND_URL}/user/logout`)
    }

    const handleClose = () => {
        setShowPopup(true)
    }
    return (
        <>
            <div className="intro-logout">
                <span className="logout-label">{userEmail}</span>
                <img
                    src={logOut}
                    alt="logout"
                    style={{ width: "2vw", cursor: "pointer" }}
                    onClick={handleLogout}
                />
            </div>
            {!showPopup ?
                <Popup handleClose={handleClose} height={"45vmin"}>
                    <div className="phead">
                        <p style={{ display: "inherit", alignItems: 'center' }}>
                            <img src={require(`assets/images/logout.svg`).default} style={{ width: "3vw", paddingRight: '0.5vw' }} />
                            <span>&nbsp;{getString("label_logout")}</span>
                        </p>
                    </div>
                    <div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "25vh", fontSize: "2vh", lineHeight: "2.4vh", justifyContent: "center", alignItems: "center", minHeight: "31vh" }}>
                        <p dangerouslySetInnerHTML={{ __html:logoutType == "RETURN" ? getString("label_ask_exit") : getString("label_logout_message") }} style={{ color: "#083880", fontSize: "2.6vh" }}></p>
                        <div className="lbuttons">
                            <button className="logout-btn" onClick={handleLogoutBtn}>{logoutType == "RETURN" ? getString("label_gen_return") : getString("label_logout_btn")}</button>
                            <button className="cancel" onClick={handleClose}>{getString("label_logout_cancel_btn")}</button>
                        </div>
                    </div>
                </Popup>
                : null}
        </>
    );
};

export default IntroLogOut;
