import { Dispatch } from "redux";
import actionConsts from "../constants";
import { store } from "store";
import { getMonth } from "utils";
import { IAction, IClientCharacter, IStringObject } from 'interfaces/storyline';

export const setMonth = () => {
	return function action(dispatch: Dispatch) {
		const currentMonth = getMonth(
			store.getState().initUser.userCurrentDay,
			store.getState().storylineData.monthInterval
		);
		dispatch({
			type: actionConsts.SET_MONTH,
			payload: currentMonth,
		});
	};
};

export const setShowWalkthrough = (currentDay: number, userTimer: number | null, gameStatus: number) => {
	return function action(dispatch: Dispatch) {
		let showWalkthrough = false
		if (currentDay <= 1 && userTimer != 0 && gameStatus != 2) {
			showWalkthrough = true
		}
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showWalkthrough: showWalkthrough }
		});
	};
}

export const setShowIndividualAction = (showIndividualAction: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showIndividualAction: showIndividualAction },
		});
	};
};


export const setIsGroupActionOpen = (isGroupActionOpen: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { isGroupActionOpen: isGroupActionOpen }
		})
	}
}

export const setShowReport = (showReport: number) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showReport: showReport }
		})
	}
}

export const setTimerEnd = (time: number) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { timer: time }
		})
	}
}


export const updateSelectedCharacterList = (charData: IClientCharacter, select: boolean) => {
	return function action(dispatch: Dispatch) {
		if (select) {
			dispatch({
				type: actionConsts.UPDATE_GLOBAL_DATA,
				payload: { selectedCharacterList: [...store.getState().globalData.selectedCharacterList, charData] },
			})
		}
		else {
			const list = store.getState().globalData.selectedCharacterList
			const newList = list.filter((ele: any) => ele['stc_id'] !== charData['stc_id'])
			dispatch({
				type: actionConsts.UPDATE_GLOBAL_DATA,
				payload: { selectedCharacterList: newList }
			})
		}
	}
}

export const resetSelectedCharacterList = () => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { selectedCharacterList: [] }
		})
	}
}

export const setActionResponseLoader = (showLoader: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showActionResponseLoader: showLoader }
		})
	}
}

export const setShowActionResponse = (showResponse: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showActionResponse: showResponse }
		})
	}
}

export const setCurrentActionData = (actionData: any) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { currentActionData: actionData }
		})
	}
}

export const setSelectedTeamMember = (charData: any) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { selectedTeamMemberData: charData }
		})
	}
}

export const resetSelectedTeamMember = () => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { selectedTeamMemberData: {} }
		})
	}
}

export const setShowActionOption = (show: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showActionOption: show }
		})
	}
}

export const setShowEvent = (show: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showEvent: show }
		})
	}
}

export const setShowProgressBarChange = (show: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showProgressBarChange: show }
		})
	}
}

export const resetGlobalData = () => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.RESET_GLOBAL_DATA
		})
	}
}

export const setShowlogoutPopup = (show: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showLogout: show }
		})
	}
}

export const setShowExitDemoConfirmationPopup = (show: boolean) => {
	return function action(dispatch: Dispatch) {
		dispatch({
			type: actionConsts.UPDATE_GLOBAL_DATA,
			payload: { showExitDemoConfirmationPopup: show }
		})
	}
}