/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";

import { useAppDispatch, useAppSelector } from "config/hooks";
import { IStringObject } from "interfaces/storyline";
import { getString } from "utils"
import { Radio } from '@mui/material';

import "./yourTeam.scss";
import { characterImages } from "utils/characterImages";


interface YourTeamProps {
	showName: boolean;
	showFirstName?: boolean;
	showRadioButton?: boolean;
	class_member_title?: string;
	class_member_image?: string;
	class_team_container?: string;
	class_member?: string;
	isYouShown: boolean;
	label_you: string;
	isSelected?: string | null;
	selectedClass?: string;
	onClickHandler?: (newValue: number, member: IStringObject) => void;
}

const YourTeam: React.FC<YourTeamProps> = (props: YourTeamProps) => {
	let teamCharacters = useAppSelector(state => state.storylineData.teamCharacters)
	teamCharacters = !teamCharacters ? [] : teamCharacters
	

	const handleClick =
		(index: number, member: IStringObject) =>
			(event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
				props.onClickHandler && props.onClickHandler(index, member);
			};

	let teamList = teamCharacters
	if(props.isYouShown){
		if(teamList.length==3)
			teamList.push({
				stc_id: '#',
				ch_name: getString("label_base_you"),
				ch_image: getString("img_you"),
			})
	}else{
		teamList = teamCharacters.filter((member: IStringObject) => {
			return member["ch_name"] != getString("label_base_you");
		});

	}
		// : teamCharacters.filter((member: IStringObject) => {
		// 	return member["ch_name"] != getString("label_base_you");
		// });

	const getImageClass = (member: IStringObject) => {
		let classes = props.class_member_image + " member-image "

		classes += props.isSelected ? props.isSelected == member["ch_name"] ? props.selectedClass : "" : ""
		return classes
	}

	return (
		<div className={`${props.class_team_container} your-team-container `}>
			{teamList.map((member: IStringObject, index: number) => {
				const imgUrl = getString(member["ch_image"])
				return (
					<div
						className={props.class_member + " team-member"}
						key={member["ch_name"]}
					>
						<img
							src={
								characterImages(imgUrl)
							}
							className={getImageClass(member)}
							alt={""}
							onClick={handleClick(index, member)}
						/>
						{props.showName && (
							<div
								className={
									props.class_member_title + " member-title"
								}
							>
								{props.showFirstName ? getString(member["ch_name"]).split(" ")[0]  : getString(member["ch_name"])}
							</div>
						)}

						{props.showRadioButton ?
							(<Radio
								className="radio"
								disableRipple
								checked={props.isSelected == member['ch_name']}
								name="radio-buttons"
								sx={{
									"& .MuiSvgIcon-root": {
										fontSize: 12,
									},
								}}
								onClick={handleClick(index, member)}
							/>)
							: null}

					</div>
				);
			})}
		</div>
	);
};

export default YourTeam;
