import React from "react";

import { Tab, Tabs } from "@mui/material";
import TabSwitcher from "components/intro/IntroBody/TabSwitcher";
import ClientProfile from "./ClientProfile";
import ClientHistory from "./ClientHistory";
import { IClientCharacter } from "interfaces/storyline";
import "./info.scss";
import { getString } from "utils";

interface ClientInfoProps {
	characterData: IClientCharacter
	isGameOver: boolean
}

const ClientInfo: React.FC<ClientInfoProps> = (props: ClientInfoProps) => {
	const [currentTab, setValue] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		// const target = event.target as Element;
		// target.classList.add("active")
		setValue(newValue);
	};


	return (
		<div className="info-tabs">
			<Tabs
				value={currentTab}
				classes={{ indicator: "indicator" }}
				className="tab-panel"
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
			>
				<Tab label={getString("label_profile")} className="tabHead" disableRipple />
				<Tab label={getString("label_history")} className="tabHead" disableRipple />
			</Tabs>

			<TabSwitcher value={currentTab} index={0}>
				<ClientProfile
					actionCost={1}
					characterData={props.characterData}
					isGameOver={props.isGameOver}
				/>
			</TabSwitcher>
			<TabSwitcher value={currentTab} index={1}>
				<ClientHistory characterData={props.characterData}/>
			</TabSwitcher>
		</div>
	);
};

export default ClientInfo;
