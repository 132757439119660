import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "config/hooks"
import { bindActionCreators } from "redux"
import * as actionCreators from "store/actionCreator"
import { Divider } from "@mui/material";

import HeaderDefault from "../header/Default";
import OrgChart from "../clientCharacters/OrgChart";
import GroupActions from "../groupActions/GroupActions";
import PowerMap from "../powermap/PowerMap";
import KnolskapeLogo from "components/commonComponents/knolskapeLogo/KnolskapeLogo";
import background from "assets/images/background.svg";
import YourTeam from "components/commonComponents/yourTeam/YourTeam";
import YourTeamDetails from "components/commonComponents/yourTeam/YourTeamDetails";
import Spinner from "components/commonComponents/loaders/Spinner"
import SimEnd from 'components/endScreens/SimEnd';
import WalkthroughDefault from 'components/walkthrough/Default'

import "./default.scss";
import LogOutPopup from "components/commonComponents/logOut/LogOutPopup";
import { getString } from "utils";

const DashboardDefault: React.FC = () => {

	// Minimize the re renders by downlifting use of global state
	const actionResponseLoader = useAppSelector((state) => state.globalData.showActionResponseLoader)
	const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
	const simDays = useAppSelector(state => state.storylineData.simDays)
	const isDataFetched = useAppSelector(state => state.isDataFetched)
	const selectedTeamMemberData = useAppSelector(state => state.globalData.selectedTeamMemberData)
	const showEvent = useAppSelector(state => state.globalData.showEvent)
	const timer = useAppSelector(state => state.globalData.timer)
	const gameStatus = useAppSelector(state => state.initUser.status)
	const showWalkthrough = useAppSelector(state => state.globalData.showWalkthrough)
	const showLogout = useAppSelector(state => state.globalData.showLogout)

	const [clicked, setClicked] = useState(false)
	const [isGameOver, setIsGameOver] = useState(false)

	const dispatch = useAppDispatch();
	const { setShowWalkthrough } = bindActionCreators(actionCreators, dispatch)

	useEffect(() => {
		if (isDataFetched >= 5 && (userCurrentDay >= simDays || timer == 0 || gameStatus == 2) && !isGameOver && Object.keys(selectedTeamMemberData).length === 0 && !showEvent) {
			if (showWalkthrough) {
				setShowWalkthrough(userCurrentDay, timer, gameStatus)
			}
			setIsGameOver(true)
		}
	})

	const renderYourTeamDetails = () => {
		return <YourTeamDetails close={() => setClicked(!clicked)} />
	}

	const handleYourTeamDetails = () => {
		setClicked(true)
	}

	return (
		<>
			<div
				id="dashboard-container"
				className="dashboard-container"
				style={{
					background: `url(${background}) no-repeat center center fixed`,
					backgroundSize: "cover",
				}}
			>
				<header id="dashboard-header" className="header">
					<HeaderDefault isGameOver={isGameOver} />
				</header>
				<div id='dashboard-body' className="dashboard-body">
					<GroupActions isGameOver={isGameOver} />
					<OrgChart isGameOver={isGameOver} />
					<PowerMap isGameOver={isGameOver} />
				</div>
				<div className="dashboard-footer">
					<KnolskapeLogo />
					<div
						id="dashboard-your-team"
						onClick={handleYourTeamDetails}
					>
						<div className="team-title">{getString("label_base_your_team")}</div>
						<YourTeam
							showName={true}
							isYouShown={false}
							label_you={getString("label_base_you")}
						/>
					</div>
					{clicked ? renderYourTeamDetails() : null}
				</div>
			</div>
			{actionResponseLoader ? <Spinner /> : ""}
			{isGameOver ? <SimEnd /> : null}
			{showWalkthrough && !isGameOver ? <WalkthroughDefault /> : null}
			{showLogout ? <LogOutPopup /> : null}

		</>
	);
};

export default DashboardDefault;
