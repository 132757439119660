import React, { Suspense } from "react";
import { IAction } from "interfaces/storyline";

interface ActionsBaseProps {
	actionId: number;
	actionDescription: string;
	actionName: string;
	actionCost: number;
	actionData: IAction;
	isGroupAction: boolean;
	handleExitGroupDescription: (arg1?: any) => void;
	handleExitCharacterPopup?: (arg1?: any) => void
}

const loadComponent = (staActionID: number) => {

	if (staActionID == 5) {
		// lobby
		const ActionDescriptionComponent = React.lazy(
			() =>
				import(
					"./actionDescription/ActionDescription1"
				)
		)

		return ActionDescriptionComponent;
	} else if (staActionID == 7 || staActionID === 9 || staActionID === 11 || staActionID === 12) {
		// seek advice, buyer role
		const ActionDescriptionComponent = React.lazy(
			() =>
				import(
					"./actionDescription/ActionDescription2"
				)
		);

		return ActionDescriptionComponent;
	} else {
		// default view with team member selection
		const ActionDescriptionComponent = React.lazy(
			() =>
				import(
					"./actionDescription/ActionDescription"
				)
		);

		return ActionDescriptionComponent;
	}
};

const ActionsBase: React.FC<ActionsBaseProps> = (
	props: ActionsBaseProps
) => {
	const ActionDescriptionComponent = loadComponent(props.actionData['sta_act_id']);
	return (
		<Suspense fallback={<div></div>}>
			<ActionDescriptionComponent
				actionId={props.actionId}
				actionDescription={props.actionDescription}
				actionName={props.actionName}
				actionData={props.actionData}
				actionCost={props.actionCost}
				handleExitGroupDescription={props.handleExitGroupDescription}
				handleExitCharacterPopup={props.handleExitCharacterPopup}
				isGroupAction={props.isGroupAction}
			/>
		</Suspense>
	);
};

export default ActionsBase;
