import React, { useEffect } from 'react'
import './videoPlayer.scss'

interface VideoPlayerProps {
  kalturaEntryId: string
  playerKey: string
  onVideoComplete: () => void
}

interface VideoPlayerComponentProps {
  kalturaVideoId: string
  propRef: string
  onVideoComplete: () => void
  playerHeight?: string
  playerWidth?: string
}

const VideoPlayer = (props: VideoPlayerProps) => {

  const playerReadyCallback = (playerID: string) => {

    const onUpdateProgress = (progress, is_completed = false) => console.log("onUpdateProgress", progress, is_completed)
    // const onCompletion = () => console.log("onCompletion")
    const progress = { progress: 0, is_completed: false }


    const kdp :any  = document.getElementById(playerID);
    kdp.kBind('playerReady', function () {

      if (progress.progress) {
        kdp.sendNotification("doSeek", parseFloat(progress.progress.toString()));
      }

      kdp.kBind('playerPaused', function () {
        const progress = kdp.evaluate('{video.player.currentTime}');
        const is_completed = kdp.triggeredEndDone;
        console.log("event: video paused");
        onUpdateProgress(progress, is_completed);
      });

      kdp.kBind('playerPlayEnd', function () {
        console.log("event: video end");
        const progress = kdp.evaluate('{video.player.currentTime}');
        // const is_completed = kdp.triggeredEndDone;
        onUpdateProgress(progress, true);
        props.onVideoComplete();
      });
    });

  };

  useEffect(() => {
    if (window.kWidget) {
      // window.mw.setConfig("EmbedPlayer.DisableContextMenu", true)
      window.kWidget.thumbEmbed({
        targetId: `${props.playerKey}-${props.kalturaEntryId}`,
        wid: '_2413672',
        uiconf_id: '42816162',
        entry_id: props.kalturaEntryId,
        flashvars: {
          streamerType: 'hdnetworkmanifest'
        },
        readyCallback: playerReadyCallback
      });
    }
  }, [props.kalturaEntryId]);

  return (
    <div
      id={`${props.playerKey}-${props.kalturaEntryId}`}
      style={{
        height: '100%',
        width: '100%'
      }}
    >
    </div>
  );
}

const VideoPlayerComponent = (props: VideoPlayerComponentProps) : JSX.Element => {


  // const renderLoader = () => {
  //   return (
  //     <div
  //       style={{
  //         height: props.playerHeight || '952px',
  //         width: props.playerWidth || '480px'
  //       }}
  //     >
  //       Loading...
  //     </div>
  //   );
  // }

  return (
    <div className="video-container">
      <div
        key={props.propRef}
        style={{
          height: props.playerHeight || '100%',
          width: props.playerWidth || '100%'
        }}
      >
        <VideoPlayer
          kalturaEntryId={props.kalturaVideoId}
          playerKey={props.propRef}
          onVideoComplete={props.onVideoComplete}
        />
      </div>
    </div>
  );
}

export default VideoPlayerComponent