import React, { useState } from "react";
import * as  jointjs from "jointjs"

import info from "assets/images/info.svg"
import tick from "assets/images/tick.svg"
import MenuListComposition from "components/commonComponents/dropdown";
import { CustSwitch, CustSlider, CustSliderNeg } from "components/commonComponents/customMUI"
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { useAppSelector } from 'config/hooks';
import { getString } from "utils";

const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 50,
        label: '2',
    },
    {
        value: 100,
        label: '3',
    },
]

interface IPowermapTool {
    draw: (from: any) => void
    showDesc: () => void
    isGameOver: boolean
}

const PowermapTool: React.FC<IPowermapTool> = (props: IPowermapTool) => {
    const updatedLinks = useAppSelector(state => state.userMap);
    // const gameStatus = useAppSelector(state => state.initUser.status)


    const [direction, setDirection] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [isBidirection, setBidirection] = useState(true)
    const [influence, setInfluence] = useState(1)
    const [isDrawn, setDrawn] = useState(false)
    const [from, setFrom] = useState("" as any)
    const [to, setTo] = useState("" as any)
    // const btnaudio = new Audio("/button_cta.mp3")



    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirection(event.target.checked)
        setInfluence(1)
        // true means negative, false means positive
    }

    const valuetext = (value: number) => {
        return `${value}°C`;
    }

    const valueLabelFormat = (value: number) => {
        return marks.findIndex((mark) => mark.value === value) + 1;
    }

    const handleFrom = (event: SelectChangeEvent) => {
        setFrom(event.target.value)
        setDrawn(false)
    };

    const handleTo = (event: SelectChangeEvent) => {
        setTo(event.target.value)
        const linkExits = getLink(event.target.value)
        setDisabled(false)
        setDrawn(linkExits)
    };

    const getLink = (target:string) =>{
        // check in usermap if link exists
        const arr = Object.values(updatedLinks)
        const len = arr.length
        for(let i =0; i <len; i++){
            const _link:any  = arr[i]
            
            if ((_link["ln_src"] == from && _link["ln_tar_id"] == target) || (_link["ln_src"] == target && _link["ln_tar_id"] == from)) {
                setBidirection(_link["ln_dir"] ? true: false)
                setDirection(_link["ln_col"] ? false: true)
                setInfluence(_link["ln_width"] as number)
                return true
            }
        }
        setBidirection(false)
        setDirection(false)
        setInfluence(1)
        return false
    }

    const handleDraw = () => {
        if(from == "" || to == ""){
            setDisabled(true)
            return
        }
        // btnaudio.play()
        props.draw({
            direction: direction ? "negative" : "positive",
            from: from,
            to: to,
            isBidirection: isBidirection,
            influence,
            instruction: "draw"
        })
        return setDrawn(!isDrawn)
    }

    const handleUpdate = () => {
        // btnaudio.play()
        props.draw({
            direction: direction ? "negative" : "positive",
            from: from,
            to: to,
            isBidirection: isBidirection,
            influence,
            instruction: "update"
        })

    }

    const handleRemove = () => {
        // btnaudio.play()
        props.draw({
            from: from,
            to: to,
            instruction: "remove"
        })
        return setDrawn(!isDrawn)
    }

    const handleInfluenceChange = (event: Event, value: number | number[], active: number) => {
        setInfluence(value as number)
    }

    return (
        <div className="tool-container">
            <div className="heading">
                <span>{getString("label_pm_draw_inf_lines")}</span>
                <img src={info} style={{ cursor: "pointer", width: "3vmin" }} onClick={() => props.showDesc()} /> {/** todo */}
            </div>
            <div className="step-1">
                &#8226;<span>{getString("label_pm_draw_inf_lines_desc")}</span>
                <div className="from">
                    <span className="text">{getString("label_pm_from")}:</span>
                    <MenuListComposition handleChange={handleFrom} />
                </div>
                <div className="to">
                    <span className="text">{getString("label_pm_to")}:</span>
                    <MenuListComposition handleChange={handleTo} removeValue={from}/>
                </div>
            </div>
            <div className="step-2">
                <span className="heading">&#8226; {getString("label_connection_desc")} : </span>
                <div className="influence">
                    <span className="text">{getString("label_influence_type")}:</span><br />
                    <div className="direction">
                        <span className="text">{!direction ? <strong>{getString("label_pm_positive")}</strong> : getString("label_pm_positive")}</span>
                        <CustSwitch {...{
                            inputProps: { 'aria-label': getString("label_pm_switch_dir") }
                        }}
                            checked={direction}
                            disableRipple={true}
                            onChange={handleSwitch} />
                        <span className="text">{direction ? <strong>{getString("label_pm_negative")}</strong> : getString("label_pm_negative")}</span>
                    </div>
                    <span className="text">{getString("lable_pm_influence_strength")}:</span><br />

                    {direction ? <CustSliderNeg
                        aria-label="Restricted values"
                        defaultValue={1}
                        valueLabelDisplay="auto"
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        onChange={handleInfluenceChange}
                        step={null}
                        marks={marks}
                        value={influence}
                        className="slider"
                    /> :
                        <CustSlider
                            aria-label="Restricted values"
                            defaultValue={1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={valueLabelFormat}
                            getAriaValueText={valuetext}
                            onChange={handleInfluenceChange}
                            step={null}
                            marks={marks}
                            value={influence}
                            className="slider"
                        />}
                    <div style={{ display: "inline-flex", paddingTop: "1vh" }}>
                        {isBidirection ?
                            <>
                                <span className="checked">
                                    <img className="arrow" src={tick} onClick={() => { setBidirection(false) }} />
                                </span>
                                <span className="text-check">{getString("label_pm_bidirectional")}</span>
                            </> :
                            <>
                                <span className="checkbox"
                                    onClick={() => { setBidirection(true) }} />
                                <span className="text">{getString("label_pm_bidirectional")}</span>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={`pm-buttons ${ props.isGameOver ? "disabled" : ""}`}>
                {isDrawn ?
                    <>&#8226;  < button className="draw" onClick={handleUpdate}>{getString("label_pm_update").toUpperCase()}</button>
                        <button className="remove" onClick={handleRemove}>{getString("label_pm_remobe").toUpperCase()}</button></>
                    :
                    <>&#8226;  < button className={`draw ${disabled ? "disabled": ""}`} onClick={handleDraw}>{disabled}{getString("label_pm_draw").toUpperCase()}</button></>
                }
            </div>
        </div >
    )
}

export default PowermapTool;