import { PathConnector } from "components/commonComponents/connectors/connectors";
import ClientProgressBar from "components/dashboard/clientCharacters/ProgressBar";
import { useAppSelector } from "config/hooks";
import React, { useEffect } from "react";
import { addClassToElement, getString, removeClassFromElement } from "utils";
import "./steps.scss";

interface IStep {
	clickHandler: (step: number) => void;
}

const Step2 = (props: IStep) => {
	const { height, width } = useAppSelector((state) => state.dimensions);

	useEffect(() => {
		addClassToElement("client-container", "z-index-3");
	}, []);

	const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
		props.clickHandler(0);
		removeClassFromElement("client-container", "z-index-3");
		addClassToElement("dashboard-header", "z-index-3")
	};

	const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
		removeClassFromElement("client-container", "z-index-3")
		props.clickHandler(2);
	};

	return (
		<>
			<PathConnector
				pathD={`M ${width / 5.4} ${height / 12} V ${height / 110} H ${width / 20
					} L ${width / 100} ${height / 110}`}
				class="s2-connector2 animate fadeIn"
				cx={width / 5.4}
				cy={height / 12}
				r={height / 300}
			/>
			<div className="s2-strust animate fadeIn">
				<div className="s2-strust-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_stakeholder_trust') }}></div>
					<div className="description">
						<span className="d-text" dangerouslySetInnerHTML={{ __html: getString('label_wt_stakeholder_trust_1') }}>
						</span>
						<ClientProgressBar
							label={getString("label_character_y")}
							reverse={false}
							value={20}
							background_color="rgba(30, 39, 52, 0.06)"
						/>
						<ClientProgressBar
							label={getString("label_character_c")}
							reverse={true}
							value={80}
							background_color="rgba(30, 39, 52, 0.06)"
						/>
					</div>
				</div>
				<div className="s2-navigation-buttons animate fadeIn">
					<button className="s2-prev" onClick={handlePrevious}>
						{getString('label_gen_previous')}
					</button>
					<button className="s2-next" onClick={handleNext}>
						{getString('label_gen_next')}
					</button>
				</div>
			</div>
		</>
	);
};

export default Step2;
