import React from 'react'
import intro_loader from "assets/loading_big.gif"

const KnolskapeLoader = () => {
  return (
    <>
      <div style={{ marginTop: "40vh", marginLeft: "45vw" }}>
        <iframe src={intro_loader} width="180" height="180" frameBorder="0"
          className={"giphy-embed "} allowFullScreen />
      </div>
    </>
  )
}

export default KnolskapeLoader
