import React from "react";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabSwitcher: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value != index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value == index && (
                <>{children}</>
            )}
        </div>
    )
}

export default TabSwitcher;
