import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { CustTableRow } from "components/commonComponents/customMUI"
import { getString } from "utils"
import React from "react"

interface ILeaderboardTable {
    rows: any[]
}

const leaderboardTable: React.FC<ILeaderboardTable> = (props: ILeaderboardTable) => {
    const { rows } = props

    return (
        <Table aria-label="simple table" sx={{ width: "95%" }}>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ borderBottom: "none", color: "#0D54BD", fontSize: "2vh", fontWeight: "bold" }}>{getString("label_base_lb_pos")}</TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none", color: "#0D54BD", fontSize: "2vh", fontWeight: "bold" }}>{getString("label_username")}</TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none", color: "#0D54BD", fontSize: "2vh", fontWeight: "bold" }}>{getString("label_gen_days")}</TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none", color: "#0D54BD", fontSize: "2vh", fontWeight: "bold" }}>{getString("label_base_lb_ytrust")}</TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none", color: "#0D54BD", fontSize: "2vh", fontWeight: "bold" }}>{getString("label_base_lb_roleaccuracy")}</TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none", color: "#0D54BD", fontSize: "2vh", fontWeight: "bold" }}>{getString("report_comp_trust")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row: any) => (
                    <CustTableRow
                        key={row.id}
                        // hover={true}
                        sx={row.username == getString('label_base_you') ? { backgroundColor: "#0D54BD" } : {}}
                    >
                        <TableCell component="th" scope="row" sx={row.username == getString('label_base_you') ? { borderBottom: "none", color: "#fff", borderTopLeftRadius: "1.2vw", borderBottomLeftRadius: "1.2vw" } : { borderBottom: "none", color: "#0D54BD" }}>{row.position}</TableCell>
                        <TableCell align="left" sx={row.username == getString('label_base_you') ? { borderBottom: "none", color: "#fff" } : { borderBottom: "none", color: "#0D54BD" }}>{row.username}</TableCell>
                        <TableCell align="left" sx={row.username == getString('label_base_you') ? { borderBottom: "none", color: "#fff" } : { borderBottom: "none", color: "#0D54BD" }}>{row.days}</TableCell>
                        <TableCell align="left" sx={row.username == getString('label_base_you') ? { borderBottom: "none", color: "#fff" } : { borderBottom: "none", color: "#0D54BD" }}>{row.trust}</TableCell>
                        <TableCell align="left" sx={row.username == getString('label_base_you') ? { borderBottom: "none", color: "#fff" } : { borderBottom: "none", color: "#0D54BD" }}>{row.accuracy}</TableCell>
                        <TableCell align="left" sx={row.username == getString('label_base_you') ? { borderBottom: "none", color: "#fff", borderTopRightRadius: "1.2vw", borderBottomRightRadius: "1.2vw" } : { borderBottom: "none", color: "#0D54BD" }}>{row.ctrust}</TableCell>
                    </CustTableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default leaderboardTable