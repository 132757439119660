import React, { useEffect, useState } from "react";

import "./leaderboard.scss";
import Popup from "components/commonComponents/popup/Popup"

import { useAppDispatch, useAppSelector } from "config/hooks";
import { bindActionCreators } from "redux"
import * as actionCreators from "store/actionCreator"
import { getString } from "utils";
import LeaderboardTable from "./leaderboardTable"

interface ILeaderBoard {
    close: () => void
}

const Leaderboard: React.FC<ILeaderBoard> = (props: ILeaderBoard) => {

    const [showComponent, setShowComponent] = useState(true)
    const [buttonText, setButtonText] = useState(getString("label_base_refresh"))
    const [error, setErrormsg] = useState("")
    const [rows, setRows] = useState([])
    // redux state, action, dispatchers
    const { getLeaderboard } = bindActionCreators(actionCreators, useAppDispatch())
    const leaderboard = useAppSelector(state => state.leaderboard)
    const lboard: any[] = Object.values(leaderboard)
    const { userId } = useAppSelector(state => state.initUser)
    // const  audio = new Audio("/popup.mp3")

    useEffect(() => {
        // audio.play()
        getLeaderboard()
    }, [])

    useEffect(() => {
        renderData()
    }, [leaderboard])

    function createData(
        position: string,
        username: string,
        days: number,
        trust: number,
        accuracy: number,
        ctrust: number
    ) {
        return { position, username, days, trust, accuracy, ctrust };
    }

    const handleClose = () => {
        setShowComponent(false)
        props.close()
    }

    const handleRefresh = async() => {
        setErrormsg("")
        setButtonText(`${getString("label_leaderboard_refreshing")}`)
        try{
            await getLeaderboard()
        }catch(err){
            setErrormsg("Oops! There seems to be a problem. Please try again ")
            setButtonText(getString("label_base_refresh"))
        }
        renderData()
    }

    const renderData = () => {
        const data: any = []

        if (lboard.length != 0) {
            let prevScore = lboard[0].rankingPercent, count = 1, pos = 1

            for (const userItem of lboard) {
                if (userItem.rankingPercent < prevScore) {
                    pos = count;
                    prevScore = userItem.rankingPercent;
                }
                userItem.position = '#' + pos;
                if (Number(userItem.id) === Number(userId)) {
                    userItem.user = getString('label_base_you');
                }
                count++;
            }
            for (const key in lboard) {
                data.push(createData(lboard[key].position, lboard[key].user, lboard[key].day, Math.round(lboard[key].trust * 100) / 100, lboard[key].roleAccuracy, Math.round(lboard[key].trust_comp * 100) / 100))
            }
        }
        setRows(data)
        setButtonText(getString("label_base_refresh"))
    }

    return (
        <>
            {showComponent ? <Popup handleClose={handleClose}>
                <div className="phead">
                    <div style={{ display: "contents" }}>
                        <div className="metric-popup-icon">
                            <img src={require(`assets/images/leaderboard.svg`).default} style={{ width: "2vw" }} />
                        </div>
                        <span>&nbsp;{getString("label_report_leaderboard")}</span>
                    </div>
                </div>
                <div className="curved-header"></div>
                <div className="pbody" style={{ minHeight: "34vh", minWidth: "70vw" }}>
                    <div className="tbuttons lbutton">
                        <div className="error">{error}</div>
                        <button className="next" onClick={handleRefresh}>{buttonText}</button>
                    </div>
                    <LeaderboardTable rows={rows} />
                </div>
            </Popup>
                : null}
        </>
    )
};

export default Leaderboard;
