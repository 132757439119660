import React, { useEffect, useState } from "react";

import "./yourTeam.scss";
import Popup from "components/commonComponents/popup/Popup"

import ActorTabs from "components/intro/IntroBody/ActorTabs";
import "../../intro/IntroBody/body.scss"
import { getString } from "utils";

interface IYourTeamDetails {
    close: () => void
}

const YourTeamDetails: React.FC<IYourTeamDetails> = (props: IYourTeamDetails) => {

    const [showComponent, setShowComponent] = useState(true)
    // const  audio = new Audio("/popup.mp3")
	
	// useEffect(()=>{
	// 	audio.play()
	// },[])
    
    const handleClose = () => {
        setShowComponent(false)
        props.close()
    }

    return (
        <>
            {showComponent ?
                <Popup handleClose={handleClose}>
                    <div className="phead">
                        <p style={{ display: "inherit" }}>
                            <img src={require(`assets/images/Meeting.svg`).default} style={{ width: "3vw" }} />
                            <span>&nbsp;{getString("label_base_your_team")}</span>
                        </p>
                    </div>
                    <div className="curved-header"></div>
                    <div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "50vh", fontSize: "2vh", lineHeight: "2.4vh", borderRadius: '0px', borderBottomLeftRadius: '0.7vw', borderBottomRightRadius: '0.7vw' }}>
                        <ActorTabs />
                    </div>
                </Popup>
                : null}
        </>
    )
};

export default YourTeamDetails;
