
const actions = {
	STORYLINE: "storyline",
	INIT: "init",
	HISTORY: "history",
	USERMAP: "usermap",
	ISDATAFETCHED: "isDataFetched",
	RESET_DATA_FETCHED: "resetDataFetched",
	WINDOWRESIZE: "windowresize",
	SHOWINTROPOWERMAP: "showintropowermap",
	SHOWPOWERMAPLOADER: "showpowermaploader",
	SHOWINTROBUTTONS: "showintrobuttons",
	ACTORVISITED: "actorvisited",
	SHOWDASHPOWERMAP: "showdashpowermap",
	SETPOWERMAPLOCATIONS: "powermaplocations",
	UPDATEPOWERMAPLINKS: "updatepowermaplinks",
	LEADERBOARD: "leaderboard",
	PERFORMACTION: "performaction",
	SHOWACTIONRESPONSELOADER: "showactionresponseloader",
	HIDEACTIONRESPONSELOADER: "hideactionresponseloader",
	SAVE_SELECTED_ACTION_ID: "saveselectedactionid",
	SAVE_SELECTED_ACTOR_ID: "saveselectedactorid",
	UPDATE_CURRENT_DAY: "updatecurrentday",
	UPDATE_USER_DATA: "updateuserdata",
	UPDATE_STORYLINE_DATA: "updatestorylinedata",
	SET_BG_READ: "setbgread",
	SET_LEVEL2_BG_READ: "setlevel2bgread",
	SET_MONTH: "setmonth",
	UPDATE_GLOBAL_DATA: "updateglobaldata",
	SET_ACTION_RESPONSE_LOADER: "setactionresponseloader",
	LISTS: "lists",
	UPDATE_HISTORY: "updatehistory",
	UPDATE_CURRENT_ACTION_RESPONSE: "updatecurrentactionresponse",
	UPDATE_PRECHECK_RESPONSE: "updateprecheckresponse",
	SHOWPOPUP: "showpopup",
	ACTION_OPTION_RESPONSE: "actionoptionresponse",
	ADD_EVENT_DATA: "addeventdata",
	REMOVE_EVENT_DATA: "removeeventdata",
	SET_SHOW_WALKTHROUGH: "setshowwalkthrough",
	REPORTPM: "reportpm",
	REPORTPMCACHED: "reportpmcached",
	REPORTANALYSIS: "reportanalysis",
	RESET_GLOBAL_DATA: "resetglobaldata",
	SET_VIDEO_COMPLETED: "setvideocompleted",
	SHOW_EXIT_DEMO_CONFIRMATION_POPUP: "showexitdemoconfirmationpopup",
	POWERMAPTIP: "powermaptip",
	UPDATE_CHARACTER_LISTS: "updatecharacterlists",
	MOVEDCHARACTERS:"movedcharacters"
};
export default actions