import { PathConnector } from "components/commonComponents/connectors/connectors";
import React, { useEffect } from "react";
import "./steps.scss";

import { useAppDispatch, useAppSelector } from "config/hooks";
import { addClassToElement, getString, removeClassFromElement } from "utils";

interface IStep {
	clickHandler: (step: number) => void;
}

const Step1 = (props: IStep) => {
	const { height, width } = useAppSelector((state) => state.dimensions);

	useEffect(() => {
		addClassToElement("dashboard-header", "z-index-3")
	}, []);

	const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
		removeClassFromElement("dashboard-header", "z-index-3")
		props.clickHandler(1);
	};

	return (
		<>
			<div className="box-trust-meter animate-box animateBorderWidth"></div>
			<div className="box-timer animate-box animateBorderWidth" style={{ animationDelay: '2s'}}></div>
			<div className="box-others animate-box animateBorderWidth" style={{ animationDelay: '4s'}}></div>
			<PathConnector
				pathD={`M ${width / 50} ${height / 15} V ${height / 110} H ${
					width / 4
				} L ${width / 4} ${height / 110}`}
				class="s1-connector1 animate fadeIn"
				cx={width / 50}
				cy={height / 15}
				r={height / 300}
			/>
			<div className="s1-meter animate fadeIn">
				<div className="s1-meter-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_trust_meter') }}></div>
					<div className="description" dangerouslySetInnerHTML={{ __html: getString('label_wt_tm_1') }}>
					</div>
				</div>
			</div>
			<PathConnector
				pathD={`M ${width / 50} ${height / 15} V ${height / 110} H ${
					width / 20
				} L ${width / 20} ${height / 110}`}
				class="s1-connector2 animate fadeIn"
				cx={width / 50}
				cy={height / 15}
				r={height / 300}
			/>
			<div className="s1-timer animate fadeIn">
				<div className="s1-timer-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_timer_days') }}></div>
					<div className="description" dangerouslySetInnerHTML={{ __html: getString('label_wt_td_1') }}>

					</div>
				</div>
			</div>
			<PathConnector
				pathD={`M ${width / 6} ${height / 15} V ${height / 110} H ${
					width / 200
				} L ${width / 200} ${height / 110}`}
				class="s1-connector3 animate fadeIn"
				cx={width / 6}
				cy={height / 15}
				r={height / 300}
			/>
			<div className="s1-metric animate fadeIn">
				<div className="s1-metric-content">
					<div className="title" dangerouslySetInnerHTML={{ __html: getString('label_wt_other_tabs') }} ></div>
					<div className="description" dangerouslySetInnerHTML={{ __html: getString('label_wt_ot_1') }} >
					</div>
				</div>
				<div className="s1-navigation-buttons animate fadeIn">
				<button className="s1-next" onClick={handleNext}>
						{getString('label_gen_next')}
				</button>
			</div>
			</div>
		</>
	);
};

export default Step1;
