/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";

import "./metricIcon.scss";
import UserHistory from "components/stats/history/UserHistory";
import Leaderboard from "components/stats/leaderboard/Leaderboard";
import MonthlyReport from "components/stats/reports/monthlyReport";
import { getString } from "utils";
import { characterImages } from "utils/characterImages";


interface MetricIconProps {
	label: string;
	icon: string;
	iconClicked?: number
	isDisabled?: number
}

const MetricIcon: React.FC<MetricIconProps> = (props: MetricIconProps) => {

	const [clicked, setClicked] = useState(false)

	const onclickHandler = () => {
		setClicked(true)
	}

	const renderComponent = () => {
		switch (props.icon) {
			case "history": {
				return <UserHistory close={() => setClicked(false)} />
			}
			case "leaderboard": {
				return <Leaderboard close={() => setClicked(false)} />
			}
			case "reports": {
				return <MonthlyReport close={() => setClicked(false)} />
			}
			default: return null
		}
	}

	return (
		<>
			<div className={`icon-container ${props.isDisabled == 0 ? "report_lock" : ""}`} onClick={onclickHandler}>
				<div className="metric-icon" style={{background: `${props.isDisabled == 0 ? "white" : "rgba(255, 255, 255, 0.06)"}`}}>
					<img
						src={characterImages(props.icon)}
						style={{ width: "1.5vw" }}
					/>
				</div>
				<div className="metric-label">{props.label}</div>
				<div className="tooltiptext">{getString("label_report_locked_desc")}</div>
			</div>
			{clicked ? renderComponent() : null}
			{props.iconClicked == 2 ? renderComponent() : null}
		</>
	);
};

export default MetricIcon;
