import Alex_Panakal from "assets/images/characters/Alex_Panakal.png";
import Andrea_Reyes from "assets/images/characters/Andrea_Reyes.png";
import Anju_Menon from "assets/images/characters/Anju_Menon.png";
import Anup_Valsan from "assets/images/characters/Anup_Valsan.png";
import Barbara_Sika from "assets/images/characters/Barbara_Sika.png";
import Brandon_White from "assets/images/characters/Brandon_White.png";
import David_Foster from "assets/images/characters/David_Foster.png";
import Edward_Young from "assets/images/characters/Edward_Young.png";
import Farzan_Khan from "assets/images/characters/Farzan_Khan.png";
import Ganesh_Kulkarni from "assets/images/characters/Ganesh_Kulkarni.png";
import Geeta_Pathak from "assets/images/characters/Geeta_Pathak.png";
import James_Hughes from "assets/images/characters/James_Hughes.png";
import Jennifer_Hall from "assets/images/characters/Jennifer_Hall.png";
import Liam_Cooper from "assets/images/characters/Liam_Cooper.png";
import Liam_Johnson from "assets/images/characters//Liam_Johnson.png";
import Malishka_Mathur from "assets/images/characters/Malishka_Mathur.png";
import Maria_Ribeiro from "assets/images/characters/Maria_Ribeiro.png";
import Martha_Walker from "assets/images/characters/Martha_Walker.png";
import Namita_Narendar from "assets/images/characters/Namita_Narendar.png";
import Neethi_Venkatesh from "assets/images/characters/Neethi_Venkatesh.png";
import Neil_Walker from "assets/images/characters/Neil_Walker.png";
import Suresh_Chang from "assets/images/characters/Suresh_Chang.png";
import Ludwig_Harvey from "assets/images/characters/Ludwig_Harvey.png";
import You from "assets/images/characters/You.png";
import You_tr from "assets/images/characters/You_tr.png";
import Email from "assets/images/Email.svg"
import Email_clicked from "assets/images/Email_clicked.svg";
import InviteForDemo from "assets/images/InviteForDemo.svg";
import InviteForDemo_clicked from "assets/images/InviteForDemo_clicked.svg";
import InviteForWorkshop from "assets/images/InviteForWorkshop.svg";
import InviteForWorkshop_clicked from "assets/images/InviteForWorkshop_clicked.svg";
import BuildRapport from "assets/images/BuildRapport.svg";
import BuildRapport_clicked from "assets/images/BuildRapport_clicked.svg";
import Lobby from "assets/images/Lobby.svg";
import Lobby_active from "assets/images/Lobby_active.svg";
import Lobby_clicked from "assets/images/Lobby_clicked.svg";
import ProposeChange from "assets/images/ProposeChange.svg";
import ProposeChange_clicked from "assets/images/ProposeChange_clicked.svg";
import AskForBuyer from "assets/images/AskForBuyer.svg";
import AskForBuyer_clicked from "assets/images/AskForBuyer_clicked.svg";
import AskForNetwork from "assets/images/AskForNetwork.svg";
import AskForNetwork_clicked from "assets/images/AskForNetwork_clicked.svg";
import Meeting from "assets/images/Meeting.svg";
import Meeting_clicked from "assets/images/Meeting_clicked.svg";
import Presentation from "assets/images/Presentation.svg";
import Presentation_clicked from "assets/images/Presentation_clicked.svg";
import SeekAdvice from "assets/images/SeekAdvice.svg";
import SeekAdvice_clicked from "assets/images/SeekAdvice_clicked.svg";
import StatusReport from "assets/images/StatusReport.svg";
import StatusReport_clicked from "assets/images/StatusReport_clicked.svg";
import history from "assets/images/history.svg";
import reports from "assets/images/reports.svg";
import report_lock from "assets/images/report_lock.svg";
import leaderboard from "assets/images/leaderboard.svg";
import concepts from "assets/images/concepts.svg";
import objectives from "assets/images/objectives.svg";

export const characterImages = (imgStr) => {
	const images = {
		Alex_Panakal,
		Andrea_Reyes,
		Anju_Menon,
		Anup_Valsan,
		Barbara_Sika,
		Brandon_White,
		David_Foster,
		Edward_Young,
		Farzan_Khan,
		Ganesh_Kulkarni,
		Geeta_Pathak,
		James_Hughes,
		Jennifer_Hall,
		Liam_Cooper,
		Liam_Johnson,
		Malishka_Mathur,
		Maria_Ribeiro,
		Martha_Walker,
		Namita_Narendar,
		Neethi_Venkatesh,
		Neil_Walker,
		Suresh_Chang,
		You,
		You_tr,
		Email,
		Email_clicked,
		InviteForDemo,
		InviteForDemo_clicked,
		InviteForWorkshop,
		InviteForWorkshop_clicked,
		BuildRapport,
		BuildRapport_clicked,
		Lobby,
		Lobby_active,
		Lobby_clicked,
		ProposeChange,
		ProposeChange_clicked,
		AskForBuyer,
		AskForBuyer_clicked,
		AskForNetwork,
		AskForNetwork_clicked,
		Meeting,
		Meeting_clicked,
		Presentation,
		Presentation_clicked,
		SeekAdvice,
		SeekAdvice_clicked,
		StatusReport,
		StatusReport_clicked,
		Ludwig_Harvey,
		history,
		reports,
		report_lock,
		leaderboard,
		concepts,
		objectives,
	};

	const key = imgStr?.split(".")[0]
	if(key) return images[key]
	else return ""
};
