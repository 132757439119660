import { combineReducers } from "redux"
// import IntroReducer from "./intro/reducers"
import {
	storylineData,
	initUser,
	history,
	userMap,
	leaderboard,
	isDataFetched,
	showLoginPopup,
	setDimensions,
	setMTYFlags,
	setDashFlags,
	actionResponseLoader,
	globalData,
	setLists,
	currentActionResponse,
	precheckResponse,
	actionOptionsResponse,
	eventData,
	reportPowermapData,
	reportAnalysis
} from "./reducers";
// import DashboardReducer from "./dashboard/reducers"

const reducers = combineReducers({
	storylineData,
	initUser,
	history,
	userMap,
	leaderboard,
	isDataFetched,
	dimensions: setDimensions,
	mty: setMTYFlags,
	dash: setDashFlags,
	actionResponseLoader,
	globalData,
	lists: setLists,
	currentActionResponse,
	precheckResponse,
	showLoginPopup: showLoginPopup,
	actionOptionsResponse,
	eventData,
	reportPm: reportPowermapData,
	reportAnalysis,
});

export default reducers
