import { useAppDispatch, useAppSelector } from "config/hooks";
import React, { useState, useRef, useEffect } from "react";
import { actionCreators } from "store";
import { bindActionCreators } from "redux"
// import { initialiseFirebase, syncFirebase } from "utils/timer"
import "./timer.scss"

interface TimerComponentProps {
	isGameOver: boolean
}

let timerCallback: NodeJS.Timeout

const TimerComponent: React.FC<TimerComponentProps> = (props: TimerComponentProps) => {

	// const [firebaseInitialized, setfirebaseInitialized] = useState(false)
	const userTimer = useAppSelector(state => state.initUser.userTimer)
	const simTime = useAppSelector(state => state.storylineData.simTime)
	const [timeLeft, setTimeLeft] = useState(userTimer != null ? userTimer : simTime)
	const dispatch = useAppDispatch()
	const { updateTimerInDB, setTimerEnd } = bindActionCreators(actionCreators, dispatch)

	const timeRef = useRef(timeLeft)
	timeRef.current = timeLeft

	useEffect(() => {
		if (props.isGameOver) {
			// update the exact time left in DB (precise to 1 sec)
			updateTimerInDB(timeLeft)
			stopTimer()
		}
		else {
			startTimer();
		}
		return () => clearInterval(timerCallback)
	}, [props.isGameOver]);


	const startTimer = () => {
		// initialise stuff
		// const isInitialised = initialiseFirebase()
		// setfirebaseInitialized(isInitialised)

		if (timerCallback && props.isGameOver) {
			stopTimer();
		}

		timerCallback = setInterval(() => {
			if (timeRef.current > 0) {
				const newTime = timeRef.current - 1
				setTimeLeft(newTime)

				if (newTime % 20 == 0) {
					// if (firebaseInitialized) {
					// 	syncFirebase(newTime)
					// }
					updateTimerInDB(newTime)
				}
			} else {
				updateTimerInDB(0)
				stopTimer(); // execute at -1 sec
			}
		}, 1000);

	};

	const stopTimer = () => {
		// push to global state now when 0
		setTimerEnd(0)
		clearInterval(timerCallback);
	};

	// Set time in min:sec format
	let minute = ("0" + Math.floor(timeLeft / 60)).slice(-2);
	let second = ("0" + Math.floor(timeLeft % 60)).slice(-2);

	if (timeLeft <= 0) {
		minute = "00";
		second = "00";
	}

	return (
		<>
			<span className="clock">
				{`${minute} : ${second}`}
			</span >
		</>
	)
}

export default TimerComponent;
