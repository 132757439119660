import actionConsts from "../constants";

const globalData = (state: any, data: any): any => {
  const { payload, type } = data;
  switch (type) {
    case actionConsts.SET_MONTH: {
      return { ...state, month: payload }
    }
    case actionConsts.UPDATE_GLOBAL_DATA: {
      return { ...state, ...{ ...payload } }
    }
    case actionConsts.RESET_GLOBAL_DATA: {
      return { month: 0, timer: 3600, isGroupActionOpen: false, showWalkthrough: false, showEvent: false, showReport: 0, showIndividualAction: true, selectedCharacterList: [], selectedTeamMemberData: {}, currentActionData: {}, showProgressBarChange: false, showActionResponse: false, showActionOption: false, showActionResponseLoader: false, overallTrust: -1, overallTrustComp: -1, deltaOverallTrust: -1, deltaOverallTrustComp: -1 }
    }
    default:
      return state ? state : { month: 0, timer: 3600, isGroupActionOpen: false, showWalkthrough: false, showLogout: false, showEvent: false, showReport: 0, showIndividualAction: true, selectedCharacterList: [], selectedTeamMemberData: {}, currentActionData: {}, showProgressBarChange: false, showActionResponse: false, showActionOption: false, showActionResponseLoader: false, overallTrust: -1, overallTrustComp: -1, deltaOverallTrust: -1, deltaOverallTrustComp: -1, showExitDemoConfirmationPopup: false }
  }
}

export { globalData }