import React from "react";
import { getString } from "utils";
import "./repsMap.scss"
import Icon from "assets/images/icon.svg"

interface IRepsMap {
    showIcon?: boolean
    handleClick?: () => void
}

const RepsMap: React.FC<IRepsMap> = (props: IRepsMap) => {

    return (
        <div className="reps-container">
            <div className="scholar">
                {getString("label_intro_scholar")}
                <div className="desc" dangerouslySetInnerHTML={{ __html: getString("label_intro_scholar_desc") }}>
                </div>
            </div>
            <div className="relator">
                <p>{getString("label_intro_relator")}
                    {props.showIcon ? <img src={Icon} onClick={() => props.handleClick ? props.handleClick() : null} style={{ cursor: "pointer", width: "1.8vmax" }} /> : null}
                </p>
                <div className="desc" dangerouslySetInnerHTML={{ __html: getString("label_intro_relator_desc") }}>
                </div>
            </div>
            <div className="executor">
                {getString("label_intro_executor")}
                <div className="desc" dangerouslySetInnerHTML={{ __html: getString("label_intro_executor_desc") }}>
                </div>
            </div>
            <div className="promoter">
                {getString("label_intro_promoter")}
                <div className="desc" dangerouslySetInnerHTML={{ __html: getString("label_intro_promoter_desc") }}>
                </div>
            </div>


        </div >
    )
}

export default RepsMap;
