/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";
import Concepts from "components/stats/concepts/Concepts";
import Objectives from "components/stats/objectives/Objectives";

import "./introIcon.scss";
import { characterImages } from "utils/characterImages";

interface IntroIconProps {
	label: string;
	icon: string;
}

const IntroIcon: React.FC<IntroIconProps> = (props: IntroIconProps) => {

	const [clicked, setClicked] = useState(false)

	const onclickHandler = () => {
		setClicked(true)
	}

	const renderComponent = () => {
		switch (props.icon) {
			case "concepts": {
				return <Concepts close={() => setClicked(false)} />
			}
			case "objectives": {
				return <Objectives close={() => setClicked(false)} />
			}
			default: return null
		}
	}

	return (
		<>
			<div className="intro-icon-container" onClick={onclickHandler}>
				<img
					src={characterImages(props.icon)}
					alt={props.label}
					style={{ width: "1.1vw" }}
				/>
				<span className="icon-label">{props.label}</span>
			</div>
			{clicked ? renderComponent() : null}
		</>
	);
};

export default IntroIcon;
