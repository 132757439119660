import React from "react";

import "./flashToolTip.scss";
import FlashIcon from "assets/images/flashIcon.svg";

interface FlashToolTipProps {
	toolTipText: string;
}

const FlashToolTip = (props: FlashToolTipProps) => {
	return (
		<div className="tooltip-container">
			<img
				className="flash-icon"
				src={FlashIcon}
				alt="flash"
				style={{ marginRight: "2%" }}
			/>
			<span className="tooltip-text"
				dangerouslySetInnerHTML={{ __html: props.toolTipText }}
			></span>
		</div>
	);
};

export default FlashToolTip;
