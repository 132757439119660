import React from "react";
import Overlay from "../overlay/Overlay";

import "./loader.scss";

interface SpinnerProps {
	hideOverlay?: boolean
}

const Spinner = (props: SpinnerProps) => {
	return (
		<>
			{!props.hideOverlay ? <Overlay zIndex={6} /> : null}
			<div className="container-circle">
				<div className="sk-circle">
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
					<div className="sk-circle-dot"></div>
				</div>
			</div>
		</>
	);
};

export default Spinner;
