

export const getBarColor=(val: number) :  string =>{
    let color = ""
    switch(true){
        case val <= 33: {
            color = "#FF7171"
            break;
        }
        case val <= 66: {
            color = "#FCDA84"
            break;
        }
        case val <= 100: {
            color = "#84FCB4"
            break;
        }
    }
    return color
}

/* Reverse order of bar coloring in case of competitor trust %
    since the more competitor trust is threat for us(hence red color)
*/
export const getCompBarColor=(val: number) :  string=>{
    let color = ""
    switch(true){
        case val <= 33: {
            color = "#84FCB4"
            break;
        }
        case val <= 66: {
            color = "#FCDA84"
            break;
        }
        case val <= 100: {
            color = "#FF7171"
            break;
        }
    }
    return color
}