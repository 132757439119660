import { store } from 'store';
import actionConsts from "../store/constants"

export default function (url: string, type = 'GET', body1: any = null, optionParams: any = null) {
    let options: any = {
        method: type,
        credentials: "include",
        // mode: "no-cors"
    }
    if (type !== 'GET') {
        options["body"] = JSON.stringify(body1)
        options["headers"] = {
            // "Accept": "application/json, text/plain, */*",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
        }
    }
    options = { ...options, ...optionParams }
    return fetch(url, options)
        .then(res => {
            if (res.status !== 200) {
                throw res
            }
            return res ? res.json() : res
        })
        .then(res => {
            return Promise.resolve(res)
        })
        .catch(error => {
            switch (error.status) {
                case 408: {
                    console.log("SessionTimeOutError", error);
                    store.dispatch({
                        type: actionConsts.SHOWPOPUP
                    })
                    break;
                }
                case 410: {
                    console.log("SessionDoesNotExistsError", error)
                    store.dispatch({
                        type: actionConsts.SHOWPOPUP
                    })
                    break;
                }
                case 412: {
                    console.log("Auth issue", error)
                    store.dispatch({
                        type: actionConsts.SHOWPOPUP
                    })
                    break;
                }
                case 500: {
                    console.log(error)
                    return Promise.reject(error)
                }
                default: {
                    console.log(error)
                    if(url.includes('/init')){
                        console.log("init fail")
                        return Promise.reject(error)
                    }
                }

            }
        })
}
