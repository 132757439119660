/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "config/hooks";
import { bindActionCreators } from "redux";

import { actionCreators } from "store";
import ClientProgressBar from "./ProgressBar"
import "./orgChart.scss"
import { IClientCharacter } from "interfaces/storyline"
import { getString } from "utils"
import charSelected from 'assets/images/actor_selected_tick.svg'
import bgInfoIcon from 'assets/images/BackgroundInfo.svg'
import { characterImages } from "utils/characterImages";
interface ICharacter {
	character: IClientCharacter;
	index: number;
	isCharacterSelected: boolean
	handleClick: (index: number, character: IClientCharacter) => void
}

const Character: React.FC<ICharacter> = (props: ICharacter) => {
	const { character, index, handleClick } = props	
	const charImg = getString(character["ch_image"])
	
	const characterInfoData = useAppSelector(state => state.storylineData.characterInfo)
	const isGroupActionOpen = useAppSelector((state) => state.globalData.isGroupActionOpen)
	const [prevTrust, setPrevTrust] = useState(-1)
	const [showInfoUnlocked, setShowInfoUnlocked] = useState(false)

	const dispatch = useAppDispatch()
	const { setLevel2BgInfo } = bindActionCreators(actionCreators, dispatch);

	// const btnaudio = new Audio("/button_cta.mp3")
	
	useEffect(() => {
		
		if (character['gc_bg_read'] < 2) {
			const charInfoData = characterInfoData.filter((char: any) => { return char['chi_ch_id'] === character['ch_id'] })

			/*
				If Isn't first render of Dashboard and Level 1 info is revealed and trust is above threshold
				then reveal level 2 bg info
			*/
			if (prevTrust != -1 && character['gc_bg_read'] === 1 && character['gc_trust'] >= charInfoData[1]['chi_trust_level']) {
				setLevel2BgInfo(character['gc_id'])
				setShowInfoUnlocked(true)
				// btnaudio.play()
			}
			setPrevTrust(character['gc_trust'])
		}
	}, [character['gc_trust']])

	const handleCharacterClick = (stcId: number, character: IClientCharacter) => {
		if (showInfoUnlocked) {
			setShowInfoUnlocked(false)
		}
		props.handleClick(stcId, character)
	}

	return (
		<div id={`ind-character-${character['stc_id']}`} >
			<div
				className="character-container"
				id={`character-${character['stc_id']}`}
				onClick={() => handleCharacterClick(character['stc_id'], character)}
			>
				<div className="character-header">
					<img
						src={
							characterImages(charImg)
						}
						style={{
							width: '3.55vw',
							height: '3.55vw',
							borderRadius: "20%",
						}}
					/>
					{isGroupActionOpen && props.isCharacterSelected ? (<img src={charSelected} alt='selected' style={{ position: 'absolute', width: '1.5vw', marginLeft: '2.3vw', marginTop: '-0.8vh' }} />) : ''}
					{showInfoUnlocked ? <img src={bgInfoIcon} alt='bginfo-unlock' style={{ position: 'absolute', width: '2.5vw', right: '1vw', marginTop: '-1vh' }} /> : null}
					<div>
						<p className="client-name">
							{" "}
							{getString(character["ch_name"])}{" "}
						</p>
						<p className="client-designation"> </p>
					</div>
				</div>
				<div className="progress-meter">
					<ClientProgressBar
						label={getString("label_character_y")}
						reverse={false}
						value={character["gc_trust"]}
					/>
					<ClientProgressBar
						label={getString("label_character_c")}
						reverse={true}
						value={character["gc_trust_comp"]}
					/>
				</div>
			</div>
		</div>
	);
};

export default Character;
