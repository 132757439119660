import React, { useEffect, useState } from "react";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import "./orgChart.scss";
import { getCompBarColor, getBarColor } from "utils/progressBar";

interface BarProps {
	label: string;
	value: number;
	reverse: boolean
	bar_color?: string;
	background_color?: string;
}

const ClientProgressBar: React.FC<BarProps> = (props: BarProps) => {

	const [barColor, setColor] = useState(props.bar_color ? props.bar_color: "")

	useEffect(()=>{
		if(!props.bar_color){
			const color = props.reverse ? getCompBarColor(props.value): getBarColor(props.value)
			setColor(color)
		}
	},[props.value])

	const CustLinearProgress = styled(LinearProgress)({
		[`&.${linearProgressClasses.colorPrimary}`]: {
			background: props.background_color
				? props.background_color
				: "rgba(255,255,255,0.1)",
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 20,
			backgroundColor: barColor,
			height: "1vh",
			transform: `translate(-${100 - props.value}% , -25%) !important`,
		},
	});

	return (
		<div className="progress-bar">
			<span className="trustLabel">{props.label + " : "}</span>
			<div className="client-bar">
				<CustLinearProgress
					variant="determinate"
					value={props.value}
					className="progress-bar-style"
				/>
			</div>

			<span
				style={{
					color: barColor,
					fontWeight: "bold",
					fontSize: "1vw",
					marginLeft: "auto",
				}}
			>
				{props.value + "%"}
			</span>
		</div>
	);
};

export default ClientProgressBar;
