/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";
import { addClassToElement } from "utils";

import "./actionIcon.scss";
import { characterImages } from "utils/characterImages";

interface ActionIconProps {
	icon: string;
	icon_width: string;
	action_width?: string;
	onClick: (actID: number) => void;
	action_name?: string;
	activeAction: number;
	actID: number;
}
const ActionIcon: React.FC<ActionIconProps> = (
	props: ActionIconProps
) => {
	const getClickedIconString = (label: string) => {
		const clickedIconString = label.split(".");
		let name = clickedIconString[0];
		name = name + "_clicked";
		return name + "." + clickedIconString[1];
	};

	const clickedIconString = getClickedIconString(props.icon);

	return (
		<>
			<div
				className={`action ${props.activeAction === props.actID ? "clicked" : ""
					}`}
				onClick={() => props.onClick(props.actID)}
				style={{ width: props.action_width, height: props.action_width }}
			>
				<img
					src={
						characterImages(props.activeAction === props.actID
							? clickedIconString
							: props.icon
						)
					}
					style={{ width: props.icon_width }}
					alt={props.icon}
				/>
				{/*
				Display action name on hover
				{props.action_name ? (
					<div className="action-name-hover">{props.action_name}</div>
				) : (
					""
				)}
				*/}
			</div>
			{
				props.action_name ? (
					<div className='group-action-name'>{props.action_name}</div>
				) : null
			}
		</>
	);
};

export default ActionIcon;
