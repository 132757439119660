import React from "react";
import upArrow from "assets/images/upArrow.svg";
import downArrow from "assets/images/downArrow.svg";

import "./trustChangeIndicator.scss";

interface TrustChangeProps {
	impactValue: number;
	positiveColor?: string
	neutralColor?: string
	negativeColor?: string
	style?: any
}

export const TrustChangeIndicator = (props: TrustChangeProps) => {
	let arrow, indicatorBgColor;

	const { positiveColor, neutralColor, negativeColor } = props

	if (props.impactValue === null || props.impactValue === 0) {
		arrow = upArrow;
		indicatorBgColor = neutralColor ? neutralColor : "#DFEAFB";
	} else if (props.impactValue > 0) {
		arrow = upArrow;
		indicatorBgColor = positiveColor ? positiveColor : "rgba(108, 219, 152, 0.5)";
	} else {
		arrow = downArrow;
		indicatorBgColor = negativeColor ? negativeColor : "#FC8484";
	}
	return (
		<div
			className="impact-indicator"
			style={{ background: indicatorBgColor, ...props.style }}
		>
			{props.impactValue != null && props.impactValue != 0 ? <img src={arrow} alt="impact-arrow" className="indicator-arrow" /> : null}
			<span className="impact-value">{Math.abs(props.impactValue)}%</span>
		</div>
	);
};

export default TrustChangeIndicator;
