/* eslint-disable @typescript-eslint/no-var-requires */

import React, { useEffect } from "react";
import { useAppSelector } from 'config/hooks';
import { IClientCharacter } from "interfaces/storyline";


import { getString, removeClassFromElement } from "utils";
import ClientProgressBar from "../ProgressBar";
import CloseIcon from "assets/images/close.svg";
import "./CharacterPopup.scss";
import ClientInfo from "./info/Info";
import ActionContainer from "./actions/ActionContainer";
import { characterImages } from "utils/characterImages";

interface CharacterPopupProps {
	character: IClientCharacter
	handleExit: () => void
	isGameOver: boolean
}

const CharacterPopup: React.FC<CharacterPopupProps> = (
	props: CharacterPopupProps
) => {
	const { character } = props;
	const showWalkthrough = useAppSelector(state => state.globalData.showWalkthrough)

	if (showWalkthrough) {
		removeClassFromElement('dashboard-header', 'z-index-3')
	}

	const charImg = getString(character["ch_image"])
	// const  audio = new Audio("/popup.mp3")
	
	// useEffect(()=>{
	// 	audio.play()
	// },[])

	return (
		<div className="client-description" id='client-description'>
			<div className="description-header">
				<div className="header-profile">
					<img
						src={characterImages(charImg)}
						style={{
							width: "3.5vw",
							height: "3.5vw",
							borderRadius: "10px",
						}}
					/>
					<div className="profile-details">
						<div className="client-name">
							{getString(character["ch_name"])}
						</div>
						<div className="client-progress">
							<div className="description-progress-meter">
								<ClientProgressBar
									label={getString("label_character_y")}
									reverse={false}
									value={character["gc_trust"]}
								/>
								<ClientProgressBar
									label={getString("label_character_c")}
									reverse={true}
									value={character["gc_trust_comp"]}
								/>
							</div>
						</div>
					</div>
				</div>
				{!showWalkthrough ? <img
					className="close-button"
					src={CloseIcon}
					alt="close"
					onClick={() => props.handleExit()}
				/> : null}
			</div>
			<div className="description-content">
				<div className="client-info-container">
					<ClientInfo characterData={character} isGameOver={props.isGameOver} />
				</div>
				<div className="actions-container">
					<ActionContainer isBgRead={character['gc_bg_read'] === 1} handleExitCharacterPopup={props.handleExit} isGameOver={props.isGameOver} />
				</div>
			</div>
		</div>
	);
};

export default CharacterPopup;
