import React from "react";

import "./calendarComponent.scss";
import calendarIcon from "assets/images/calendar.svg";
import { useAppSelector } from "config/hooks";
import { getString } from "utils";

const CalendarComponent: React.FC = () => {

	const simDays = useAppSelector(state => state.storylineData.simDays)
	const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)

	return (
		<>
			<img
				src={calendarIcon}
				alt="calendar"
				style={{ width: "2vw" }}
			></img>
			<div className="days">
				<span className="current-day">{userCurrentDay > simDays ? simDays : userCurrentDay} </span>
				<span className="max-day">/ {simDays} {getString("label_gen_days")}</span>
			</div>
		</>
	);
};

export default CalendarComponent;
