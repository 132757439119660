import * as React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import "./index.scss"
import { useAppSelector } from 'config/hooks';
import { MenuItem } from '@mui/material';
import { getString } from 'utils';
import { characterImages } from 'utils/characterImages';

interface IDropdown {
    handleChange: (event: SelectChangeEvent) => void
    teamCharactersOnly?: boolean
    clientCharactersOnly?: boolean
    values?: any[]
    showAll?: boolean
    currentValue?: string
    removeValue?: number
}

const MenuListComposition: React.FC<IDropdown> = (props: IDropdown) => {

    const clientCharacters = useAppSelector(state => state.storylineData.clientCharacters)
    const teamChars = useAppSelector(state => state.storylineData.teamCharacters)
    let characters = props.values ? props.values : []
    let teamCharacters = teamChars

    if (props.clientCharactersOnly) {
       characters = [...characters, ...clientCharacters]
    }
    if (props.teamCharactersOnly) {
       characters = [...characters, ...teamCharacters]
    }
    if (characters.length == 0) {
        teamCharacters = teamChars ? teamChars.length > 3 ? teamChars.slice(0, 3) : null : null
        let ch = [...clientCharacters, ...teamCharacters]
        if(props.removeValue) {
            ch = ch.filter((ele)=>ele["stc_id"] !== props.removeValue)
        }
       characters = ch
    }
    

    const [value, setValue] = React.useState("");

    const handleChange = (event: SelectChangeEvent) => {
        props.handleChange(event)
        setValue(event.target.value as string)
    }

    return (
        <div>
            <Select
                id="dropdown"
                name="Dropdown"
                value={props.currentValue == "-1" ? "-1" : value}
                className="dropdown"
                onChange={handleChange}
            >
                {props.showAll ?
                    <MenuItem value={-1} classes={{ root: "menu-item" }} key={-1}>
                        <span>{getString("label_gamehistory_all")}</span>
                    </MenuItem> : null}
                {characters.map((character: any, index: number) => {
                    const imgUrl = !props.values ? getString(character["ch_image"]) : ''
                    return props.values ? character["gh_ste_id"] == null ? (
                        // days
                        <MenuItem value={"" + character["gh_day"]} classes={{ root: "menu-item" }} key={index}>
                            <span>{character["gh_day"]}</span>
                        </MenuItem>
                    ) : null :
                        (
                            <MenuItem value={character['stc_id']} classes={{ root: "menu-item" }} key={character['stc_id']}>
                                <img src={characterImages(imgUrl)} className="ch-drop" />
                                <span>{getString(character['ch_name'])}</span>
                            </MenuItem>)
                }
                )}
            </Select>
        </div >
    );
}

export default MenuListComposition