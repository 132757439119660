import { PathConnector } from 'components/commonComponents/connectors/connectors';
import { useAppSelector } from 'config/hooks';
import React from 'react'
import popper from 'assets/images/party_popper.svg'
import './steps.scss'
import { getString } from 'utils';

const Step6 = () => {

  const { height, width } = useAppSelector((state) => state.dimensions);

  return (
    <>
      <PathConnector
        pathD={`M ${width / 25} ${height / 15} V ${height / 110} H ${width / 4
          } L ${width / 4} ${height / 110}`}
        class="s6-connector1 animate fadeIn"
        cx={width / 25}
        cy={height / 15}
        r={height / 300}
      />
      <div className='s6-unlocked-tip animate fadeIn'>
        <div className='s6-tip-content'>
          <div className="title">
            <img src={popper} alt='popper' /> <br />
            {getString('label_unlocked_ind_actions')}
          </div>
        </div>
      </div>

    </>
  )
}

export default Step6
