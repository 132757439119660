import { useAppSelector } from "config/hooks";
import { IAction, IClientCharacter, IStringObject } from "interfaces/storyline";

export const individualActionParams = (
	actionsData: any,
	characterData: IClientCharacter,
	userCurrentDay: number,
	storylineId: number,
	optionText: string,
	selectedTeamMember?: any,
	selectedOptionData?: any,
) => {

	const actId = actionsData.act_id
	let agentId, type = 1
	if (actId === 9) {
		type = 4
	}
	if (actId === 1 || actId === 7 || actId === 9 || actId === 11 || actId === 12) {
		agentId = 10001
	}
	else {
		agentId = selectedTeamMember.stc_id
	}

	if (selectedOptionData) {
		return {
			type: 2,
			optionText: optionText,
			chId: characterData.ch_id,
			stcId: characterData.stc_id,
			actId: actionsData.act_id,
			staId: actionsData.sta_id,
			agentId: selectedOptionData['aco_id'],
			days: userCurrentDay,
			actDays: actionsData.act_days,
			stlId: storylineId,
			isGroup: 0
		}
	}


	return {
		type: type,
		optionText: optionText,
		chId: characterData.ch_id,
		stcId: characterData.stc_id,
		actId: actionsData.act_id,
		staId: actionsData.sta_id,
		agentId: agentId,
		days: userCurrentDay,
		actDays: actionsData.act_days,
		stlId: storylineId,
		isGroup: 0,
	}
}


export const groupActionParams = (
	actionsData: any,
	charactersData: any,
	userCurrentDay: number,
	storylineId: number,
	optionText: string,
	selectedTeamMember: any,
	userId?: number,
	teamStoryId?: number,
	selectedOptionData?: any,
) => {

	const charactersListString = getCharactersListString(charactersData)
	if (selectedOptionData) {
		return {
			actId: actionsData.act_id,
			staId: actionsData.sta_id,
			days: userCurrentDay,
			actDays: actionsData.act_days,
			stlId: storylineId,
			isGroup: 1,
			type: 2,
			optionText: optionText,
			stcId: charactersListString,
			agentId: selectedOptionData.aco_id
		}
	}

	return {
		tsId: teamStoryId,
		usrId: userId,
		actId: actionsData.act_id,
		staId: actionsData.sta_id,
		days: userCurrentDay,
		actDays: actionsData.act_days,
		stlId: storylineId,
		isGroup: 1,
		type: 1,
		optionText: optionText,
		chId: charactersListString,
		stcId: charactersListString,
		agentId: selectedTeamMember.stc_id
	}
}

export const getEventParams = (
	eventData: any,
	selectedOption: number,
) => {

	const history = eventData.history
	const optionData = eventData.ev_options[selectedOption]

	return {
		created_at: history.created_at,
		gh_day: history.gh_day,
		gh_id: history.gh_id,
		gh_interaction_desc: history.gh_interaction_desc,
		gh_interaction_heading: history.gh_interaction_heading,
		gh_interaction_type: history.gh_interaction_type,
		gh_seo_id: optionData.seo_id,
		gh_src_aco_id: history.gh_src_aco_id,
		gh_src_stc_id: history.gh_src_stc_id,
		gh_src_type: eventData.ev_type,
		gh_sta_id: history.gh_sta_id,
		gh_ste_id: history.gh_ste_id,
		gh_ts_id: history.gh_ts_id,
		gh_usr_id: history.gh_usr_id,
		updated_at: history.updated_at,
		user_game_history_details: history.user_game_history_details,
	}
}


const getCharactersListString = (characters: any) => {
	let list = ''

	characters.forEach((char: IClientCharacter) => {
		list += char['stc_id'] + ','
	})

	list = list.substring(0, list.length - 1)
	return list
}