import { Dispatch } from "redux"
import actionConsts from "../constants"
import { store } from "store"
import { getActionOptions, getMonth } from "utils"
import kfetch from "utils/kfetch"
import BaseHandler from 'utils/actionResponseHandler'

export const setStorylineData = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/story/storylineData`)
            .then(res => {
                dispatch({
                    type: actionConsts.STORYLINE,
                    payload: res
                })
                dispatch({
                    type: actionConsts.ISDATAFETCHED
                })
                dispatch({
                    type: actionConsts.LISTS,
                    payload: res
                })
            })
            .catch(error => {
                console.log("inside apiactions", error)
                dispatch({
                    type: actionConsts.STORYLINE,
                    payload: {}
                })
            })
    }
}


export const setUser = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/user/init?lang=en_US`, 'GET', null, 
            { "headers" : { "Content-Type" : "text/plain" }, "credentails": "omit"}) // remove cookies from this api only
            .then(res => {
                dispatch({
                    type: actionConsts.INIT,
                    payload: res
                })
                dispatch({
                    type: actionConsts.ISDATAFETCHED
                })
            })
            .catch((err) => {
                window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`)
            })
    }
}

export const setHistory = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/history`)
            .then(res => {
                dispatch({
                    type: actionConsts.HISTORY,
                    payload: res
                })
                dispatch({
                    type: actionConsts.ISDATAFETCHED
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const updateHistoryData = (historyData: any) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.UPDATE_HISTORY,
            payload: historyData
        })
    }
}

export const setUserMap = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/userMap`)
            .then(res => {
                dispatch({
                    type: actionConsts.USERMAP,
                    payload: res
                })
                dispatch({
                    type: actionConsts.ISDATAFETCHED
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const getLeaderboard = () => {
    return async function action(dispatch: Dispatch) {
        return kfetch(`${process.env.REACT_APP_BACKEND_URL}/analyse/leaderBoard`)
            .then(res => {
                dispatch({
                    type: actionConsts.LEADERBOARD,
                    payload: res
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const setPowermapLocations = (updatedLocations: { stc_id: any; gc_x: number | undefined; gc_y: number | undefined }[]) => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/updateAllLocation`, "POST", { update: JSON.stringify(updatedLocations) })
            .then(res => {
                dispatch({
                    type: actionConsts.SETPOWERMAPLOCATIONS,
                    payload: updatedLocations
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const getPowermapLocations = () =>{
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/getAllLocation`)
            .then(res => {
                dispatch({
                    type: actionConsts.SETPOWERMAPLOCATIONS,
                    payload: res
                })
                dispatch({
                    type: actionConsts.ISDATAFETCHED
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const createPowermapLink = (link: any, updatedLinks: any[]) => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/maps/link`, "POST", link)
            .then(res => {
                const len = Object.keys(updatedLinks).length
                updatedLinks[len-1] = res // to add link_id in the state
                dispatch({
                    type: actionConsts.UPDATEPOWERMAPLINKS,
                    payload: updatedLinks
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const updatePowermapLink = (link: any, updatedLinks: any[]) => {
    return function action(dispatch: Dispatch) {
        kfetch(
            `${process.env.REACT_APP_BACKEND_URL}/maps/link/${link["ln_id"]}`, "PUT", link)
            .then((res) => {
                dispatch({
                    type: actionConsts.UPDATEPOWERMAPLINKS,
                    payload: updatedLinks,
                })
            })
            .catch(error => {
                console.log(error)
            })

    }
}

export const removePowermapLink = (link: any, updatedLinks: any[]) => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/maps/link/${link["ln_id"]}`, "DELETE", link)
            .then((res) => {
                dispatch({
                    type: actionConsts.UPDATEPOWERMAPLINKS,
                    payload: updatedLinks,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const setShowGroupActions = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/setGroupAction`, "POST")
            .then((res) => {
                dispatch({
                    type: actionConsts.UPDATE_USER_DATA,
                    payload: { showGroupActions: 1 }
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

/* Action Execution Related actions */
export const preCheck = (performActionParams: any) => {
    return function action(dispatch: Dispatch) {
        return kfetch(`${process.env.REACT_APP_BACKEND_URL}/actions/preCheck`, "POST", performActionParams)
            .then((res) => {
                dispatch({
                    type: actionConsts.UPDATE_PRECHECK_RESPONSE,
                    payload: res
                })
                return res
            })
            .catch(error => {
                console.log(error)
            })
    }
}

//Perform Action API
export const performAction = (performActionParams: any) => {
    return async function action(dispatch: Dispatch) {
        //when You team member is selected
        if (performActionParams.agentId === '#') {
            try{

                const actionOptions = await getActionOptions(performActionParams.staId)
                dispatch({
                    type: actionConsts.ACTION_OPTION_RESPONSE,
                    payload: { data: actionOptions }
                })

                dispatch({
                    type: actionConsts.UPDATE_GLOBAL_DATA,
                    payload: { showActionResponseLoader: false }
                })

                dispatch({
                    type: actionConsts.UPDATE_GLOBAL_DATA,
                    payload: { showActionOption: true }
                })
            }catch(err){
                console.log(err)
            }
        }
        else {
            return kfetch(`${process.env.REACT_APP_BACKEND_URL}/actions/perform`, "POST", performActionParams)
                .then((res) => {

                    dispatch({
                        type: actionConsts.UPDATE_CURRENT_ACTION_RESPONSE,
                        payload: res
                    })

                    const handlerObj = new BaseHandler()
                    handlerObj.init(performActionParams)
                    return res
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }
}

export const getReportPowermap = (month: number) => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/analyse/report/${month}`,'GET', null, { "headers" : { "Content-Type" : "text/plain" }})
            .then(res => {
                dispatch({
                    type: actionConsts.REPORTPM,
                    payload: res
                })
                dispatch({
                    type: actionConsts.REPORTANALYSIS,
                    payload: JSON.parse(res.analyse)
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}
export const getReportPowermapCached = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/analyse/reportCached`)
            .then(res => {
                console.log("report",res)
                dispatch({
                    type: actionConsts.REPORTPMCACHED,
                    payload: res
                })
                dispatch({
                    type: actionConsts.REPORTANALYSIS,
                    payload: JSON.parse(res.analyse)
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

// export const getReportAnalysis = () => {
//     return function action(dispatch: Dispatch) {
//         kfetch(`${process.env.REACT_APP_BACKEND_URL}/analyse/trust`)
//             .then(res => {
//                 dispatch({
//                     type: actionConsts.REPORTANALYSIS,
//                     payload: res
//                 })
//             })
//             .catch(error => {
//                 console.log(error)
//             })
//     }
// }

export const resetActionOptionsResponse = () => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.ACTION_OPTION_RESPONSE,
            payload: { data: [] }
        })
    }
}

/* Event Actions */
export const addEventData = (data: any) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.ADD_EVENT_DATA,
            payload: data
        })
    }
}

export const removeEventData = (data: any) => {
    return function action(dispatch: Dispatch) {
        dispatch({
            type: actionConsts.REMOVE_EVENT_DATA,
            payload: data
        })
    }
}

/* Game End Actions */
export const setGameStatusToComplete = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/complete`, "GET")
        .then(()=>{return})
        .catch(error => {
            console.log(error)
        })
    }
}


/* Report Related Actions */
export const setReportData = (month: number) => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/analyse/report/${month}`,'GET', null, { "headers" : { "Content-Type" : "text/plain" }})
        .then(res => {
            dispatch({
                type: actionConsts.REPORTPM,
                payload: res
            })
            dispatch({
                type: actionConsts.REPORTANALYSIS,
                payload: JSON.parse(res.analyse)
            })
        })
        .catch(error => {
            console.log(error)
        })
    }
}

export const downloadUserReport = (isNewReportEnabled: number) => {

    return async function action(dispatch: Dispatch) {
        let reportUrl = '/user/report'
        if (isNewReportEnabled === 1) {
            reportUrl = '/user/newUserReport'
        }

        return kfetch(`${process.env.REACT_APP_BACKEND_URL}${reportUrl}`, "GET")
            .then((res) => {
                return res
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
}

export const emailUserReport = (email: string) => {
    return async function action(dispatch: Dispatch) {

        const emailBody = { mailId: email }

        return kfetch(`${process.env.REACT_APP_BACKEND_URL}/user/mailReport`, "POST", emailBody)
            .then((res) => {
                // console.log('email response', res)
                return res
            })
            .catch(error => {
                return error
                console.log(error)
            })
    }
}

/* Demo related Actions */
export const startDemoMode = () => {
    return async function action(dispatch: Dispatch) {
        return kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/startDemoMode`, "POST")
        .then(()=>{return})
        .catch(error => {
            console.log(error)
        })
    }
}

export const exitDemoMode = () => {
    return async function action(dispatch: Dispatch) {
        return kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/exitDemoMode`, "POST")
        .then(()=>{return})
        .catch(error => {
            console.log(error)
        })
    }
}

export const setDemoCompleted = () => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/setDemoCompletedFlag`, "POST")
        .then(()=>{return})
        .catch(error => {
            console.log(error)
        })
    }
}


export const setVideoCompleted = () => {

    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/setVideoCompletedFlag`, "POST")
        .then(()=>{return})
        .catch(error => {
            console.log(error)
        })

        dispatch({
            type: actionConsts.UPDATE_USER_DATA,
            payload: { isVideoCompleted: 1 }
        })
    }
}

export const setLevel2BgInfo = (gc_id: number) => {
    return async function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/setLevel2BgInfo`, "POST", { gc_id: gc_id })
        .then(() => {return})
        .catch(error => {
            console.log(error)
        })

        dispatch({
            type: actionConsts.SET_LEVEL2_BG_READ,
            payload: gc_id
        })
    }
}

export const updateTimerInDB = (time: number) => {
    return function action(dispatch: Dispatch) {
        kfetch(`${process.env.REACT_APP_BACKEND_URL}/game/updateTimerInDB`, "POST", { time: time })
        .then(()=>{return})
        .catch(error => {
            console.log(error)
        })
    }
}