import { Button } from "@mui/material";
import React from "react";

import costIcon from "assets/images/action-cost.svg";
import { useAppDispatch, useAppSelector } from "config/hooks";
import { actionCreators } from "store";
import { IAction, IClientCharacter } from "interfaces/storyline";
import { getString } from "utils";
import BgInfoLock from "assets/images/bg_info_lock.svg";
import FlashToolTip from "components/commonComponents/flashToolTip/FlashToolTip";
import { bindActionCreators } from "redux";
import { individualActionParams } from "../actions/ActionParams";

interface ClientProfileProps {
	actionCost: number
	characterData: IClientCharacter
	isGameOver: boolean
}

const ClientProfile = (props: ClientProfileProps) => {
	const characterData = props.characterData
	const characterInfoData = useAppSelector(state => state.storylineData.characterInfo)
	const { userCurrentDay } = useAppSelector((state) => state.initUser)
	const { storylineId } = useAppSelector((state) => state.storylineData)
	let { actions } = useAppSelector((state) => state.storylineData)
	actions = actions ? actions : []

	const dispatch = useAppDispatch()
	const {
		performAction,
		addEventData,
	} = bindActionCreators(actionCreators, dispatch);

	// const btnaudio = new Audio("/button_cta.mp3")

	const showBGInfo = async () => {
		// btnaudio.play()
		const buttonElement = document.getElementById("reveal-info")
		if (buttonElement)
			buttonElement.innerHTML = getString("label_requesting")
		const actionData = actions.filter((action: IAction) => {
			return action.act_id == 1
		});
		const optionText = "BG INFO"
		const actionParams = individualActionParams(
			actionData[0],
			characterData,
			userCurrentDay,
			storylineId,
			optionText
		);
		const res: any = await performAction(actionParams)

		if(res.event.length > 0) {
			addEventData(res.event)
		}
	};

	let isLevel2InfoRevealed = false;
	(function () {
		if(characterData['gc_bg_read'] === 2) {
			isLevel2InfoRevealed = true
		}
	}())

	const getLevel2Info = () => {

		const charInfoData = characterInfoData.filter((char: any) => { return char['chi_ch_id'] === characterData['ch_id'] })
		const level2Info: string = charInfoData[1]['chi_desc']
		return level2Info
	}

	return (
		<div className="client-profile">
			<div className="level1-info">
				<div className="info-title">{getString("label_level1_info")}</div>
				<div className="info-content">
					{characterData["gc_bg_read"] >= 1 ? (
						<div
							className="revealed-info"
							dangerouslySetInnerHTML={{
								__html: getString(
									characterData["ch_name"] + "_bg1"
								),
							}}
						></div>
					) : (
						<div className="hidden-level1-info">
							<Button
								variant="contained"
								disableRipple={true}
								disabled={props.isGameOver ? true : false}
								id="reveal-info"
								className="reveal-info"
								onClick={() => showBGInfo()}
							>
								{getString("label_gen_read")}
							</Button>
							<div className="action-cost">
								<img
									src={costIcon}
									alt="cost"
									style={{
										paddingRight: "2px",
										width: "0.9vw",
									}}
								/>
								<span className="cost-days">
									<span style={{ fontWeight: "normal" }}>
										{" "}
										{getString("label_gen_cost")}:{" "}
									</span>
									{props.actionCost}{" "}
									{getString("label_gen_day")}
								</span>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="level2-info">
				<div className="info-title">
					{isLevel2InfoRevealed ? (
						null
					) : (
						<img className="bg-lock" src={BgInfoLock}></img>
					)}
					<span style={{ alignSelf: "center" }}>
						{getString("label_level2_info")}
					</span>
				</div>
				<div className="info-content">
					{isLevel2InfoRevealed ? (
						<div className='revealed-info'>{getString(getLevel2Info())}</div>
					) : (
						<div className="hidden-level2-info">
								{characterData["gc_bg_read"] ? (
								<span className="tooltip">
									{getString("label_character_later")}
								</span>
							) : (
								<FlashToolTip
									toolTipText={getString(
										"label_character_later"
									)}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ClientProfile;
