import React, { useEffect, useState } from "react";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import "./progressBarWithLabel.scss";
import { useAppSelector } from 'config/hooks';
import TrustChangeIndicator from 'components/commonComponents/trustChangeIndicator/TrustChangeIndicator';
import { getCompBarColor, getBarColor } from "utils/progressBar";

interface BarProps {
	label_bar: string;
	progress_bar_value: number;
	reverse: boolean;
	deltaTrustValue: number;
}

const ProgressBarWithLabel: React.FC<BarProps> = (props: BarProps) => {

	const showActionResponse = useAppSelector(state => state.globalData.showActionResponse)
	const showProgressBarChange = useAppSelector(state => state.globalData.showProgressBarChange)
	const [barColor, setColor] = useState("")

	useEffect(()=>{
			const color = props.reverse ? getCompBarColor(props.progress_bar_value): getBarColor(props.progress_bar_value)
			setColor(color)
	},[props.progress_bar_value])

	const CustomLinearProgress = styled(LinearProgress)({
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "transparent",
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 20,
			backgroundColor: barColor,
		},
	});

	return (
		<div className="bar-container">
			<div className="bar-content">
				<span className="bar-label">{props.label_bar}</span>
				<span className="bar-value" style={{ color: barColor }}>
					{props.progress_bar_value}
					<span className="percentage">%</span>
				</span>
			</div>
			<CustomLinearProgress
				variant="determinate"
				value={props.progress_bar_value}
				className="bar-style"
			/>
			{showActionResponse || showProgressBarChange ? <div className="animate-impact animateImpact"><TrustChangeIndicator impactValue={props.deltaTrustValue} positiveColor="#84FCB4" negativeColor="#FF7171" /></div> : null}
		</div>
	);
};

export default ProgressBarWithLabel;
