import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "config/hooks";

import ActionsBase from "components/commonComponents/actions/ActionsBase";
import ActionIcon from "../../../../commonComponents/actions/actionIcon/ActionIcon";
import { IAction, IClientCharacter } from "interfaces/storyline";
import { getString } from "utils";
import ActionLock from "assets/images/individual_actions_locked.svg";

import "./actionContainer.scss";
import Step6 from 'components/walkthrough/steps/Step6';

interface ActionContainerProps {
	isBgRead: boolean
	handleExitCharacterPopup: (arg1?: any) => void
	isGameOver: boolean
}

const ActionContainer = (props: ActionContainerProps) => {
	let actions = useAppSelector((state) => state.storylineData.actions)
	actions = actions ? actions : []

	const userCurrentDay = useAppSelector((state) => state.initUser.userCurrentDay)
	const showWalkthrough = useAppSelector(state => state.globalData.showWalkthrough)

	const showIndividualAction = userCurrentDay > 0;
	const [activeAction, setActiveAction] = useState(-1);
	const [showUnlockedTip, setShowUnlockedTip] = useState(false)

	const  audio = new Audio("/confetti.mp3")
	// const btnaudio = new Audio("/button_cta.mp3")
	
	useEffect(() => {
		if (showWalkthrough && props.isBgRead) {
			setShowUnlockedTip(true)
			audio.play()
		}
	}, [props.isBgRead])

	const individualActions: IAction[] = actions.filter((action: IAction) => {
		return action.act_group != 2 && action.act_id != 1;
	});

	const handleOnClick = (actID: number) => {
		// btnaudio.play()
		if (activeAction == actID) {
			setActiveAction(-1);
			const individualActionElement = document.getElementById(
				"individual-action-description"
			);
			if (individualActionElement)
				individualActionElement.style.display = "none";
			return;
		}
		setActiveAction(actID);
		const individualActionElement = document.getElementById(
			"individual-action-description"
		);
		if (individualActionElement)
			individualActionElement.style.display = "block";

		if (showUnlockedTip) {
			setShowUnlockedTip(false)
		}

		return;
	};

	const handleExitActionDescription = () => {
		setActiveAction(-1);
		const individualActionElement = document.getElementById(
			"individual-action-description"
		);
		if (individualActionElement)
			individualActionElement.style.display = "none";
	};

	const activeActionData: IAction[] = individualActions.filter(
		(action: IAction) => {
			return action.act_id == activeAction;
		}
	);

	return (
		<div className="individual-actions-container">
			{showUnlockedTip ? <Step6 /> : null}
			<div className="individual-actions">
				<div className="title">{getString('label_action')}</div>
				<div
					className={`all-actions ${
						showIndividualAction && !props.isGameOver
							? ""
							: "pointer-events-none opacity-4"
					}`}
				>
					<div className="actions">
						{individualActions.map(
							(action: IAction, index: number) => {
								return (
									<div
										id={`action-${index}`}
										className={`single-action ${
											activeAction == action["act_id"]
												? "single-action-clicked"
												: ""
										}`}
										key={index}
									>
										<ActionIcon
											icon={getString(
												action["act_image"]
											)}
											onClick={handleOnClick}
											actID={action["act_id"]}
											activeAction={activeAction}
											icon_width="2.5vh"
											action_width="2.5vw"
										/>
										<span className="action-name" onClick={() => handleOnClick(action['act_id'])}>
											{getString(action["act_name"])}
										</span>
									</div>
								);
							}
						)}
					</div>
					<div
						id="individual-action-description"
						className="individual-action-description"
						style={{ display: "none" }}
					>
						{activeActionData.length > 0 && (
							<ActionsBase
								actionId={activeAction}
								actionDescription={getString(
									activeActionData[0]["act_desc"]
								)}
								actionName={getString(
									activeActionData[0]["act_name"]
								)}
								actionData={activeActionData[0]}
								actionCost={activeActionData[0]["act_days"]}
								handleExitGroupDescription={
									handleExitActionDescription
								}
								handleExitCharacterPopup={
									props.handleExitCharacterPopup
								}
								isGroupAction={false}
								
							/>
						)}
					</div>
				</div>
				{showIndividualAction && !props.isGameOver ? (
					""
				) : (
					<div className="locked-individual-actions">
						<img
							src={ActionLock}
							alt="locked"
							className="lock-icon"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ActionContainer;
