import React, { useState } from "react";
import { bindActionCreators } from "redux"

import { Powermap } from "components/commonComponents/powermap";
import Overlay from "components/commonComponents/overlay/Overlay";


import "./powermap.scss"
import { useAppDispatch, useAppSelector } from "config/hooks";
import { actionCreators } from "store";
import PowermapTool from "./PowermapTool";
import RepsMap from "components/commonComponents/repsMap/RepsMap";
import PowermapDesc from "./PowermapDesc";
import { getString } from "utils";

interface PowermapDashProps {
	isGameOver: boolean
}

const PowerMap: React.FC<PowermapDashProps> = (props:PowermapDashProps) => {

    const { showPowermap } = useAppSelector(state => state.dash);
    const dispatch = useAppDispatch();
    const { setDashPowermap } = bindActionCreators(actionCreators, dispatch)

    const [obj, setObj] = useState({})
    const [showInfo, setShowInfo] = useState(false)
    const [showDesc, setShowDesc] = useState(false)
    // const [to, setToEvent] = useState("" as unknown as SelectChangeEvent)

    const btnaudio = new Audio("/button_cta.mp3")
    
    const handlePowermapClick = () => {
        // btnaudio.play()
        setDashPowermap(!showPowermap)
    }

    const updateLink = (obj: any) => {
        setObj(obj)
    }

    const renderInfo = () => {
        setShowInfo(true)
    }

    const renderDesc = () => {
        setShowDesc(!showDesc)
    }

    return (
        <div className="powermap">
            {/* <Divider orientation="vertical" flexItem className="powermap-divider" /> */}
            {!showPowermap && (
                <button
                    id="powermap-btn"
                    className="powermap-btn"
                    onClick={handlePowermapClick}
                >
                    {getString("label_powermap_cap")}
                </button>
            )}
            {showPowermap && (
                <div className="expand-powemap">
                    <Overlay onClickHandler={handlePowermapClick}/>
                    <button className="powermap-btn-overlay" onClick={handlePowermapClick}>{getString("label_powermap_cap")}</button>
                    <div className="dash-pm">
                        {!showDesc ? <>
                            <div className="container">
                                {!showInfo ? <Powermap isIntro={false} config={obj} renderInfo={renderInfo}isGameOver={props.isGameOver}/> :
                                    <RepsMap showIcon={true} handleClick={() => setShowInfo(false)} />}
                            </div>
                            <div className="influence-container">
                                <PowermapTool draw={updateLink} showDesc={renderDesc} isGameOver={props.isGameOver}/>
                            </div>
                        </> : <PowermapDesc close={renderDesc} />
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default PowerMap;
