import React from "react";
import { useHistory } from 'react-router-dom';
import introIllustration from "assets/images/intro/illustration_introduction.svg"
import qoute from "assets/images/intro/qoute.svg"
import { getString } from "utils";

interface IntroProps {
    onTabChange: (newValue: number) => void
    isPopup?: boolean
}

const Introduction: React.FC<IntroProps> = (props: IntroProps) => {

    // const btnaudio = new Audio("/button_cta.mp3")
    
    const handleNext = () => {
        // btnaudio.play()
        props.onTabChange(1);
    }

    return (
        <>
            {!props.isPopup ? <div className="theader">
                <img src={introIllustration} className="timage" />
                <img src={qoute} className="tqoute" />
                <div className="ceo-qoute">
                    <span dangerouslySetInnerHTML={{ __html: getString("label_intro_steve_welcome") }}></span>
                </div>
            </div> : null}
            <div className="tbody">
                <span className="bhead" dangerouslySetInnerHTML={{ __html: getString("label_intro_role") }} />
                <p className="bhead bbody" dangerouslySetInnerHTML={{ __html: getString("intro_default") }} />
                {!props.isPopup ? <div className="tbuttons">
                    <button className="next" onClick={handleNext}>{getString("label_intro_next")}</button>
                </div> : null}
            </div>
        </>
    )
}

export default Introduction;
