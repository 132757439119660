import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useAppSelector } from "config/hooks";

import background from "assets/images/intro/BG_intro.svg"
import landing from "assets/images/intro/landing.svg"
import IntroHeader from "./IntroHeader";
import IntroFooter from "./IntroFooter";
import Popup from "components/commonComponents/popup/Popup";
import "./intro.scss"
import { getString } from "utils";

const LandingPage: React.FC = () => {


    const showLoginPopup = useAppSelector(state => state.showLoginPopup)
    const isDemoEnabled = useAppSelector(state => state.initUser.isDemoEnabled)
    const isDemoCompleted = useAppSelector(state => state.initUser.isDemoCompleted)
    const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
    const isDemoUser = useAppSelector(state => state.initUser.isDemoUser)
    const isVideoCompleted = useAppSelector(state => state.initUser.isVideoCompleted)
    const isVideoEnabled = useAppSelector(state => state.initUser.isVideoEnabled)
    // Router useHistory initialisation 
    const history = useHistory();
    // const btnaudio = new Audio("/button_cta.mp3")
    
    const handleReady = () => {
        //uncomment me to enable video feature.
        if (isDemoUser == 0 && isVideoEnabled == 1 && isVideoCompleted == 0) {
            history.push('/video')
        }
        else history.push('/intro');
        // btnaudio.play()
        // history.push('/intro')
    }
    // ROUTING FLOW
    
    // demo user disabled -> /intro /dashboard
    /*
    isDemoEnabled && isDemoCompleted 0 /intro
    isVideoEnabled && isVideoCompleted 0 /video
    isDemoEnabled && isDemoCOmpleted 1 /Demo (continue demo)
    isDemoEnabled && isDemoCOmpleted 2 /demo (thank YourTeam)
        setDemoCompletedFlag
        Click Proceed To simulation --> 
    /exit Demo mode --> /dashboard 
    */
    useEffect(() => {

        if (isDemoUser == 0 && userCurrentDay > 0) {
            history.push('/intro')
        }
        else if (isDemoUser == 1) {
            history.push('/demo')
        }
        else if (isDemoEnabled == 1 && isDemoCompleted == 1) {
            // show ContinueDemo screen on /demo
            history.push('/demo')
        }
        else if (isDemoEnabled == 1 && isDemoCompleted == 2) {
            // redirect to intro - Meet Your team
            history.push('/intro')
        }

        // return () => {
        // }
    }, [])


    return (
        <div className="intro-container"
            style={{ "background": `url(${background}) no-repeat center center fixed`, backgroundSize: "cover" }}>
            <IntroHeader />
            <div className="landing-body">
                <img src={landing} alt="" />
                <div className="content">
                    <p className="welcome">{getString("label_landing_welcome")}<span className="trust">TRUST</span><span className="sim">Sim</span></p>
                    <p className="desc">{getString("label_landing_page_desc")}
                    </p>
                    <p className="ready-text">{getString("label_landing_page_ready")}
                    </p>
                    <button className="ready" onClick={handleReady}>{getString("label_ready")}</button>
                </div>
            </div>
            <IntroFooter />
            {showLoginPopup ? <>
                <Popup>
                    <div className="phead">
                        <p style={{ display: "inherit" }}>
                            <img src={require(`assets/images/Meeting.svg`).default} style={{ width: "3vw" }} />
                            <span>&nbsp;{getString("label_logout")}</span>
                        </p>
                    </div>
					<div className="curved-header"></div>
                    <div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "31vh", fontSize: "2vh", lineHeight: "2.4vh", justifyContent: "center", alignItems: "center", borderRadius: '0', borderBottomLeftRadius: '0.7vw', borderBottomRightRadius: '0.7vw' }}>
                        <p dangerouslySetInnerHTML={{ __html: getString("label_login_again") }} style={{ color: "#083880", fontSize: "2.6vh" }}></p>
                        <div className="lbuttons">
                            <button className="logout-btn" onClick={() => window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`)}>{getString("label_login_again_heading")}</button>
                        </div>
                    </div>
                </Popup>
            </> : null}
        </div>
    )



};

export default LandingPage;
